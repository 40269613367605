import { useContext, useEffect, useRef, useState } from "react";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";
import AppContext from "../../../handle-reuses/Contexts/CreateContexts";
import moment from "moment";
import ModalReact from "../../../Component/Modal/ModalReact";
import PaymentTechPricing from "./PaymentTechPricing";
import classNames from "classnames/bind";
import styles from "../Pricing.module.scss"
const cx = classNames.bind(styles);

function ContentPricingPC(props) {
    const {sizeScreen} = props
    const { socketio, dataUser } = useContext(AppContext)
    const [activeService, setActiveService] = useState("");

    // Kiểm tra kích hoạt
    useEffect(() => {
      if (dataUser.length > 0) {
        const dateEnd = moment(dataUser[0].date_end);
        const currentTime = moment()
        const daysDiff = Number(dateEnd.diff(currentTime, "days"))
        if (daysDiff > 0) {
          setActiveService(dataUser[0].pricing_id)
        }
            
        }
    }, [])
    // Quản lý thay đổi thời gian gói dịch vụ.
    const [yearMonth, setYearMonth] = useState("months");
    const handleStatusPayment = (yearMonth) => {
        setYearMonth(yearMonth)
    }
    // Quản lý thay đổi gói dịch vụ.
    const [modalChageService, setModalChangeService] = useState(false);
    const objectChange = useRef()
    const handleChangeService = (object) => {
        // Kiểm tra khách hàng có đăng ký tài khoản hay không.
        if (dataUser.length > 0) {
            // Kiểm tra người dùng đang sử dụng gói dịch vu nào và hết hạn chưa.
            if (dataUser[0].date_end) {
                // Xử lý gia hạn
                let dateEnd = moment(dataUser[0].date_end)
                let currentTime = moment()
              let daysDiff = Number(dateEnd.diff(currentTime, "days"))
              // được phép gia hạn trước 1 ngày
                if (daysDiff >= 1) {
                  alert("Bạn không thể thay đổi gói mới, hãy chờ hết hạn để đổi gói dịch vụ.")
                } else {
                    // Xử lý gia hạn hoặc đổi gói dịch vụ.
                    setModalChangeService((true))
                    objectChange.current = object
                }
            } else {
                // Xử lý đăng ký mới
                setModalChangeService(true)
                objectChange.current = object
            }
        } else {
            alert("Bạn cần có tài khoản để đăng ký gói dịch vụ.")
        }
    }
    const handleCloseChangeService = () => {
        setModalChangeService(false)
        objectChange.current = undefined
    }

    return (
      <div className="row mb-5 wrapper_content_children">
        <div className="heightScroll">
          <div className="col-xl-12">
            <h5 className="fw-semibold text-center"> Hạng Thành Viên </h5>
            <p className="text-muted text-center">
              Hãy lựa chọn hạng thành viên phù hợp với bạn
            </p>
            <div className="d-flex justify-content-center mb-4">
              <ul
                className="nav nav-tabs mb-3 tab-style-6 bg-primary-transparent"
                id="myTab1"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className={cx(
                      "nav-link",
                      yearMonth === "months" && "active",
                    )}
                    id="pricing-monthly1"
                    data-bs-toggle="tab"
                    data-bs-target="#pricing-monthly1-pane"
                    type="button"
                    role="tab"
                    aria-controls="pricing-monthly1-pane"
                    aria-selected="true"
                    onClick={() => handleStatusPayment("months")}
                  >
                    Tháng
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={cx(
                      "nav-link",
                      yearMonth === "years" && "active",
                    )}
                    id="pricing-yearly1"
                    data-bs-toggle="tab"
                    data-bs-target="#pricing-yearly1-pane"
                    type="button"
                    role="tab"
                    aria-controls="pricing-yearly1-pane"
                    aria-selected="false"
                    onClick={() => handleStatusPayment("years")}
                  >
                    Năm
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="tab-content" id="myTabContent1">
              <div
                className="tab-pane show active p-0 border-0"
                id="pricing-monthly1-pane"
                role="tabpanel"
                aria-labelledby="pricing-monthly1"
                tabIndex="0"
              >
                <div className="row">
                  <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div
                      className="card custom-card overflow-hidden"
                      style={{ height: "615px" }}
                    >
                      <div className="card-body p-0">
                        <div className="px-1 py-2 bg-success op-3"></div>
                        <div className="p-4">
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <div className="fs-18 fw-semibold">Miễn phí</div>
                          </div>
                          <div className="fs-25 fw-bold mb-1">
                            0đ
                            <sub className="text-muted fw-semibold fs-15 ms-1">
                              / Tháng
                            </sub>
                          </div>
                          <div className="mb-1 text-muted">
                            Sử dụng những ứng dụng miễn phí
                          </div>
                          <ul className="list-unstyled mb-0">
                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoCloseCircleOutline className="text-danger" />
                              </span>
                              <span>Hỗ trợ</span>
                            </li>
                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoIosCheckmarkCircleOutline className="text-success" />
                              </span>
                              <span>
                                Sử dụng ứng dụng:{" "}
                                <strong className="me-1">Miễn phí</strong>
                              </span>
                            </li>
                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoIosCheckmarkCircleOutline className="text-success" />
                              </span>
                              <span>Bảo mật thông tin</span>
                            </li>
                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoCloseCircleOutline className="text-danger" />
                              </span>
                              <span>
                                Giảm giá khi đặt mua{" "}
                                <span className="fw-5">0%</span>
                              </span>
                            </li>
                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoCloseCircleOutline className="text-danger" />
                              </span>
                              <span>Nâng cấp theo yêu cầu</span>
                            </li>

                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoCloseCircleOutline className="text-danger" />
                              </span>
                              <span>Phát triển chức năng riêng</span>
                            </li>

                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoCloseCircleOutline className="text-danger" />
                              </span>
                              <span>Đối tác kinh doanh</span>
                            </li>
                            <li className="d-grid">
                              {activeService === 1 ? (
                                <button className="btn btn-wave btn-success fw-5">
                                  Đã kích hoạt
                                </button>
                              ) : (
                                <button
                                  className="btn btn-light btn-wave fw-5"
                                  onClick={() =>
                                    handleChangeService({
                                      pricing_id: 1,
                                      yearMonth: yearMonth,
                                    })
                                  }
                                >
                                  Kích hoạt
                                </button>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div
                      className="card custom-card overflow-hidden"
                      style={{ height: "615px" }}
                    >
                      <div className="card-body p-0">
                        <div className="px-1 py-2 bg-warning op-3"></div>
                        <div className="p-4">
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <div className="fs-18 fw-semibold">
                              Hạng tiêu chuẩn
                            </div>
                            <div>
                              <span className="badge bg-warning-transparent">
                                Cá nhân
                              </span>
                            </div>
                          </div>
                          <div className="fs-25 fw-bold mb-1">
                            {yearMonth === "months" ? "100,000" : "1,200,000"}đ
                            <sub className="text-muted fw-semibold fs-15 ms-1">
                              / {yearMonth === "months" ? "Tháng" : "Năm"}
                            </sub>
                          </div>
                          <div className="mb-1 text-muted">
                            Sử dụng những ứng dụng tiêu chuẩn có sẵn của hệ
                            thống
                          </div>
                          <ul className="list-unstyled mb-0">
                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoIosCheckmarkCircleOutline className="text-success" />
                              </span>
                              <span>Hỗ trợ</span>
                            </li>
                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoIosCheckmarkCircleOutline className="text-success" />
                              </span>
                              <span>
                                Sử dụng ứng dụng:{" "}
                                <strong className="me-1">Tiêu chuẩn</strong>
                              </span>
                            </li>
                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoIosCheckmarkCircleOutline className="text-success" />
                              </span>
                              <span>Bảo mật thông tin</span>
                            </li>
                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoIosCheckmarkCircleOutline className="text-success" />
                              </span>
                              <span>
                                Giảm giá khi đặt mua{" "}
                                <span className="fw-5">3%</span>
                              </span>
                            </li>
                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoCloseCircleOutline className="text-danger" />
                              </span>
                              <span>Nâng cấp theo yêu cầu</span>
                            </li>

                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoCloseCircleOutline className="text-danger" />
                              </span>
                              <span>Phát triển chức năng riêng</span>
                            </li>

                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoCloseCircleOutline className="text-danger" />
                              </span>
                              <span>Đối tác kinh doanh</span>
                            </li>
                            <li className="d-grid">
                              {activeService === 2 ? (
                                <button className="btn btn-success btn-wave fw-5">
                                  Đã kích hoạt
                                </button>
                              ) : (
                                <button
                                  className="btn btn-light btn-wave fw-5"
                                  onClick={() =>
                                    handleChangeService({
                                      pricing_id: 2,
                                      yearMonth: yearMonth,
                                    })
                                  }
                                >
                                  Kích hoạt
                                </button>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div
                      className="border border-primary card custom-card overflow-hidden"
                      style={{ height: "615px" }}
                    >
                      <div className="card-body p-0">
                        <div className="px-1 py-2 bg-primary op-5"></div>
                        <div className="p-4">
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <div className="fs-18 fw-semibold">Hạng bạc</div>
                            <div>
                              <span className="badge bg-primary-transparent">
                                Phù hợp
                              </span>
                            </div>
                          </div>
                          <div className="fs-25 fw-bold mb-1">
                            {yearMonth === "months" ? "299,000" : "3,588,000"}đ
                            <sub className="text-muted fw-semibold fs-15 ms-1">
                              / {yearMonth === "months" ? "Tháng" : "Năm"}
                            </sub>
                          </div>
                          <div className="mb-1 text-muted">
                            Sử dụng những ứng dụng chuyên nghiệp đa dạng của hệ
                            thống
                          </div>
                          <ul className="list-unstyled mb-0">
                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoIosCheckmarkCircleOutline className="text-success" />
                              </span>
                              <span>Hỗ trợ</span>
                            </li>
                            <li className="d-flex align-items-center text-primary mb-3">
                              <span className="me-2">
                                <IoIosCheckmarkCircleOutline className="text-success" />
                              </span>
                              <span>
                                Sử dụng ứng dụng:{" "}
                                <strong className="me-1">Nâng cao</strong>
                              </span>
                            </li>
                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoIosCheckmarkCircleOutline className="text-success" />
                              </span>
                              <span>Bảo mật thông tin</span>
                            </li>
                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoIosCheckmarkCircleOutline className="text-success" />
                              </span>
                              <span>
                                Giảm giá khi đặt mua{" "}
                                <span className="fw-5">4%</span>
                              </span>
                            </li>
                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoCloseCircleOutline className="text-danger" />
                              </span>
                              <span>Nâng cấp theo yêu cầu</span>
                            </li>

                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoCloseCircleOutline className="text-danger" />
                              </span>
                              <span>Phát triển chức năng riêng</span>
                            </li>

                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoCloseCircleOutline className="text-danger" />
                              </span>
                              <span>Đối tác kinh doanh</span>
                            </li>
                            <li className="d-grid">
                              {activeService === 3 ? (
                                <button className="btn btn-success btn-wave fw-5">
                                  Đã kích hoạt
                                </button>
                              ) : (
                                <button
                                  className="btn btn-primary btn-wave fw-5"
                                  onClick={() =>
                                    handleChangeService({
                                      pricing_id: 3,
                                      yearMonth: yearMonth,
                                    })
                                  }
                                >
                                  Kích hoạt
                                </button>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div
                      className="card custom-card overflow-hidden"
                      style={{ height: "615px" }}
                    >
                      <div className="card-body p-0">
                        <div className="px-1 py-2 bg-danger op-3"></div>
                        <div className="p-4">
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <div className="fs-18 fw-semibold">Hạng vàng</div>
                            <div>
                              <span className="badge bg-danger-transparent">
                                Cao cấp
                              </span>
                            </div>
                          </div>
                          <div className="fs-25 fw-bold mb-1">
                            {yearMonth === "months" ? "500,000" : "6,000,000"}đ
                            <sub className="text-muted fw-semibold fs-15 ms-1">
                              / {yearMonth === "months" ? "Tháng" : "Năm"}
                            </sub>
                          </div>
                          <div className="mb-1 text-muted">
                            Sử dụng tất cả các ứng dụng chuyên nghiệp, kèm những
                            ứng dụng mới nhất trong tương lai
                          </div>
                          <ul className="list-unstyled mb-0">
                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoIosCheckmarkCircleOutline className="text-success" />
                              </span>
                              <span>Hỗ trợ</span>
                            </li>
                            <li className="d-flex align-items-center text-danger mb-3">
                              <span className="me-2">
                                <IoIosCheckmarkCircleOutline className="text-success" />
                              </span>
                              <span>
                                Sử dụng ứng dụng:{" "}
                                <strong className="me-1">Tất cả</strong>
                              </span>
                            </li>
                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoIosCheckmarkCircleOutline className="text-success" />
                              </span>
                              <span>Bảo mật thông tin</span>
                            </li>
                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoIosCheckmarkCircleOutline className="text-success" />
                              </span>
                              <span>
                                Giảm giá khi đặt mua{" "}
                                <span className="fw-5">5%</span>
                              </span>
                            </li>
                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoIosCheckmarkCircleOutline className="text-success" />
                              </span>
                              <span>Nâng cấp theo yêu cầu</span>
                            </li>

                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoIosCheckmarkCircleOutline className="text-success" />
                              </span>
                              <span>Phát triển chức năng riêng</span>
                            </li>

                            <li className="d-flex align-items-center mb-3">
                              <span className="me-2">
                                <IoCloseCircleOutline className="text-danger" />
                              </span>
                              <span>Đối tác kinh doanh</span>
                            </li>
                            <li className="d-grid">
                              {activeService === 4 ? (
                                <button className="btn btn-success btn-wave fw-5">
                                  Đã kích hoạt
                                </button>
                              ) : (
                                <button
                                  className="btn btn-light btn-wave fw-5"
                                  onClick={() =>
                                    handleChangeService({
                                      pricing_id: 4,
                                      yearMonth: yearMonth,
                                    })
                                  }
                                >
                                  Kích hoạt
                                </button>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal payment */}
        {modalChageService && (
          <ModalReact
            modalTitle="Thông tin thanh toán"
            showModal={modalChageService}
            handleClose={handleCloseChangeService}
            theme={
              <PaymentTechPricing
                socketio={socketio}
                dataUser={dataUser}
                objectChange={objectChange.current}
              />
            }
          />
        )}
      </div>
    )
}
export default ContentPricingPC