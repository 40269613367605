import ContentPersonalMobile from "../../Component/ContentPersonalMobile"

function PersonalPageMobile(props) {
    const {socketio, dataUser} = props
    return (
        <div>
            {/* Header */}
            <div>Header</div>
            {/* Content */}
            <div className="mt-3">
                <ContentPersonalMobile />
            </div>
        </div>
    )
}

export default PersonalPageMobile