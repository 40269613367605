import { useEffect, useLayoutEffect, useRef, useState } from "react"
import AppContext from "./CreateContexts"
import io from "socket.io-client"

import { setMaDinhdanh } from "../reuses"
import { useNavigate } from "react-router-dom"
import LoadingSpinner from "../../Component/Loading/LoadingSpinner"

function ContextData({ children }) {
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const socket = useRef() // của hệ thống.
  const [getDataUser, setGetDataUser] = useState([])
  const [loading, setLoading] = useState(true)
  const checkLocal = localStorage.getItem("ob")
  const [userName, setUserName] = useState()


  // Kiểm tra internet.
  useEffect(() => {
    const handleOnline = () => setIsOnline(true)
    const handleOffline = () => setIsOnline(false)

    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)

    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
    }
  }, [isOnline])

  // connect socket
  useEffect(() => {
    if (isOnline) {
      socket.current = io(process.env.REACT_APP_DOMAIN)
      return () => {
        socket.current.disconnect()
      }
    }
    
  }, [])
  // Dùng để gọi dữ liệu
  useEffect(() => {
    // Kiểm tra kết nối internet
    if (isOnline) {
      // Kiểm tra xem user đã đăng nhập chưa
      if (checkLocal) {
        let strUser = JSON.parse(localStorage.getItem("ob"))
        // setUserName(strUser)
        if (strUser) {
          // Get data user
          const paramsGetUser = {
            iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
            object: {
              id: Number(strUser.email.match(/\d+/g).join(" ")),
            },
          }
          socket.current.emit(
            "client-get-data-user-id-tech-user",
            paramsGetUser,
          )
          socket.current.on("server-get-data-user-id-tech-user", (dataUser) => {
            setGetDataUser([...dataUser])
            setLoading(false)
          })
        } else {
          setLoading(false)
        }
      } else {
        // Trường hợp chưa đăng nhập
        setLoading(false)
      }
      // if (userName) {console.log("yes")
      //   // Get data user
      //   const paramsGetUser = {
      //     iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
      //     object: {
      //       id: Number(userName.email.match(/\d+/g).join(" ")),
      //     },
      //   }
      //   socket.current.emit("client-get-data-user-id-tech-user", paramsGetUser)
      //   socket.current.on("server-get-data-user-id-tech-user", (dataUser) => {
      //     setGetDataUser([...dataUser])
      //     setLoading(false)
      //   })
      // } else {console.log("no")
      //   setLoading(false)
      // }
    }
    return () => {
      socket.current.off("client-get-data-user-id-tech-user")
      socket.current.off("server-get-data-user-id-tech-user")
    }
  }, [isOnline])


  // Kiểm tra đường truyền internet.
  if (!isOnline) {
    return (
      <div className="text-center mt-3">
        Đường truyền internet không ổn định
      </div>
    )
  }
  if (loading) {
    // Kiểm tra xem loading data thành công chưa
    return <LoadingSpinner />
  }

  return (
    <AppContext.Provider
      value={{
        dataUser: getDataUser,
        connect: isOnline,
        socketio: socket.current,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default ContextData


