import { useEffect } from "react"
import { toast, ToastContainer } from "react-toastify"

function AlertSuccess({ activeAlert }) {
    // Khởi tạo hàm thông báo
    const notifiAlert = () =>
      toast.success("Đặt mua thành công!", {
        position: "bottom-left", // Hiển thị thông báo ở giữa trên cùng
        autoClose: 3000, // Thời gian tự động đóng (3 giây)
        hideProgressBar: false, // Hiển thị thanh tiến độ
        closeOnClick: true, // Đóng khi click vào thông báo
        pauseOnHover: true, // Tạm dừng khi di chuột vào
        draggable: true, // Kéo thả thông báo
        progress: undefined, // Thanh tiến độ mặc định
      })
    // Cập nhật khi giá trị đầu vào thay đổi và chạy hàm thông báo
    useEffect(() => {
        if (activeAlert) {
            notifiAlert()
        }
    }, [activeAlert])
    
    return (
      <div>
        {/* Thông báo khi đặt mua thành công */}
        <ToastContainer
          position="top-center" // Đặt vị trí giữa trên cùng của màn hình
          limit={1} // Giới hạn số thông báo hiển thị cùng lúc
          closeOnClick // Đóng khi nhấn vào thông báo
          pauseOnHover // Tạm dừng đóng khi hover vào thông báo
          draggable // Kéo thả thông báo
        />
      </div>
    )
}
export default AlertSuccess