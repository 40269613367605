import { useEffect,useRef, useState } from "react"
import LoadingSpinner from "../../Component/Loading/LoadingSpinner"

function ThueThuNhapCaNhan(props) {
  const { socketio, dataUser} = props
  const [dataBac, setDataBac] = useState([])
  const giamtruBanthan = useRef(0)
  const giamtruNguoiphuthuoc = useRef(0)
  const [objectSalary, setObjectSalary] = useState({
    luongThang: "",
    soNguoiPhuThuoc: "",
  })
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
    }
    // Get data cấp bậc.
    socketio.emit("client-get-data-thue-tncn-bac", params)
    socketio.on("server-get-data-thue-tncn-bac", (dataCapBac) => {
      setDataBac([...dataCapBac])
    })

    // Get data giảm trừ.
    socketio.emit("client-get-data-giamtruthue", params)
    socketio.on("server-get-data-giamtruthue", (dataGiamtruThue) => {
      giamtruBanthan.current = dataGiamtruThue.find(
        (item) => item.ky_hieu === "GT_BT",
      )
      giamtruNguoiphuthuoc.current = dataGiamtruThue.find(
        (item) => item.ky_hieu === "GT_NPT",
      )
      setLoading(false)
    })

    return () => {
      socketio.off("client-get-data-thue-tncn-bac")
      socketio.off("server-get-data-thue-tncn-bac")

      socketio.off("client-get-data-giamtruthue")
      socketio.off("server-get-data-giamtruthue")
    }
  }, [])

  // Set lương tháng.
  const [showContent, setShowContent] = useState("none")
  const handleLuongThangValue = (e) => {
    let formatValue = e.target.value.replace(/[,.]/g, "")
    if (Number(formatValue) > 0) {
      setShowContent("block")
    } else {
      setShowContent("none")
    }
    setObjectSalary({
      luongThang: Number(formatValue).toLocaleString().replace(/[.,]/g, ","),
      soNguoiPhuThuoc: objectSalary.soNguoiPhuThuoc,
    })
  }

  // Set số người phụ thuộc.
  const handleSonguoiPhuthuocValue = (e) => {
    setObjectSalary({
      luongThang: objectSalary.luongThang,
      soNguoiPhuThuoc: e.target.value,
    })
  }

  // Giải thích cách tính thuế TNCN.
  const khoanGiamtru = useRef(0)
  const [objectBac, setObjectBac] = useState([])
  const thu_nhap_chiu_thue = useRef(0)
  const tongThueThuNhapCaNhanPhaiDong = useRef(0)
  useEffect(() => {
    if (objectSalary.luongThang) {
      // Các khoản giảm trừ.
      let banThan = giamtruBanthan.current.money
      let nguoiPhuthuoc = giamtruNguoiphuthuoc.current.money
      khoanGiamtru.current =
        objectSalary.soNguoiPhuThuoc > 0
          ? Number(objectSalary.soNguoiPhuThuoc) *
            Number(giamtruNguoiphuthuoc.current.money)
          : 0

      // set thu nhập chịu thuế.
      let tongLuongthang = objectSalary.luongThang.replace(/[.,]/g, "")
      let thunhapChiuthue =
        Number(tongLuongthang) -
        banThan -
        (Number(objectSalary.soNguoiPhuThuoc) > 0 &&
          nguoiPhuthuoc * Number(objectSalary.soNguoiPhuThuoc))
      thu_nhap_chiu_thue.current = thunhapChiuthue

      const arrBac = []
      // bậc 1
      let bac1 = dataBac.find((item) => item.ky_hieu === "bac_1")
      let bac2 = dataBac.find((item) => item.ky_hieu === "bac_2")
      let bac3 = dataBac.find((item) => item.ky_hieu === "bac_3")
      let bac4 = dataBac.find((item) => item.ky_hieu === "bac_4")
      let bac5 = dataBac.find((item) => item.ky_hieu === "bac_5")
      let bac6 = dataBac.find((item) => item.ky_hieu === "bac_6")
      let bac7 = dataBac.find((item) => item.ky_hieu === "bac_7")
      // set bậc 1
      if (thunhapChiuthue <= Number(bac1.thunhap_tinhthue_den)) {
        arrBac.push(bac1)
      } else if (
        thunhapChiuthue > Number(bac2.thunhap_tinhthue_tu) &&
        thunhapChiuthue <= Number(bac2.thunhap_tinhthue_den)
      ) {
        arrBac.push(bac1, bac2)
      } else if (
        thunhapChiuthue > Number(bac3.thunhap_tinhthue_tu) &&
        thunhapChiuthue <= Number(bac3.thunhap_tinhthue_den)
      ) {
        arrBac.push(bac1, bac2, bac3)
      } else if (
        thunhapChiuthue > Number(bac4.thunhap_tinhthue_tu) &&
        thunhapChiuthue <= Number(bac4.thunhap_tinhthue_den)
      ) {
        arrBac.push(bac1, bac2, bac3, bac4)
      } else if (
        thunhapChiuthue > Number(bac5.thunhap_tinhthue_tu) &&
        thunhapChiuthue <= Number(bac5.thunhap_tinhthue_den)
      ) {
        arrBac.push(bac1, bac2, bac3, bac4, bac5)
      } else if (
        thunhapChiuthue > Number(bac6.thunhap_tinhthue_tu) &&
        thunhapChiuthue <= Number(bac6.thunhap_tinhthue_den)
      ) {
        arrBac.push(bac1, bac2, bac3, bac4, bac5, bac6)
      } else if (
        thunhapChiuthue > Number(bac7.thunhap_tinhthue_tu) &&
        thunhapChiuthue <= Number(bac7.thunhap_tinhthue_den)
      ) {
        arrBac.push(bac1, bac2, bac3, bac4, bac5, bac6, bac7)
      }
      // Điều kiện set objectBac
      if (thunhapChiuthue > 0) {
        setObjectBac([...arrBac])
        let total = 0
        for (let i = 0; i < arrBac.length; i++) {
          let thunhaptu = Number(arrBac[i].thunhap_tinhthue_tu)
          let thunhapden = Number(arrBac[i].thunhap_tinhthue_den)

          if (thunhapChiuthue < thunhapden) {
            thunhapden = thunhapChiuthue
          }
          total += ((thunhapden - thunhaptu) * arrBac[i].thuesuat) / 100
        }
        tongThueThuNhapCaNhanPhaiDong.current = total
      } else {
        setObjectBac([])
      }
    }
  }, [objectSalary])

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <div className="wrapper_module_content">
      <div className="col-xl-12">
        <div className="card custom-card currency-exchange-card btn btn-secondary-gradient btn-wave">
          <div className="card-body p-3 d-flex align-items-center justify-content-center">
            <div className="container">
              <h3 className="text-fixed-white text-center">
                Hệ thống tính thuế thu nhập cá nhân
              </h3>
              <span className="d-block fs-16 text-fixed-white text-center op-8 mb-4">
                Bạn cần điền tổng thu nhập/tháng & số người phụ thuộc trong gia
                đình (con cái)
              </span>
              <div className="p-3 mb-4 rounded currency-exchange-area">
                <div className="row gy-3 align-items-center">
                  <div className="col-xxl-3 col-12">
                    <input
                      type="text"
                      className="form-control"
                      value={objectSalary.luongThang}
                      placeholder="Tổng thu nhập/tháng"
                      onChange={handleLuongThangValue}
                      style={{ fontSize: "1.125rem" }}
                    />
                  </div>
                  <div className="col-xxl-2 col-12">
                    <input
                      type="text"
                      className="form-control"
                      value={objectSalary.soNguoiPhuThuoc}
                      placeholder="Số người phụ thuộc"
                      onChange={handleSonguoiPhuthuocValue}
                      style={{ fontSize: "1.125rem" }}
                    />
                  </div>
                  <div className="col-xxl-2 col-12">
                    <div className="fs-2 text-fixed-white text-center op-8 lh-1">
                      =
                    </div>
                  </div>
                  <div
                    className="col-xxl-3 col-12 fs-15"
                    style={{ color: "white" }}
                  >
                    {Number(thu_nhap_chiu_thue.current) > 0 ? (
                      <>
                        <span className="fw-5 fs-21">
                          {tongThueThuNhapCaNhanPhaiDong.current
                            .toLocaleString()
                            .replace(/[.]/g, ",")}
                          đ
                        </span>
                        <span className="fs-15 ms-3">(Thuế TNCN phải nộp)</span>
                      </>
                    ) : (
                      <>
                        <span className="fw-5 fs-21">0đ</span>
                        <span className="fs-15 ms-3">(Thuế TNCN phải nộp)</span>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="fs-15">
                <span className="fw-5 me-1">Lưu ý:</span>
                <span className="me-1">
                  Thu nhập trong tháng gồm tiền lương, tiền công, thưởng{" "}
                  <b>đã trừ</b> các khoản bảo hiểm bắt buộc gồm:
                </span>
                <span className="me-1 fw-5">1. Bảo hiểm xã hội (8%)</span>
                <span className="me-1 fw-5">2. Bảo hiểm y tế (1,5%)</span>
                <span className="me-1 fw-5">3. Bảo hiểm thất nghiệp 1%</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 d-flex justify-content-center gap-3">
        <div className="col-xl-6 col-12" style={{ display: showContent }}>
          <div className="card custom-card rounded-4">
            {/* <div className="alert alert-primary fw-5 text-center fs-18 mt-1">
                Giải thích cách tính thuế TNCN
              </div> */}
            {/* <div className="card-header">
                <div className="card-title fw-5 fs-15"></div>
              </div> */}
            <div className="card-body">
              <div className="alert alert-primary fw-5 text-center fs-18 rounded-3">
                Giải thích cách tính thuế TNCN
              </div>
              {Number(thu_nhap_chiu_thue.current) > 0 ? (
                <>
                  <div className="alert alert-light">
                    <div className="text-center fw-5 fs-15 mb-3">
                      Các khoản được giảm trừ
                    </div>
                    <div className="fs-16 mb-1">
                      <span className="fw-5">Giảm trừ bản thân = </span>
                      <span>
                        {giamtruBanthan.current.money
                          .toLocaleString()
                          .replace(/[.]/g, ",")}
                        đ
                      </span>
                    </div>
                    <div className="fs-16 mb-1">
                      <span className="fw-5">Giảm trừ người phụ thuộc = </span>
                      <span>
                        {objectSalary.soNguoiPhuThuoc} x{" "}
                        {giamtruNguoiphuthuoc.current.money
                          .toLocaleString()
                          .replace(/[.]/g, ",")}{" "}
                        ={" "}
                        {Number(khoanGiamtru.current)
                          .toLocaleString()
                          .replace(/[.]/g, ",")}
                        đ
                      </span>
                    </div>
                    <div className="fs-16 mb-1">
                      <span className="fw-5">Thu nhập chịu thuế = </span>
                      <span>
                        <span>{objectSalary.luongThang}</span>
                        <span className="ms-2 me-2">-</span>
                        <span>
                          {giamtruBanthan.current.money
                            .toLocaleString()
                            .replace(/[.]/g, ",")}
                        </span>
                        {Number(objectSalary.soNguoiPhuThuoc) > 0 && (
                          <>
                            <span className="ms-2 me-2">-</span>
                            <span>
                              {Number(khoanGiamtru.current)
                                .toLocaleString()
                                .replace(/[.]/g, ",")}
                            </span>
                          </>
                        )}
                        <span className="ms-2 me-2">=</span>
                        {Number(thu_nhap_chiu_thue.current)
                          .toLocaleString()
                          .replace(/[.]/g, ",")}
                        đ
                      </span>
                    </div>
                  </div>
                  <div
                    className="mt-2 mb-2"
                    style={{ border: "0.5px dashed #c6c6c6" }}
                  ></div>
                </>
              ) : (
                <div className="text-center">
                  <span className="fs-18 text-primary">
                    Thu nhập của bạn chưa phải đóng thuế !
                  </span>
                </div>
              )}
              {/* Các cấp bậc */}
              <div className="mt-3">
                {objectBac.length > 0 &&
                  objectBac.map((item, index) => {
                    let tongThuNhapChiuThue_ = Number(
                      thu_nhap_chiu_thue.current,
                    )
                    let thunhaptu = Number(item.thunhap_tinhthue_tu)
                    let thunhapden = Number(item.thunhap_tinhthue_den)
                    let thunhaptuFormat = thunhaptu
                      .toLocaleString()
                      .replace(/[.]/g, ",")
                    let thunhapdenFormat = thunhapden
                      .toLocaleString()
                      .replace(/[.]/g, ",")
                    let thunhapchiuthueFormat =
                      ((thunhapden - thunhaptu) * Number(item.thuesuat)) / 100

                    if (tongThuNhapChiuThue_ < thunhapden) {
                      thunhapden = tongThuNhapChiuThue_
                      thunhapdenFormat = tongThuNhapChiuThue_
                        .toLocaleString()
                        .replace(/[.]/g, ",")
                      thunhapchiuthueFormat =
                        ((thunhapden - thunhaptu) * Number(item.thuesuat)) / 100
                    }
                    return (
                      <div
                        className="fs-16 mb-3 d-flex gap-2 align-items-center"
                        key={index}
                      >
                        <div className="fw-5" style={{ width: "14%" }}>
                          {item.title}
                        </div>
                        <div
                          style={{
                            borderLeft: "3px solid #d9d9d9",
                            height: "45px",
                          }}
                        ></div>
                        <div style={{ width: "81%" }}>
                          {item.content}
                          <p className="mb-1 text-danger">
                            <span>
                              ({thunhapdenFormat}{" "}
                              {thunhaptu > 0 && " - " + thunhaptuFormat})
                            </span>
                            <span className="ms-2 me-2">x</span>
                            <span>{item.thuesuat}%</span>
                            <span className="ms-2 me-2">=</span>
                            {/* Tổng */}
                            <span>
                              {thunhapchiuthueFormat
                                .toLocaleString()
                                .replace(/[.]/g, ",")}
                            </span>
                          </p>
                        </div>
                      </div>
                    )
                  })}
                {/* Tổng thuế phải đóng */}
                {Number(thu_nhap_chiu_thue.current) > 0 && (
                  <div className="fs-15 alert alert-danger">
                    <span className="fw-5">
                      Tổng thuế bạn phải đóng{" "}
                      <span className="ms-2 me-2">=</span>
                      {objectBac.map((item, index) => {
                        return (
                          <span key={index}>
                            {item.title}
                            {index === objectBac.length - 1 ? "" : " + "}
                          </span>
                        )
                      })}
                      <span className="ms-2 me-2">=</span>
                    </span>
                    <span className="fw-5 fs-18">
                      {tongThueThuNhapCaNhanPhaiDong.current
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                      đ
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThueThuNhapCaNhan
