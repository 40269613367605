import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { GoArrowLeft, GoArrowRight } from "react-icons/go"
import { handleShowAlertWarning } from "../../../Component/Alert_sweetalert/AlertWarning"
import ListRequestCustomer from "../../Admin/Component/Cate-donhang/ListRequestCustomer"
import { BsCheck2Circle } from "react-icons/bs"
import moment from "moment"

// Tạo mới đơn đặt hàng.
function FormSoftwareOrder(props) {
    const {socketio, dataUser, resultOrderNew} = props
    
    
    // Kết quả submit từ form tạo dự án.
    const [dataCreateProject, setDataCreateProject] = useState([])
    const handleFormProject = (dataNewCreate) => {
      setDataCreateProject(dataNewCreate);
      // trả về để cập nhật danh sách hiển thị khi thêm mới thành công
      resultOrderNew(dataNewCreate)
    }

    // Quản lý quay lại bước 1.
    const [checkPage, setCheckPage] = useState("buoc-1")
    const handleBackBuoc1 = () => {
        setCheckPage("buoc-1")
    }

    // Quản lý next bước 2.
    const handleNextBuoc2 = () => {
        if (dataCreateProject.length > 0) {
            setCheckPage("buoc-2")
        } else {
            handleShowAlertWarning({
              title: "Cảnh báo",
              html: "<div class='text-danger'>Bạn cần tạo tên dự án trước đã.</div>",
            })
        }
    }


    return (
      <div>
        {checkPage === "buoc-1" && (
          <div className="d-flex justify-content-between gap-3">
            <div className="fs-17 fw-5">Bước 1: Tạo tên dự án</div>
            <button
              type="button"
              className="border-0 bg-transparent d-flex align-items-center gap-2 text-muted"
              onClick={handleNextBuoc2}
            >
              Bước 2<GoArrowRight className="fs-21" />
            </button>
          </div>
        )}
        {checkPage === "buoc-2" && dataCreateProject.length > 0 && (
          <div className="d-flex gap-3">
            <button
              type="button"
              className="border-0 bg-transparent d-flex align-items-center gap-2 text-muted"
              onClick={handleBackBuoc1}
            >
              <GoArrowLeft className="fs-21" />
            </button>
            <div className="text-center col-10 fw-5">
              Bước 2: Xây dựng chức năng cho dự án
            </div>
          </div>
        )}
        {/* kiểm tra để hiển thị đã tạo tên dự án */}
        {checkPage === "buoc-1" && dataCreateProject.length > 0 && (
          <div className="text-center fs-18 mt-3 d-flex flex-direction-column align-items-center justify-content-center">
            <BsCheck2Circle
              className="text-success"
              style={{ fontSize: "68px" }}
            />
            <div className="fs-16 mt-2 mb-2 rounded-4">
              <table className="table text-nowrap table-bordered rounded-3">
                <tbody>
                  <tr>
                    <td>
                      <span className="fs-16 fw-5">
                        Tên dự án
                      </span>
                    </td>
                    <td>
                      <span className="fs-16">
                        {dataCreateProject[0].project_name}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="fs-16 fw-5">
                        Ngày tạo
                      </span>
                    </td>
                    <td>
                      <span className="fs-16">
                        {moment(dataCreateProject[0].addtime).format(
                          "DD/MM/YYYY HH:mm",
                        )}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        {/* Hiển thị form tạo dự án */}
        {dataCreateProject.length === 0 && (
          <FormProjectName {...props} result={handleFormProject} />
        )}
        {/* Bước 2: xây dựng chức năng cho dự án */}
        {checkPage === "buoc-2" && dataCreateProject.length > 0 && (
          <div className="mt-3">
            <ListRequestCustomer
              socketio={socketio}
              dataUser={dataUser}
              dataDetail={dataCreateProject}
            />
          </div>
        )}
      </div>
    )
}
export default FormSoftwareOrder

// Tạo tên dự án.
function FormProjectName(props) {
    const {socketio, dataUser, result} = props
    const { register, handleSubmit, formState: { errors } } = useForm()
    // Xử lý submit.
    const  [changeSuccess, setChangeSuccess] = useState("")
    const handleOnSubmit = (object) => {
        object.user_id = dataUser[0].id;
        const params = {
            iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
            object: object
        }
        socketio.emit("client-create-data-software-orders", params)
        socketio.on("server-create-data-software-orders", (data) => {
            if (data.length > 0) {
                setChangeSuccess(object);
                result(data)
            }
        })
    }
    useEffect(() => {
        return () => {
            socketio.off("client-create-data-software-orders")
            socketio.off("server-create-data-software-orders")
        }
    }, [changeSuccess])

    return (
        <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="row mt-3">
                <div className="form-floating mb-3 col-12">
                    <input
                        type="text"
                        className="form-control rounded-4"
                        placeholder="Tên input"
                        {...register("project_name", { required: true })}
                    />
                    <label className="fs-15 ms-2 text-muted">Tên dự án</label>
                    {errors.project_name && (
                        <span className="fs-14 text-danger">
                            *Bạn cần nhập tên dự án của bạn.
                        </span>
                    )}
                </div>
            </div>
            {/* Button */}
            <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
                <button
                    className="btn btn-primary-light m-1 fw-5 rounded-5"
                    type="submit"
                >
                    Tạo dự án
                </button>
            </div>
        </form>
    );
}


