import { useState } from "react";
import { GoLog, GoPeople, GoTasklist } from "react-icons/go"
import { IoChatbubbleEllipsesOutline, IoCodeSlashOutline } from "react-icons/io5"
import { PiShoppingBagOpen } from "react-icons/pi";
import { Link, useLocation } from "react-router-dom";

function MenuAdmin(props) {
  const location = useLocation()
  const path = location.pathname;
  
    return (
      <div className="d-flex justify-content-end gap-3 rounded-4">
        <Link
          to="/role-admin/cate-user"
          className={`btn btn-wave bg-transparent border-0 rounded-4 btn-light d-flex flex-direction-column justify-content-center align-items-center ${
            path === "/role-admin/cate-user" || path === "/role-admin"
              ? "active"
              : "text-muted"
          }`}
        >
          <GoPeople className="fs-21" />
          Người dùng
        </Link>
        <Link
          to="/role-admin/cate-shops"
          className={`btn btn-wave bg-transparent border-0 rounded-4 btn-light d-flex flex-direction-column justify-content-center align-items-center ${
            path === "/role-admin/cate-shops" ? "active" : "text-muted"
          }`}
        >
          <PiShoppingBagOpen className="fs-21" />
          Đơn hàng
        </Link>
        <Link
          to="/role-admin/cate-payment"
          className={`btn btn-wave bg-transparent border-0 rounded-4 btn-light d-flex flex-direction-column justify-content-center align-items-center ${
            path === "/role-admin/cate-payment" ? "active" : "text-muted"
          }`}
        >
          <GoLog className="fs-21" />
          Thanh toán
        </Link>
        <Link
          to="/role-admin/cate-chatbot"
          className={`btn btn-wave bg-transparent border-0 rounded-4 btn-light d-flex flex-direction-column justify-content-center align-items-center ${
            path === "/role-admin/cate-chatbot" ? "active" : "text-muted"
          }`}
        >
          <IoChatbubbleEllipsesOutline className="fs-21" />
          Set chatbot
        </Link>
        <Link
          to="/role-admin/cate-software-designer"
          className={`btn btn-wave bg-transparent border-0 rounded-4 btn-light d-flex flex-direction-column justify-content-center align-items-center ${
            path === "/role-admin/cate-software-designer" ? "active" : "text-muted"
          }`}
        >
          <IoCodeSlashOutline className="fs-21" />
          Thiết kế web
        </Link>
        <Link
          to="/role-admin/cate-statistical"
          className={`btn btn-wave bg-transparent border-0 rounded-4 btn-light d-flex flex-direction-column justify-content-center align-items-center ${
            path === "/role-admin/cate-statistical" ? "active" : "text-muted"
          }`}
        >
          <GoTasklist className="fs-21" />
          Thống kê
        </Link>
      </div>
    )
}
export default MenuAdmin