import CheckPCMobile from "../../handle-reuses/reuses/check.pc.mobile";
import RentEstatePageMobile from "./Theme/Mobile";
import RentEstatePagePC from "./Theme/PC";

function RentEstatePage() {
    return (
        <CheckPCMobile>
            {(isMobile) => isMobile ? <RentEstatePageMobile /> : <RentEstatePagePC />}
        </CheckPCMobile>
    )
}

export default RentEstatePage