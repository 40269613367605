import Tippy from "@tippyjs/react";
import Tippyheandless from "@tippyjs/react/headless";
import { useEffect, useRef, useState } from "react";
import { IoAddCircle, IoCardOutline, IoOptionsOutline } from "react-icons/io5";
import LoadingSpinner from "../../../../Component/Loading/LoadingSpinner";
import { IoIosArrowDown, IoIosArrowUp, IoIosCloseCircle} from "react-icons/io";
import SearchHorizontal from "../../../../Component/Search/SearchHorizontal";
import { HiAdjustmentsHorizontal, HiOutlineCalendarDays } from "react-icons/hi2";
import ButtonAddFunction from "./ButtonAddFunction";
import { BsDot, BsFillCheckCircleFill } from "react-icons/bs";
import moment from "moment";
import ModalReact from "../../../../Component/Modal/ModalReact";
import { useForm } from "react-hook-form";
import { FormEditFunctions, FormEditWorkdayFunction } from "./HandleForm";
import Sortable from "sortablejs";
import { handleShowAlertWarning } from "../../../../Component/Alert_sweetalert/AlertWarning";
import { FaPencilAlt } from "react-icons/fa"
import { MdCancel, MdOutlineDoubleArrow } from "react-icons/md";
import { PiSimCardFill } from "react-icons/pi";
import { RiCheckboxCircleFill } from "react-icons/ri";


function ListRequestCustomer(props) {
  const { socketio, dataUser, dataDetail } = props
  const [dataRequest, setDataRequest] = useState([])
  const dataRequestRef = useRef([])
  const totalWorkday = useRef(0)
  const totalPaymentAmount = useRef(0)
  const [objectSearch, setObjectSearch] = useState({ textSearch: "" })
  const [loading, setLoading] = useState(true)

  // Set thời gian làm việc & số tiền thanh toán.
  const handleWorkdayPayment = (data) => {
    // Set thời gian làm việc
    let arrWorkday = data.map((item) => item.workday)
    totalWorkday.current = arrWorkday.reduce((a, b) => Number(a) + Number(b))
    // Set tổng tiền thanh toán.
    let arrPayment = data.map((item) => item.payment_amount)
    totalPaymentAmount.current = arrPayment.reduce(
      (a, b) => Number(a) + Number(b),
    )
  }
  // Get data request.
  const handleGetDataRequestCustomer = (object) => {
    const params = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
      object: {
        software_orders_id: dataDetail[0].id,
        textSearch: object.textSearch,
      },
    }
    socketio.emit("client-get-data-id-software-orders-list", params)
    socketio.on("server-get-data-id-software-orders-list", (data) => {
      setDataRequest([...data])
      dataRequestRef.current = [...data]
      if (data.length > 0) {
        // Tinhs toán thời gian làm việc & thanh toán
        handleWorkdayPayment([...data])
      }
      setLoading(false)
    })
  }

  // Set data ban đầu.
  useEffect(() => {
    handleGetDataRequestCustomer(objectSearch)
    return () => {
      socketio.off("client-get-data-id-software-orders-list")
      socketio.off("server-get-data-id-software-orders-list")
    }
  }, [loading, objectSearch])

  // Quản lý data update khi create success.
  const handleDataUpdateCreateSuccess = (dataUpdateCreate) => {
    if (dataUpdateCreate.length > 0) {
      setLoading(true)
    }
  }

  // Quản lý update data khi edit success.
  const handleUpdateDataEditSuccess = (dataResult) => {
    // Xử lý update.
    if (dataResult[0].delete_orders_list === 0) {
      let dataUpdate = dataRequestRef.current
      let index = dataUpdate.findIndex((item) => item.id === dataResult[0].id)
      dataUpdate[index] = dataResult[0]
      setDataRequest([...dataUpdate])
      dataRequestRef.current = [...dataUpdate]
      handleWorkdayPayment([...dataUpdate])
      // Cập nhật lại danh sách nội dung chức năng.
      handleShowHide(dataResult[0].id)
    } else if (dataResult[0].delete_orders_list === 1) {
      // Xử lý cập nhận dữ liệu khi đã xóa
      let dataUpdate = dataRequestRef.current.filter((item)=> item.id !== dataResult[0].id)
      setDataRequest([...dataUpdate])
      dataRequestRef.current = [...dataUpdate]
      handleWorkdayPayment([...dataUpdate])
    }
  }

  // Quản lý hiển thị chi tiết danh sách thông tin của chức năng.
  const [showHideContent, setShowHideContent] = useState("")
  const [listInfoOfFunction, setListInfoOfFunction] = useState([])
  const listInfoOfFunctionRef = useRef([])
  const handleShowHide = (id) => {
    const paramsInfo = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
      object: {
        orders_list_id: id,
      }
    }
    socketio.emit("client-get-data-software-order-list-function", paramsInfo)
    socketio.on("server-get-data-software-order-list-function", (dataInfo) => {
      if (dataInfo.length > 0) {
        setListInfoOfFunction([...dataInfo])
        listInfoOfFunctionRef.current = [...dataInfo]
      }
      setShowHideContent(id)
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-get-data-software-order-list-function")
      socketio.off("server-get-data-software-order-list-function")
    }
  }, [listInfoOfFunction])

  // Quản lý tìm kiếm chức năng.
  const handleSearchFunction = (value) => {
    setObjectSearch({ textSearch: value })
  }

  // Quản lý sắp xếp danh sách
  
  const sortableContainer = useRef(null)
  const dataUpdateChangeSort = useRef([])
  const [checkChange, setCheckChange] = useState([])
  useEffect(() => {
    if (!loading) {
      const sortable = Sortable.create(sortableContainer.current, {
        animation: 500,
        onEnd: (evt) => {
          const newItems = [...dataRequestRef.current]
          /**==== Cách hoán đổi 2 vị trí cho nhau (Nhược điểm: Cảm giác như bị nhảy dữ liệu) === */
          // Lấy item bị hoán đổi vị trí
          // const draggedItem = newItems[evt.oldIndex] // mục được kéo
          // const swappedItem = newItems[evt.newIndex] // mục hoán đổi

          // // Hoán đổi vị trí của 2 item (cập nhật lại cột sort_function khi đổi vị trí cho nhau)
          // const tempOrder = draggedItem.sort_function
          // draggedItem.sort_function = swappedItem.sort_function
          // swappedItem.sort_function = tempOrder
          /**==== *Cách hoán đổi 2 vị trí cho nhau === */

          // Cập nhật lại data khi kéo thả (Nhược điểm edit lại tất cả item trong mảng)
          const [removed] = newItems.splice(evt.oldIndex, 1);
          newItems.splice(evt.newIndex, 0, removed)

          let arr = []
          for (let i = 0; i < newItems.length; i++) {
            newItems[i].sort_function = i + 1;
            arr.push(newItems[i])
          }

          // Set data mới
          setCheckChange(arr)
          // dataRequestRef.current = arr
          // Lưu sự thay đổi vào database.
          dataUpdateChangeSort.current = arr //[draggedItem, swappedItem];
        },
      })

      // Cleanup function khi component bị hủy
      return () => {
        sortable.destroy()
      }
    }
  }, [loading]);
  // Xử lý cập nhật lên cơ sở dữ liệu khi sort success.
  useEffect(() => {
    if (dataUpdateChangeSort.current.length > 0) {
      let i = 0;
      let max_ = dataUpdateChangeSort.current.length;
      let dataChangeSort = dataUpdateChangeSort.current;
      function handleUpdateSort() {
        if (i <= max_) {
          if (i < max_) {
            const paramsChangeSort = {
              iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
              object: {
                id: dataChangeSort[i].id,
                sort_function: dataChangeSort[i].sort_function,
              },
            };
            socketio.emit(
              "client-edit-row-sort-funciton-software-orders-list",
              paramsChangeSort,
            );
          }
          i++
          setTimeout(handleUpdateSort, 1)
          if (i === max_) {
            // setLoading(true)
            dataRequestRef.current = checkChange;
          }
        }
      }
      handleUpdateSort()

      return () => {
        socketio.off("client-edit-row-sort-funciton-software-orders-list")
      }
    }
  }, [checkChange])

  // Check show menu option.
  const [checkShowMenuOption, setCheckShowMenuOption] = useState("")
  const handleCheckShowMenu = (id) => {
    setCheckShowMenuOption(id)
  }

  // Thêm các vấn đề cho từng mục trong từng chức năng.
  const [modalProblem, setModalProblem] = useState(false)
  const dataProblemRef = useRef([])
  const handleShowProblemList = (item1, item2) => {
    dataProblemRef.current = [item1 , item2]
    setModalProblem(true)
  }
  const handleCloseProblem = () => {
    setModalProblem(false)
    dataProblemRef.current = []
  }

  // Quản lý edit danh sách yêu cầu của chức năng.
  const [activeEditID, setActiveEditID] = useState("")
  const [valueTextRequest, setValueTextRequest] = useState("")
  const checkEditListFunction = useRef()
  const handleButtonEdit = (id, textRequest) => {
    setActiveEditID(id)
    setValueTextRequest(textRequest);
  }
  const handleGetValueTextRequest = (e) => {
    setValueTextRequest(e.target.value)
  }
  const handleEditInfoOfFunction = (status_) => {
    if (status_ === "cancel") {
      setActiveEditID("")
      setValueTextRequest("")
    } else if (status_ === "agree") {
      // Update data.
      const paramsEditFunction = {
        iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
        object: {
          id: activeEditID,
          function_name: valueTextRequest
        }
      }
      socketio.emit("client-edit-row-function-name-software-order-list-function", paramsEditFunction)
      socketio.on("server-edit-row-function-name-software-order-list-function", (data) => {
        if (data.length > 0) {
          // Xử lý update.
          let dataUpdate = listInfoOfFunctionRef.current
          let index = dataUpdate.findIndex((item) => item.id === data[0].id);
          dataUpdate[index] = data[0]
          setListInfoOfFunction([...dataUpdate])
          listInfoOfFunctionRef.current = [...dataUpdate]
          setActiveEditID("")
          setValueTextRequest("")
          checkEditListFunction.current = data
        }
      })
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-row-function-name-software-order-list-function")
      socketio.off("server-edit-row-function-name-software-order-list-function")
    }
  }, [checkEditListFunction.current])


  if (loading) {
    return <LoadingSpinner />
  }
 
  return (
    <div>
      {/* Thêm yêu cầu */}
      <div className="wrapper_ground align-items-center gap-1 mb-2">
        <div className="list_items mb-1" style={{ width: "100%" }}>
          <div className="card-body">
            <div className="row gy-xl-0 gy-3">
              <div className="col-xxl-4 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                <div>
                  <div className="category-link orange text-center p-1 rounded-4">
                    <button
                      className="border-0 rounded-5 bg-orange"
                      style={{ width: "35px", height: "35px" }}
                    >
                      <HiAdjustmentsHorizontal className="fs-21 text-white" />
                    </button>
                    <p className="fs-18 mb-0 text-default fw-semibold">
                      {dataRequest.length}
                    </p>
                    <span className="fs-15 text-muted">Mục yêu cầu</span>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                <div>
                  <div className="category-link orange text-center p-1 rounded-4">
                    <button
                      className="border-0 rounded-5 bg-orange"
                      style={{ width: "35px", height: "35px" }}
                    >
                      <HiOutlineCalendarDays className="fs-21 text-white" />
                    </button>

                    <p className="fs-18 mb-0 text-default fw-semibold">
                      {totalWorkday.current} ngày
                    </p>
                    <span className="fs-15 text-muted">Số ngày dự kiến</span>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                <div>
                  <div className="category-link orange text-center p-1 rounded-4">
                    <button
                      className="border-0 rounded-5 bg-orange"
                      style={{ width: "35px", height: "35px" }}
                    >
                      <IoCardOutline className="fs-21 text-white" />
                    </button>
                    <p className="fs-18 mb-0 text-default fw-semibold">
                      {totalPaymentAmount.current
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                      đ
                    </p>
                    <span className="fs-15 text-muted">Giá dự kiến</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="wrapper_ground d-flex gap-1"
          style={{ minWidth: "100%" }}
        >
          <div className="col-10 list_items">
            <SearchHorizontal
              placeholder="Tên chức năng"
              className="rounded-5"
              result={handleSearchFunction}
              style={{ height: "45px" }}
            />
          </div>
          {/* Thêm mới chức năng */}
          <div className="list_items text-end">
            <ButtonAddFunction
              {...props}
              dataRequest={dataRequest}
              resultDataUpdate={handleDataUpdateCreateSuccess}
            />
          </div>
        </div>
      </div>
      {/* Danh sách yêu cầu */}
      <div ref={sortableContainer}>
        {dataRequest.map((item, index) => {
          // set arr content request.
          let arr = item.content.split(",")
          // Set thời gian hoàn thành.
          let setTimeSuccess = ""
          if (item.date_start) {
            let dateStart = moment(item.date_start)
            let dateEnd = moment(item.date_end)
            let currentTime = moment()
            if (item.date_end) {
              let diffDays = Number(dateEnd.diff(dateStart, "days"))
              setTimeSuccess = diffDays
            } else {
              setTimeSuccess = item.workday
            }
          }

          return (
            <div
              key={index}
              className={`mb-2 alert text-default rounded-4 ${
                item.workday === 0 && "alert-danger"
              } ${item.workday > 0 && item.status === 0 && "alert-warning"} ${
                item.workday > 0 && item.status === 1 && "alert-success"
              }`}
            >
              <div className="fs-18 rounded-4 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap-2">
                  <div>
                    <span className="me-1 fw-5 fs-18">
                      {item.sort_function}.
                    </span>
                    {item.cate}
                  </div>
                  {item.status === 1 && (
                    <BsFillCheckCircleFill className="text-success" />
                  )}
                  {item.status === 0 &&
                    (!setTimeSuccess ? (
                      <span className="badge bg-danger-transparent">
                        Chưa xử lý
                      </span>
                    ) : (
                      <span className="badge bg-warning-transparent">
                        Đang xử lý
                      </span>
                    ))}
                </div>
                <div className="d-flex align-items-center gap-2">
                  {showHideContent !== item.id && (
                    <button
                      className="border-0 rounded-5"
                      style={{ width: "33px", height: "33px" }}
                      onClick={() => handleShowHide(item.id)}
                    >
                      <IoIosArrowDown className="fs-18" />
                    </button>
                  )}
                  {showHideContent === item.id && (
                    <button
                      className="border-0 rounded-5"
                      style={{ width: "33px", height: "33px" }}
                      onClick={() => handleShowHide("")}
                    >
                      <IoIosArrowUp className="fs-18" />
                    </button>
                  )}
                  {/*button option */}
                  <Tippyheandless
                    interactive
                    delay={[0, 0]}
                    placement="bottom-end"
                    trigger="click"
                    render={(instance) => {
                      if (
                        instance["data-placement"] &&
                        item.id === checkShowMenuOption
                      ) {
                        return (
                          <OptionRequest
                            {...props}
                            object={item}
                            resultDataUpdate={handleUpdateDataEditSuccess}
                          />
                        )
                      }
                    }}
                  >
                    <button
                      className="border-0 bg-orange rounded-5"
                      style={{ width: "33px", height: "33px" }}
                      onClick={() => handleCheckShowMenu(item.id)}
                    >
                      <IoOptionsOutline className="fs-18" />
                    </button>
                  </Tippyheandless>
                </div>
              </div>
              {showHideContent === item.id && (
                <ul className="list-group rounded-4 mt-2">
                  {listInfoOfFunction.map((item_, index) => {
                    return (
                      <li
                        className="list-group-item fs-16 d-flex justify-content-between align-items-start"
                        key={index}
                      >
                        <div className="d-flex gap-1 col-10">
                          <span className="me-2 fw-5">{index + 1}</span>
                          {activeEditID === item_.id ? (
                            <>
                              <input
                                type="text"
                                className="border-0 bg-danger-transparent rounded-4 px-1 col-8"
                                value={valueTextRequest}
                                onChange={handleGetValueTextRequest}
                              />
                              <button
                                className="border-0 bg-transparent p-0"
                                onClick={() =>
                                  handleEditInfoOfFunction("cancel")
                                }
                              >
                                <MdCancel className="fs-21 text-muted" />
                              </button>
                              <button
                                className="border-0 bg-transparent p-0"
                                onClick={() =>
                                  handleEditInfoOfFunction("agree")
                                }
                              >
                                <RiCheckboxCircleFill className="fs-21 text-success" />
                              </button>
                            </>
                          ) : (
                            <span>{item_.function_name}</span>
                          )}
                        </div>
                        <div className="d-flex gap-2">
                          {/* điều kiện hiển thị nút sửa (khi mục giao diện chưa thiết lập ngày làm việc) */}
                          {(dataUser[0].role_token ===
                            process.env.REACT_APP_ROLE_ADMIN ||
                            item.workday === 0) && (
                            <button
                              className="border-0 bg-transparent"
                              onClick={() =>
                                handleButtonEdit(item_.id, item_.function_name)
                              }
                            >
                              <FaPencilAlt className="text-muted fs-15" />
                            </button>
                          )}

                          <Tippy content="Thêm vấn đề cần giải quyết">
                            <button
                              className="border-0 bg-transparent"
                              onClick={() => handleShowProblemList(item, item_)}
                            >
                              <IoAddCircle className="fs-21 text-muted" />
                            </button>
                          </Tippy>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>
          )
        })}
      </div>
      {/* Modal problem (giải quyết vấn đề của khách hàng) */}
      {modalProblem && (
        <ModalReact
          modalTitle="Giải quyết vấn đề về chức năng"
          showModal={modalProblem}
          handleClose={handleCloseProblem}
          theme={<ListProblemFunction {...props} dataProblem={ dataProblemRef.current} />}
        />
      )}
    </div>
  )
}
export default ListRequestCustomer

// Component xử lý lựa chọn.
function OptionRequest(props) {
  const {socketio, dataUser, object, resultDataUpdate } = props;
  // Thêm nội dung mới cho chức năng
  const [modalAddContent, setModalAddContent] = useState(false);
  const handleAddContent = () => {
    if (dataUser[0].role_token === process.env.REACT_APP_ROLE_ADMIN) {
      setModalAddContent(true)
    } else {
      if (Number(object.workday) === 0) {
        setModalAddContent(true)
      } else {
        handleShowAlertWarning({
          title: "Cảnh báo",
          html: "<div class='text-danger'>Chức năng đã đưa vào xử lý bạn không thể thêm nội dung, hãy liên hệ kỹ thuật viên giúp tôi <span class='fw-5 fs-18'>0934.222.740</span>.</div>"
        })
      }
    }
  }
  const handleCloseAddContent = () => {
    setModalAddContent(false)
  }
  const handleAddContentSuccess = (dataUpdate) => {
    resultDataUpdate(dataUpdate)
    setModalAddContent(false)
  }

  // Sửa nội dung cho chức năng.
  const [modalEditFunction, setModalEditFunction] = useState(false)
  const handleEditFunction = () => {
    if (dataUser[0].role_token === process.env.REACT_APP_ROLE_ADMIN) {
      setModalEditFunction(true)
    } else {
      if (Number(object.workday) === 0) {
        setModalEditFunction(true)
      } else {
        handleShowAlertWarning({
          title: "Cảnh báo",
          html: "<div class='text-danger'>Chức năng đã đưa vào xử lý bạn không thể sửa nội dung, hãy liên hệ kỹ thuật viên <span class='fw-5 fs-18'>0934.222.740</span>.</div>",
        })
      }
    }
  }
  const handleCloseEditFunction = () => setModalEditFunction(false)
  const handleEditFunctionSuccess = (dataUpdate) => {
    resultDataUpdate(dataUpdate)
    setModalEditFunction(false)
  }

  // Sửa ngày bắt đầu làm việc.
  const [modalEditWorkday, setModalEditWorkday] = useState(false)
  const handleEditWorkday = () => setModalEditWorkday(true);
  const handleCloseWorkday = () => setModalEditWorkday(false);
  const handleEditWorkdaySuccess = (dataUpdate) => {
    resultDataUpdate(dataUpdate)
    setModalEditWorkday(false)
  }

  // Xác nhận hoàn thành chức năng.
  const editStatus = useRef()
  const handleEditStatusFunction = () => {
    let checkEdit = window.confirm("Bạn muốn xác nhận hoàn thành chức năng này?")
    if (checkEdit) {
      // Kiểm tra xem ngày làm việc được thiết lập hoàn tất chưa.
      if (object.date_end) {
        const paramsEdit = {
          iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
          object: {
            id: object.id,
            status: 1,
          },
        }
        socketio.emit("client-edit-row-status-software-orders-list", paramsEdit)
        socketio.on("server-edit-row-status-software-orders-list", (data) => {
          if (data.length > 0) {
            resultDataUpdate(data)
            alert("Xác nhận thành công.")
          }
        })
      } else {
        alert("Bạn cần thiết lập ngày làm việc kết thúc trước khi phê duyệt chức năng này")
      }
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-row-status-software-orders-list")
      socketio.off("server-edit-row-status-software-orders-list")
    }
  }, [editStatus.current])

  // Xử lý xóa chức năng.
  const changeDelete = useRef()
  const handleDeleteFunction = () => {
    const checkDelete = window.confirm("Bạn muốn xóa chức năng này chứ?");
    if (checkDelete) {
      // Kiểm tra trước khi xóa.
      if (object.status === 0) {
        const paramsDelete = {
          iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
          object: {
            id: object.id,
            delete_orders_list: 1
          }
        }
        socketio.emit("client-edit-row-delete-software-orders-list", paramsDelete)
        socketio.on("server-edit-row-delete-software-orders-list", (data) => {
          if (data.length > 0) {
            alert("Bạn đã xóa thành công");
            changeDelete.current = data
            resultDataUpdate(data)
          }
        })
      } else {
        alert("Chức năng đã phê duyệt không thể xóa.")
      }
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-row-delete-software-orders-list")
      socketio.off("server-edit-row-delete-software-orders-list")
    }
  }, [changeDelete.current])

  return (
    <>
      <ul className="dropdown-menu-tippy">
        {/* Role add */}
        {dataUser[0].role_token === process.env.REACT_APP_ROLE_ADMIN && (
          <li className="dropdown-item" onClick={handleAddContent}>
            Thêm nội dung
          </li>
        )}
        {object.status === 0 &&
          dataUser[0].role_token !== process.env.REACT_APP_ROLE_ADMIN && (
            <li className="dropdown-item" onClick={handleAddContent}>
              Thêm nội dung
            </li>
          )}
        {/* Role edit */}
        {dataUser[0].role_token === process.env.REACT_APP_ROLE_ADMIN && (
          <li className="dropdown-item" onClick={handleEditFunction}>
            Sửa chức năng
          </li>
        )}
        {object.status === 0 &&
          dataUser[0].role_token !== process.env.REACT_APP_ROLE_ADMIN && (
            <li className="dropdown-item" onClick={handleEditFunction}>
              Sửa chức năng
            </li>
          )}

        {/* Thiết lập ngày làm việc */}
        {dataUser[0].role_token === process.env.REACT_APP_ROLE_ADMIN && (
          <li className="dropdown-item" onClick={handleEditWorkday}>
            Thiết lập ngày làm việc
          </li>
        )}

        {/* Xác nhận trạng thái */}
        {dataUser[0].role_token === process.env.REACT_APP_ROLE_ADMIN && (
          <>
            <li className="dropdown-item" onClick={handleEditStatusFunction}>
              Xác nhận hoàn thành
            </li>
            <li className="dropdown-item" onClick={handleDeleteFunction}>
              Xóa chức năng
            </li>
          </>
        )}
      </ul>
      {/* Modal add edit */}
      {modalAddContent && (
        <ModalReact
          modalTitle="Thêm nội dung cho chức năng"
          showModal={modalAddContent}
          handleClose={handleCloseAddContent}
          theme={
            <FormAddEditFunction
              {...props}
              resultDataUpdate={handleAddContentSuccess}
            />
          }
        />
      )}
      {/* Modal edit function */}
      {modalEditFunction && (
        <ModalReact
          modalTitle="Sửa thông tin chức năng"
          showModal={modalEditFunction}
          handleClose={handleCloseEditFunction}
          theme={
            <FormEditFunctions
              {...props}
              resultDataUpdate={handleEditFunctionSuccess}
            />
          }
        />
      )}
      {/* Modal edit workday */}
      {modalEditWorkday && (
        <ModalReact
          modalTitle="Thiết lập ngày làm việc"
          showModal={modalEditWorkday}
          handleClose={handleCloseWorkday}
          theme={
            <FormEditWorkdayFunction
              {...props}
              resultDataUpdate={handleEditWorkdaySuccess}
            />
          }
        />
      )}
    </>
  )
}

// Form add edit thông tin chức năng.
function FormAddEditFunction(props) {
  const { socketio, dataUser, object, resultDataUpdate } = props;
  const { register, handleSubmit, formState: { errors } } = useForm()
  // Xử lý submit.
  const checkChangeSuccess = useRef()
  const handleOnSubmit = (object_) => {

    // Xử lý thêm danh sách nội dung của chức năng.
    let arrContent = object_.content.split(",")
    let i = 0
    let max_ = arrContent.length
    function handleCreateListContent() {
      if (i <= max_) {
        if (i < max_) {
          const paramsFunction = {
            iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
            object: {
              orders_list_id: object.id,
              function_name: arrContent[i].trim(),
            },
          }
          socketio.emit(
            "client-create-data-software-order-list-function",
            paramsFunction,
          )
        }
        i++
        setTimeout(handleCreateListContent, 1)
        if (i === max_) {
          alert("Thêm mới thành công.")
          resultDataUpdate([object])
          checkChangeSuccess.current = object_
        }
      }
    }
    handleCreateListContent()
  }
  useEffect(() => {
    return () => {
      socketio.off("client-create-data-software-order-list-function")
    }
  }, [checkChangeSuccess.current])
  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="text-center alert alert-primary fs-18 fw-5">{object.cate}</div>
      <div className="mb-4">
        {/* <label className="fs-15 mb-1 text-muted">Nội dung chức năng</label> */}
        <textarea
          className="form-control-lg col-12 rounded-3"
          placeholder="Nội dung được cách nhau bởi dấu phẩy"
          style={{ height: "100px" }}
          {...register("content", { required: true })}
        ></textarea>
        {errors.content && (
          <span className="fs-14 text-danger">
            *Bạn cần nhập nội dung cho chức năng này.
          </span>
        )}
      </div>
      {/* Button */}
      <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
        <button className="btn btn-primary-light m-1 fw-5" type="submit">
          Thêm mới
        </button>
      </div>
    </form>
  )
}

// Danh sách giải quyết vấn đề khách hàng gặp phải.
function ListProblemFunction(props) {
  const {register, handleSubmit, formState: {errors}, reset} = useForm()
  const { socketio, dataUser, dataProblem } = props;
  const [listProblem, setListProblem] = useState([])
  const listProblemRef = useRef([])
  const totalStatus0 = useRef(0)
  const totalStatus1 = useRef(0)
  const totalProblem = useRef(0)
  const [loading, setLoading] = useState(true)
 
  // Get data problem.
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
      object: {
        list_children_id: dataProblem[1].id,
      },
    }
    socketio.emit("client-get-data-list-children-children", params)
    socketio.on("server-get-data-list-children-children", (data) => {
      setListProblem([...data])
      listProblemRef.current = [...data]
      handleCountProblem(data)
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-data-list-children-children")
      socketio.off("server-get-data-list-children-children")
    }
  }, [])

  // Quản lý tính số lượng vấn đề cần xử lý.
  const handleCountProblem = (data) => {
    if (data.length > 0) {
      // set data chưa khắc phục
      let arrStatus0 = data.filter((item) => item.status === 0)
      totalStatus0.current = arrStatus0.length
      // Set data đã khắc phục.
      let arrStatus1 = data.filter((item) => item.status === 1)
      totalStatus1.current = arrStatus1.length
      // Set tổng vấn đề chưa khắc phục.
      totalProblem.current = totalStatus0.current + totalStatus1.current
    }
  }

  // Quản lý thêm mới vấn đề.
  const [activeAdd, setActiveAdd] = useState(false)
  const handleAddProblem = () => {
    setActiveAdd(true)
  }
  const handleCloseAddProblem = () => {
    setActiveAdd(false)
  }

  // Xử lý submit.
  const changeSuccess = useRef([])
  const handleOnSubmit = (object) => {
    object.list_children_id = dataProblem[1].id
    object.user_id = dataUser[0].id
    const params = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
      object: object
    }
    socketio.emit("client-create-data-list-children-children", params)
    socketio.on("server-create-data-list-children-children", (data) => {
      if (data.length > 0) {
        const dataUpdate = listProblemRef.current
        dataUpdate.push(data[0])
        setListProblem([...dataUpdate])
        listProblem.current = [...dataUpdate]
        changeSuccess.current = data
        reset()
        handleShowAlertWarning({
          title: "Thành công",
          html: "<div class='fs-16'>Vấn đề đã được tạo thành công.</div>",
          icon: "success"
        });
        // ẩn form thêm vấn đề.
        setActiveAdd(false)
      }
    })
  }

  // Cập nhật lại danh sách khi submit thành công.
  useEffect(() => {

    return () => {
      socketio.off("client-create-data-list-children-children")
      socketio.off("server-create-data-list-children-children")
    }
  }, [changeSuccess.current]);

  // Xác nhận đã xử lý vấn đề thành công.
  const [checkStatus, setCheckStatus] = useState();
  const handleCheckStatus = (id, status_) => {
    if (status_ === 0) {
      const paramsCheckStatus = {
        iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
        object: {
          id: id,
          status: 1
        }
      }
      socketio.emit("client-edit-row-status-list-children-children", paramsCheckStatus)
      socketio.on("server-edit-row-status-list-children-children", (data) => {
        if (data.length > 0) {
          // Update data.
          let dataUpdate = listProblemRef.current;
          let index = dataUpdate.findIndex((item) => item.id === data[0].id);
          dataUpdate[index] = data[0];
          setListProblem([...dataUpdate])
          listProblemRef.current = [...dataUpdate];
          handleCountProblem(dataUpdate)
          setCheckStatus(id)
        }
      });
    } else {
      handleShowAlertWarning({
        title: "Cảnh báo",
        html: "<div class='fs-16'>Vấn đề đã xử lý thành công bạn sẽ không thể hủy.</div>",
        icon: "warning"
      })
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-row-status-list-children-children")
      socketio.off("server-edit-row-status-list-children-children")
    }
  }, [checkStatus])

  // Quản lý edit vấn đề (chỉ sửa được khi chưa duyệt).
  const [activeEdit, setActiveEdit] = useState("")
  const [valueContent, setValueContent] = useState("")
  const checkEditSuccess = useRef()
  const handleEditProblem = (id, textContent) => {
    setActiveEdit(id)
    setValueContent(textContent)
  }
  const handleEditProblemContent = (e) => {
    setValueContent(e.target.value)
  }
  const handleEditUpdateDataProblem = (status_) => {
    if (status_ === "cancel") {
      setActiveEdit("")
      setValueContent("")
    } else if (status_ === "agree") {
      const paramsEditAgree = {
        iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
        object: {
          id: activeEdit,
          content: valueContent
        }
      }
      socketio.emit("client-edit-row-content-list-children-children", paramsEditAgree)
      socketio.on("server-edit-row-content-list-children-children", (data) => {
        if (data.length > 0) {
          // Thự hiện update data.
          let dataUpdateEdit = listProblemRef.current;
          let index = dataUpdateEdit.findIndex((item) => item.id === data[0].id);
          dataUpdateEdit[index] = data[0];
          setListProblem([...dataUpdateEdit])
          listProblemRef.current = [...dataUpdateEdit]
          setActiveEdit("")
          setValueContent("")
          checkEditSuccess.current = data
        }
      })
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-row-content-list-children-children")
      socketio.off("server-edit-row-content-list-children-children")
    }
  }, [checkEditSuccess.current])

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        {/* Danh mục cần giải quyết */}
        <div>
          <span>{dataProblem[0].cate}</span>
          <span>
            <MdOutlineDoubleArrow className="text-muted ms-2 me-2" />
          </span>
          <span>{dataProblem[1].function_name}</span>
        </div>
        {/* Thêm vấn đề */}
        <div>
          <button
            className="border-0 rounded-3 btn btn-secondary-gradient btn-wave py-1"
            onClick={handleAddProblem}
          >
            Thêm vấn đề
          </button>
        </div>
      </div>
      <div className="mb-3 mt-2 border-top border-block-start-dashed"></div>
      {/* Thống kê vấn đề chưa khắc phục và đã khắc phục */}
      {totalProblem.current > 0 && (
        <div>
          <div className="d-flex justify-content-between mb-1">
            <span className="me-2 text-danger fw-5">
              Chờ xử lý: {totalStatus0.current}
            </span>
            <span className="text-success fw-5">
              Thành công: {totalStatus1.current}
            </span>
          </div>
          <div className="progress progress-lg mb-3 rounded-5">
            <div
              className="progress-bar bg-danger fw-5"
              role="progressbar"
              style={{
                width:
                  (totalStatus0.current / totalProblem.current) * 100 + "%",
              }}
              aria-valuenow="52"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              Đang xử lý{" "}
              {((totalStatus0.current / totalProblem.current) * 100).toFixed()}%
            </div>
            <div
              className="progress-bar bg-success fw-5"
              role="progressbar"
              style={{
                width:
                  (totalStatus1.current / totalProblem.current) * 100 + "%",
              }}
              aria-valuenow="12"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              Đã xong{" "}
              {((totalStatus1.current / totalProblem.current) * 100).toFixed()}%
            </div>
          </div>
        </div>
      )}
      {/* Trường thêm vấn đề */}
      <div>
        {activeAdd && (
          <form
            onSubmit={handleSubmit(handleOnSubmit)}
            className="d-flex gap-1 align-items-start"
          >
            <div className="col-11">
              <textarea
                className="form-control-lg col-12 rounded-3"
                placeholder="Nội dung vấn đề cần giải quyết *"
                style={{ height: "100px" }}
                {...register("content", { required: true })}
              ></textarea>
              {errors.content && (
                <span className="fs-14 text-danger">
                  *Bạn cần nhập nội dung vấn đề cho mục{" "}
                  {dataProblem[1].function_name}.
                </span>
              )}
            </div>
            <div className="d-flex justify-content-center flex-direction-column gap-2 col-1">
              <Tippy content="Hủy" placement="right">
                <button
                  type="button"
                  className="border-0 rounded-5 bg-transparent p-0"
                  onClick={handleCloseAddProblem}
                >
                  <MdCancel className="text-muted fs-29" />
                </button>
              </Tippy>
              {/* Button */}
              <Tippy content="Lưu lại" placement="right">
                <button
                  className="border-0 bg-transparent text-muted"
                  type="submit"
                >
                  <PiSimCardFill className="fs-29" />
                </button>
              </Tippy>
            </div>
          </form>
        )}
        {/* Problem list*/}
        {listProblem.length === 0 && (
          <div className="text-center mt-3 fs-16">
            Danh mục này chưa có vấn đề gì.
          </div>
        )}

        {listProblem.length > 0 && (
          <ul className="list-group rounded-4 mt-2">
            {listProblem.map((item, index) => {
              return (
                <li
                  className={`list-group-item fs-16 d-flex justify-content-between align-items-start ${
                    item.status === 1 && "bg-success-transparent"
                  }`}
                  key={index}
                >
                  <div className="d-flex gap-1 col-10">
                    <span className="me-2 fw-5">{index + 1}</span>
                    {activeEdit === item.id ? (
                      <>
                        <input
                          type="text"
                          className="border-0 bg-danger-transparent col-8 rounded-4 px-1"
                          value={valueContent}
                          onChange={handleEditProblemContent}
                        />
                        <button
                          className="border-0 bg-transparent p-0"
                          onClick={() => handleEditUpdateDataProblem("cancel")}
                        >
                          <IoIosCloseCircle className="fs-21 text-muted" />
                        </button>
                        <button
                          className="border-0 bg-transparent p-0"
                          onClick={() => handleEditUpdateDataProblem("agree")}
                        >
                          <RiCheckboxCircleFill className="fs-21 text-success" />
                        </button>
                      </>
                    ) : (
                      <span>{item.content}</span>
                    )}
                  </div>
                  <div className="d-flex gap-2 align-items-center">
                    {item.status === 0 && (
                      <button
                        className="border-0 bg-transparent"
                        onClick={() => handleEditProblem(item.id, item.content)}
                      >
                        <FaPencilAlt className="text-muted fs-15" />
                      </button>
                    )}
                    <Tippy content="Thêm vấn đề cần giải quyết">
                      <input
                        type="checkbox"
                        className="form-check-input rounded-5"
                        style={{ border: "1px solid #ff7830" }}
                        checked={
                          item.status === 1 ? true : checkStatus === item.id
                        }
                        onChange={() => handleCheckStatus(item.id, item.status)}
                      />
                    </Tippy>
                  </div>
                </li>
              )
            })}
          </ul>
        )}
      </div>
    </div>
  )
}