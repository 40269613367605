import { imageLandingPage } from "../../../assets/images";

function ContentInfoPC() {
    return (
      <div className="aboutus-banner">
        <div className="aboutus-banner-content">
          <div className="row">
            <div className="col-xxl-6 col-xl-6">
              <div className="row justify-content-center">
                <div className="col-xxl-9 col-xl-9 col-lg-10 col-md-8 col-sm-10 col-12">
                  <div className="p-3 text-start mb-4">
                    <h6 className="fw-semibold text-fixed-white pb-4">
                      <span className="about-heading-white">Chuyển đổi số</span>
                    </h6>
                    <h3 className="fw-semibold mb-2">
                      SỐ HÓA QUY TRÌNH{" "}
                      <span className="text-white me-2">ĐÃ ĐƯỢC</span>
                      CHUẨN HÓA
                    </h3>
                    <p className="mb-4 fw-normal op-7 fs-18 fw-5">
                      Mục đích của chuyển đổi số là tối ưu hóa hiệu suất làm
                      việc, tự động hóa dữ liệu cho việc báo cáo, theo dõi hoạt
                      động kinh doanh, từ đó đánh giá chính xác thực tại doanh
                      nghiệp.
                    </p>
                    <button className="btn btn-success btn-wave fw-5">
                      ĐĂNG KÝ
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 aboutus-banner-img">
              <img
                src={imageLandingPage.media_81}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-xl-12 about-company-stats">
              <div className="row justify-content-center">
                <div className="col-xxl-6 col-xl-8 col-lg-10 col-md-8 col-sm-10 col-10">
                  <div className="card custom-card text-default shadow border rounded-4">
                    <div className="card-body p-0">
                      <div className="row">
                        <div className="col-xl-4 col-xxl-4 col-lg-4 col-md-4 col-sm-4 about-company-stats-border">
                          <div className="text-center p-4 w-100 h-100 d-flex align-items-center justify-content-center">
                            <div>
                              <span className="fw-semibold">
                                Dự án đã số hóa
                              </span>
                              <p className="text-dark fs-2 mb-0">
                                <span className="count-up" data-count="136">
                                  12
                                </span>
                                +
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-xxl-4 col-lg-4 col-md-4 col-sm-4 about-company-stats-border">
                          <div className="text-center p-4 w-100 h-100 d-flex align-items-center justify-content-center">
                            <div>
                              <span className="fw-semibold">
                                Quy chuẩn số hóa
                              </span>
                              <p className="text-dark fs-2 mb-0">
                                <span className="count-up" data-count="2499">
                                  2500
                                </span>
                                +
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-xxl-4 col-lg-4 col-md-4 col-sm-4">
                          <div className="text-center p-4 w-100 h-100 d-flex align-items-center justify-content-center">
                            <div>
                              <span className="fw-semibold">
                                Hiệu suất làm việc tăng
                              </span>
                              <p className="text-dark fs-2 mb-0">
                                <span className="count-up" data-count="21">
                                  120%
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}
export default ContentInfoPC