import { useContext } from "react"
import CheckPCMobile from "../../handle-reuses/reuses/check.pc.mobile"
import PersonalPageMobile from "./Theme/Mobile"
import PersonalPagePC from "./Theme/PC"
import AppContext from "../../handle-reuses/Contexts/CreateContexts"

function PersonalPage() {
  const { socketio, dataUser } = useContext(AppContext);
    return (
      <CheckPCMobile>
        {(isMobile) =>
          isMobile ? (
            <PersonalPageMobile socketio={socketio} dataUser={dataUser} />
          ) : (
            <PersonalPagePC socketio={socketio} dataUser={dataUser} />
          )
        }
      </CheckPCMobile>
    )
}
export default PersonalPage