import LoginPage from "../Modules/Login"
import HomePage from "../Modules/Home"
import RegisterPage from "../Modules/Register"
import PersonalPage from "../Modules/PersonalPage"



// Đường dẫn công khai.
const routerPublic = [
  { path: "/register", component: RegisterPage },
  { path: "/login", component: LoginPage },
  { path: "/", component: PersonalPage },
  { path: "/shops", component: PersonalPage },
  { path: "/pricing", component: PersonalPage },
  
]

// Đường dẫn cần tài khoản.
const routerRole = [
  { path: "/setting", component: PersonalPage },
  { path: "/personal", component: PersonalPage },
  { path: "/thue-tncn", component: PersonalPage },

  { path: "/software-order", component: PersonalPage },
  { path: "/software-order/payment-amount", component: PersonalPage },
  { path: "/software-order/purchased-list", component: PersonalPage },
]

// Đường dẫn cho quản trị viên.
const routerRoleAdmin = [
  { path: "/role-admin", component: PersonalPage },
  { path: "/role-admin/cate-user", component: PersonalPage },
  { path: "/role-admin/cate-shops", component: PersonalPage },
  { path: "/role-admin/cate-payment", component: PersonalPage },
  { path: "/role-admin/cate-chatbot", component: PersonalPage },
  { path: "/role-admin/cate-software-designer", component: PersonalPage },
  { path: "/role-admin/cate-statistical", component: PersonalPage },
]


export {routerPublic, routerRole, routerRoleAdmin}
