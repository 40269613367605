import { IoAlertCircleOutline, IoChatbubbleEllipsesOutline, IoCogOutline, IoEyeOffOutline, IoEyeOutline, IoNotificationsOutline, IoShieldCheckmarkOutline } from "react-icons/io5"
import Tippy from "@tippyjs/react/headless"
import Tippyy from "@tippyjs/react"
import { Link, useNavigate } from "react-router-dom"
import { HiOutlineShoppingBag, HiOutlineTicket } from "react-icons/hi2"
import { TbView360Number } from "react-icons/tb"
import { AiOutlineLogout } from "react-icons/ai"
import { PiShoppingBagOpenDuotone } from "react-icons/pi"
import { images } from "../../../../assets/images"
import { useEffect, useRef, useState } from "react"
import ModalReact from "../../../../Component/Modal/ModalReact"
import ChatBot from "../../../ChatBotBrain/ChatBot"
import { IoIosArrowBack } from "react-icons/io"
import PaymentList from "../Payment/PaymentList"
import { GoLog } from "react-icons/go"
import { BsCartCheck } from "react-icons/bs"
import { VscBell } from "react-icons/vsc"
import { SlLock } from "react-icons/sl"
import { useForm } from "react-hook-form"
import { handleShowAlertWarning } from "../../../../Component/Alert_sweetalert/AlertWarning"


function HeaderDashboardPC(props) {
  const navigate = useNavigate()
  const { socketio, dataUser, dataMenu, path } = props.objectDataRole

  const handleGoBack = () => {
    navigate(-1)
  }
  // Modal đã mua.
  const [modalPayment, setModalPayment] = useState(false)
  const handleActiveCatePayment = () => {
    setModalPayment(true)
  }
  const handleCloseActiveCatePayment = () => {
    setModalPayment(false)
  }
  // Chat bot hỗ trợ.
  const [modalChatbot, setModalChatbot] = useState(false)
  const handleModalChatBot = () => {
    setModalChatbot(true)
  }
  const handleCloseModalChatBot = () => {
    setModalChatbot(false)
  }
  return (
    <>
      <div>
        <button className="border-0 bg-transparent p-0 text-muted" onClick={handleGoBack}>
          <IoIosArrowBack /> Quay lại
        </button>
        {/* <h5>
            Welcome!{" "}
            {dataUser.length > 0
              ? dataUser[0].username
              : "Bạn đến với view 360"}
          </h5> */}
      </div>
      <div className="wrapper_header_children">
        {/* <Tippyy content="Đặt hàng theo yêu cầu" placement="left">
          <button
            className="border-0 rounded-5 w-39 op-9"
            onClick={handleActiveCatePayment}
            style={{ background: "rgb(252 214 134)" }}
          >
            <HiOutlineShoppingBag
              className="fs-23"
              style={{ color: "var(--default-text-color)" }}
            />
          </button>
        </Tippyy> */}
        <Tippyy content="Danh sách đã mua" placement="left">
          <button
            className="border-0 rounded-5  w-39 op-9"
            onClick={handleActiveCatePayment}
            style={{ background: "rgb(252 214 134)" }}
          >
            <BsCartCheck
              className="fs-23"
              style={{ color: "var(--default-text-color)" }}
            />
          </button>
        </Tippyy>
        <Tippyy content="Hỗ trợ" placement="right">
          <button
            className="border-0 rounded-5 w-39 op-9"
            onClick={handleModalChatBot}
            style={{ background: "rgb(252 214 134)" }}
          >
            <IoChatbubbleEllipsesOutline
              className="fs-23"
              style={{ color: "var(--default-text-color)" }}
            />
          </button>
        </Tippyy>
        <Tippyy content="Thông báo" placement="right">
          <button
            className="border-0 rounded-5 w-39 op-9"
            style={{ background: "rgb(252 214 134)" }}
          >
            <VscBell
              className="fs-23"
              style={{ color: "var(--default-text-color)" }}
            />
          </button>
        </Tippyy>

        <div className="position-relative">
          <Tippy
            interactive
            placement="bottom-start"
            delay={[0, 0]}
            trigger="click"
            render={() =>
              dataUser.length > 0 ? (
                <ListItemLoginSuccess {...props.objectDataRole} />
              ) : (
                <ListItemRegister />
              )
            }
          >
            <button className="border-0 rounded-5 bg-transparent d-flex align-items-center">
              <span className="avatar avatar-md avatar-rounded">
                <img src={images.user} alt="img" />
              </span>
            </button>
          </Tippy>
        </div>
      </div>

      {/* Modal đã mua */}
      {modalPayment && (
        <ModalReact
          modalTitle={<span className="fw-5">Danh sách đã mua</span>}
          showModal={modalPayment}
          handleClose={handleCloseActiveCatePayment}
          theme={<PaymentList />}
        />
      )}
      {/* Modal chat bot */}
      {modalChatbot && (
        <ModalReact
          modalTitle="Hỗ trợ giải đáp"
          showModal={modalChatbot}
          handleClose={handleCloseModalChatBot}
          theme={<ChatBot />}
        />
      )}
    </>
  )
}

export default HeaderDashboardPC


// Danh sách Menu.
function ListItemLoginSuccess(props) {
  const { dataUser } = props;
  const navigate = useNavigate()

  const handleLinkView360 = () => {
    window.open("https://view360.com.vn")
  }
  // Quản lý logout.
  const handleLogout = () => {
    localStorage.removeItem("ob")
    navigate("/login")
  }
  // Quản lý đổi password user.
  const [modalEditPass, setModalEditPass] = useState(false)
  const handleEditPasswordUser = () => {
    setModalEditPass(true)
  }
  const handleCloseEditPasswordUser = () => setModalEditPass(false)
  const handleEditPassSuccess = (status_) => {
    if (status_) {
      setModalEditPass(false)
    }
  }
    return (
      <>
        <ul
          className="dropdown-menu-tippy"
          style={{
            border: "1px solid rgb(240, 233, 230)",
            overflow: "hidden",
            minWidth: "210px",
          }}
        >
          <li className="dropdown-item">
            <Link
              to="/personal"
              className="d-flex align-items-center gap-2 fw-5"
            >
              <span className="avatar avatar-xs avatar-rounded">
                <img
                  src={dataUser[0].avatar ? dataUser[0].avatar : images.user}
                  alt="img"
                />
              </span>
              {dataUser[0].username}
            </Link>
          </li>
          {dataUser.length > 0 &&
            dataUser[0].role_token === process.env.REACT_APP_ROLE_ADMIN && (
              <li className="dropdown-item">
                <Link
                  to="/role-admin"
                  className="d-flex align-items-center gap-2 text-primary"
                >
                  <IoShieldCheckmarkOutline className="fs-18" />
                  Quản trị viên
                </Link>
              </li>
            )}
          <div className="border-top border-block-start-dashed"></div>
          <li className="dropdown-item">
            <Link to="/shops" className="d-flex align-items-center gap-2">
              <PiShoppingBagOpenDuotone className="fs-18" />
              Cửa hàng
            </Link>
          </li>
          <li className="dropdown-item">
            <Link to="/pricing" className="d-flex align-items-center gap-2">
              <HiOutlineTicket className="fs-18" />
              Gói dịch vụ
            </Link>
          </li>
          <li className="dropdown-item">
            <Link to="" className="d-flex align-items-center gap-2">
              <IoAlertCircleOutline className="fs-18" />
              Hỗ trợ
            </Link>
          </li>
          <li className="dropdown-item">
            <button
              className="border-0 bg-transparent d-flex align-items-center gap-2 p-0"
              style={{ color: "#485056" }}
              onClick={handleLinkView360}
            >
              <TbView360Number className="fs-18" />
              View 360
            </button>
          </li>
          {/* <li className="dropdown-item">
          <button
            className="border-0 bg-transparent d-flex align-items-center gap-2 p-0"
            style={{ color: "#485056" }}
            onClick={handleLinkView360}
          >
            <IoCogOutline className="fs-18" />
            Cài đặt
          </button>
        </li> */}
          <div className="border-top border-block-start-dashed"></div>
          <li className="dropdown-item">
            <button
              className="border-0 bg-transparent d-flex align-items-center gap-2 p-0"
              style={{ color: "#485056" }}
              onClick={handleEditPasswordUser}
            >
              <SlLock className="fs-18" />
              Đổi mật khẩu
            </button>
          </li>
          <li className="dropdown-item">
            <button
              className="border-0 bg-transparent d-flex align-items-center gap-2 p-0"
              style={{ color: "#485056" }}
              onClick={handleLogout}
            >
              <AiOutlineLogout className="fs-18" />
              Đăng xuất
            </button>
          </li>
        </ul>
        {/* Modal edit pass */}
        {modalEditPass && (
          <ModalReact
            modalTitle="Thay đổi mật khẩu"
            showModal={modalEditPass}
            handleClose={handleCloseEditPasswordUser}
            theme={<FormEditPass {...props} result={handleEditPassSuccess} />}
          />
        )}
      </>
    )
}

// danh sach chua dang nhap.
function ListItemRegister() {
  return (
    <ul className="dropdown-menu-tippy">
      <li className="dropdown-item">
        <Link to="/login">Đăng nhập</Link>
      </li>
      <li className="dropdown-item">
        <Link to="/register">Đăng ký</Link>
      </li>
    </ul>
  )
}


// Form edit pass.
function FormEditPass(props) {
  const { socketio, dataUser, result } = props;
  const { register, handleSubmit, formState: { errors }, reset } = useForm()
  
  // show hide pass.
  const [showHiden, setShowHiden] = useState("hiden")
  const handleShowHidenPass = (status_) => {
    if (status_ === "show") {
      setShowHiden("show")
    } else {
      setShowHiden("hiden")
    }
  }
  // Xử lý submit.
  const [changeEditSuccess, setChangeEditSuccess] = useState()
  const handleOnSubmit = (object) => {
    // Kiểm tra tài khoản.
    const paramsCheckUser = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
      object: {
        email: object.email,
        phone: dataUser[0].phone
      }
    }; 
    socketio.emit("client-check-data-edit-passwords-tech-user", paramsCheckUser)
    socketio.on("server-check-data-edit-passwords-tech-user", (data) => {
      if (data.length > 0) {
        // Xử lý đổi mật khẩu.
        const paramsEditpass = {
          iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
          object: {
            id: dataUser[0].id,
            passwords: object.passwords
          }
        }
        socketio.emit("client-edit-row-passwords-tech-user", paramsEditpass)
        socketio.on("server-edit-row-passwords-tech-user", (data) => {
          if (data === "success") {
            setChangeEditSuccess(data)
            result(true)
            handleShowAlertWarning({
              title: "Thành công",
              html: "<div class='fs-16'>Đổi mật khẩu thành công</div>",
              icon: "success"
            })
          }
        })
      } else {
        handleShowAlertWarning({
          title: "Cảnh báo",
          html: "<div>Tài khoản không tồn tại, bạn không thể đổi mật khẩu</div>",
          icon: "warning"
        })
      }
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-check-data-edit-passwords-tech-user")
      socketio.off("server-check-data-edit-passwords-tech-user")
    }
  }, [changeEditSuccess])
  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-12">
            <input
              type="email"
              className="form-control rounded-5"
              placeholder="Tên input"
              {...register("email", { required: true })}
            />
            <label className="fs-15 ms-2 text-muted">
              Email <span className="text-danger">*</span>
            </label>
            {errors.sodienthoai && (
              <span className="text-danger fs-14">
                Bạn cần nhập email xác nhận
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-12">
            <input
              type={showHiden === "show" ? "text" : "password"}
              className="form-control rounded-5"
              placeholder="Tên input"
              {...register("passwords", { required: true })}
            />
            {showHiden === "show" ? (
              <button
                type="button"
                className="border-0 bg-transparent text-muted"
                style={{ position: "absolute", right: "21px", top: "30%" }}
                onClick={() => handleShowHidenPass("hiden")}
              >
                <IoEyeOutline className="fs-21" />
              </button>
            ) : (
              <button
                type="button"
                className="border-0 bg-transparent text-muted"
                style={{ position: "absolute", right: "21px", top: "30%" }}
                onClick={() => handleShowHidenPass("show")}
              >
                <IoEyeOffOutline className="fs-21" />
              </button>
            )}
            <label className="fs-15 ms-2 text-muted">
              Mật khẩu mới <span className="text-danger">*</span>
            </label>
            {errors.passwords && (
              <span className="text-danger fs-14">
                Bạn cần nhập mật khẩu trước khi lưu
              </span>
            )}
          </div>
        </div>
      </div>
      {/* Button */}
      <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
        <button
          className="btn btn-primary-light m-1 rounded-5 px-4 fw-5 fs-16"
          type="submit"
        >
          Lưu lại
        </button>
      </div>
    </form>
  );
}