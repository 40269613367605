import Tippy from "@tippyjs/react"
import Tippyheandless from "@tippyjs/react/headless";
import { useEffect, useRef, useState } from "react"
import { IoMdAdd } from "react-icons/io"
import ModalReact from "../../../../Component/Modal/ModalReact";
import { useForm } from "react-hook-form";
import FormAddFunctions from "./HandleForm";

function ButtonAddFunction(props) {
    // Quản lý thêm mới chức năng.
    const [modalAddFunction, setModalAddFunction] = useState(false);
    const handleShowAddFunction = () => {
        setModalAddFunction(true)
    }
    const handleCloseAddFunction = () => setModalAddFunction(false);

    return (
      <div className="col-12">
        <Tippy content="Thêm mới chức năng" placement="top">
          <div>
            <button
              className="border-0 rounded-4 p-1 px-2 fw-5 bg-orange"
              style={{ width: "44px", height: "44px" }}
              onClick={handleShowAddFunction}
            >
              <IoMdAdd className="fs-28 text-white" />
            </button>
          </div>
        </Tippy>
        {/* Modal add function */}
        {modalAddFunction && (
          <ModalReact
            modalTitle="Thêm mới chức năng hoặc thành phần"
            showModal={modalAddFunction}
            handleClose={handleCloseAddFunction}
            theme={<FormAddFunctions {...props}/>}
          />
        )}
      </div>
    )
}

export default ButtonAddFunction

