import classNames from "classnames/bind"
import styles from "../../Admin.module.scss"
import { images } from "../../../../assets/images";
import { IoIosArrowDropright } from "react-icons/io";
import SearchHorizontal from "../../../../Component/Search/SearchHorizontal";
import { useState } from "react";
import { BsDot } from "react-icons/bs";
import { TbArrowBigDownLines, TbArrowBigUpLines } from "react-icons/tb";
import Tippy from "@tippyjs/react";
const cx = classNames.bind(styles);

function ListUser() {

    // Quan ly search.
    const [objectSearch, setObjectSearch] = useState({
        textSearch: ""
    });
    const handleValueTextSearch = (value) => {
        
    }
    return (
      <>
        {/* Search */}
        <div className="mb-2">
          <SearchHorizontal
            result={handleValueTextSearch}
            className="rounded-5"
          />
        </div>
        {/* Danh sach */}
        <div
          className="rounded-4"
          style={{
            border: "1px solid rgb(244, 237, 233)",
            overflow: "hidden",
          }}
        >
          <div
            className={cx("d-flex justify-content-between")}
            style={{ background: "#f4edea", borderBottom: "1px solid white" }}
          >
            <div className={cx("d-flex align-items-center gap-3 p-1 px-2")}>
              <span className="avatar avatar-rounded">
                <img src={images.user} alt="img" />
              </span>
              <div>
                <div className="fw-5 fs-17">Hoangf hieeus</div>
                <div className="mb-0 text-muted fs-15">
                  <span className="d-flex align-items-center">
                    0934222740
                    <BsDot />
                    21/09/2024
                    <BsDot />
                    <Tippy content="Người dùng sôi nổi">
                      <span>
                        <TbArrowBigUpLines />
                      </span>
                    </Tippy>
                  </span>
                </div>
              </div>
            </div>
            <div className="p-1 px-2 d-flex align-items-center">
              <button className="border-0 bg-transparent text-muted">
                <IoIosArrowDropright className="fs-21" />
              </button>
            </div>
          </div>
          <div
            className={cx("d-flex justify-content-between")}
            style={{ background: "#f4edea" }}
          >
            <div className={cx("d-flex align-items-center gap-3 p-1 px-2")}>
              <span className="avatar avatar-rounded">
                <img src={images.noImage} alt="img" />
              </span>
              <div>
                <div className="fw-5 fs-17">Hoangf anh</div>
                <div className="mb-0 text-muted fs-15">
                  <span className="d-flex align-items-center">
                    0934222740
                    <BsDot />
                    21/09/2024
                    <BsDot />
                    <Tippy content="Người dùng kém hiệu quả">
                      <span>
                        <TbArrowBigDownLines />
                      </span>
                    </Tippy>
                  </span>
                </div>
              </div>
            </div>
            <div className="p-1 px-2 d-flex align-items-center">
              <button className="border-0 bg-transparent text-muted">
                <IoIosArrowDropright className="fs-21" />
              </button>
            </div>
          </div>
        </div>
      </>
    )
}
export default ListUser