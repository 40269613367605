import classNames from "classnames/bind";
import styles from "../../Admin.module.scss"
import { PiShoppingBagOpen, PiStarFill, PiStarHalfFill, PiWarningCircle } from "react-icons/pi";
import { IoCardOutline } from "react-icons/io5";
import DateMonth from "../../../../Component/Date/DateMonth";
import { useState } from "react";
import moment from "moment";
const cx = classNames.bind(styles);




function StatisticalUser() {
    // Quản lý search date.
    const [objectSearch, setObjectSearch] = useState({
        monthSearch: "",
        yearSearch: moment().format("YYYY")
    });
    const handleSearchDate = (object) => {
        console.log(object)
    }

    return (
        <div>
            {/* Search date */}
            <div className="d-flex justify-content-end mb-2">
                <div className="col-3">
                    <DateMonth className="rounded-5" result={handleSearchDate} />
                </div>
            </div>
            {/* Thống kê */}
            <div className="row mb-2">
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div className="card custom-card mb-2">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-9 pe-0">
                                    <p className="mb-2">
                                        <span className="fs-16">Đã mua</span>
                                    </p>
                                    <p className="mb-2 fs-12">
                                        <span className="fs-21 fw-semibold lh-1 vertical-bottom mb-0">
                                            150
                                        </span>
                                        <span className="d-block fs-12 fw-semibold text-muted">
                                            Năm 2024
                                        </span>
                                    </p>
                                    <div className="fs-15 cursor-pointer">Danh sách</div>
                                </div>
                                <div className="col-3">
                                    {/* <p className="badge bg-success-transparent float-end d-inline-flex">
                                        <i className="ti ti-caret-up me-1"></i>42%
                                    </p> */}
                                    <p className="main-card-icon mb-0">
                                        <PiShoppingBagOpen className="fs-25 text-muted" />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div className="card custom-card mb-2">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-9 pe-0">
                                    <p className="mb-2">
                                        <span className="fs-16">Số tiền thanh toán</span>
                                    </p>
                                    <p className="mb-2 fs-12">
                                        <span className="fs-21 fw-semibold lh-1 vertical-bottom mb-0">
                                            5,000,000đ
                                        </span>
                                        <span className="d-block fs-12 fw-semibold text-muted">
                                            Năm 2024
                                        </span>
                                    </p>
                                    <div className="cursor-pointer">Danh sách</div>
                                </div>
                                <div className="col-3">
                                    <p className="badge bg-danger-transparent float-end d-inline-flex">
                                        <i className="ti ti-caret-down me-1"></i>12%
                                    </p>
                                    <p className="main-card-icon mb-0">
                                        <IoCardOutline className="fs-25 text-muted" />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div className="card custom-card mb-2">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-9 pe-0">
                                    <p className="mb-2">
                                        <span className="fs-16">Vấn đề</span>
                                    </p>
                                    <p className="mb-2 fs-12">
                                        <span className="fs-21 fw-semibold lh-1 vertical-bottom mb-0">
                                            3
                                        </span>
                                        <span className="d-block fs-12 fw-semibold text-muted">
                                            Năm 2024
                                        </span>
                                    </p>
                                    <div className="cursor-pointer">Danh sách</div>
                                </div>
                                <div className="col-3">
                                    <p className="badge bg-success-transparent float-end d-inline-flex">
                                        <i className="ti ti-caret-up me-1"></i>27%
                                    </p>
                                    <p className="main-card-icon mb-0">
                                        <PiWarningCircle className="fs-25 text-muted" />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Biểu đồ lưu lượng truy cập theo thời gian*/}
            <ListStatistical />
        </div>
    );
}
export default StatisticalUser


function ListStatistical({ socketio, dataUser }) {
  return (
    <div>
      {/* Đánh giá người dùng */}
      <div className="d-flex justify-content-between align-items-center mb-2">
        <span>
          Đánh giá người dùng ở mức <span className="fw-5">31.2%</span>
        </span>
        <div>
          <PiStarFill className="text-warning" />
          <PiStarFill className="text-warning" />
          <PiStarFill className="text-warning" />
          <PiStarHalfFill className="text-warning" />
        </div>
      </div>
      {/* List statistical */}
      <div
        className="progress progress-xl progress-animate custom-progress-4 rounded-0"
        role="progressbar"
        aria-valuenow="10"
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ borderBottom: "1px solid white" }}
      >
        <div
          className="progress-bar bg-warning rounded-0"
          style={{ width: "12%" }}
        >
          12%
        </div>
        <div className="progress-bar-label">Số tiền đã chi</div>
      </div>
      <div
        className="progress progress-xl progress-animate custom-progress-4 rounded-0"
        role="progressbar"
        aria-valuenow="10"
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ borderBottom: "1px solid white" }}
      >
        <div
          className="progress-bar bg-success rounded-0"
          style={{ width: "52%" }}
        >
          52%
        </div>
        <div className="progress-bar-label">Sức mua</div>
      </div>
      <div
        className="progress progress-xl progress-animate custom-progress-4 rounded-0"
        role="progressbar"
        aria-valuenow="10"
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ borderBottom: "1px solid white" }}
      >
        <div
          className="progress-bar bg-danger rounded-0"
          style={{ width: "22%" }}
        >
          22%
        </div>
        <div className="progress-bar-label">Khả năng duy trì</div>
      </div>
      <div
        className="progress progress-xl progress-animate custom-progress-4 rounded-0"
        role="progressbar"
        aria-valuenow="10"
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ borderBottom: "1px solid white" }}
      >
        <div
          className="progress-bar bg-info rounded-0"
          style={{ width: "10%" }}
        >
          10%
        </div>
        <div className="progress-bar-label">Hỗ trợ</div>
      </div>
      <div
        className="progress progress-xl progress-animate custom-progress-4 rounded-0"
        role="progressbar"
        aria-valuenow="10"
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ borderBottom: "1px solid white" }}
      >
        <div
          className="progress-bar bg-primary rounded-0"
          style={{ width: "60%" }}
        >
          60%
        </div>
        <div className="progress-bar-label">Tỷ lệ truy cập</div>
      </div>
    </div>
  )
}