import { Link, useLocation } from "react-router-dom"
import { BsDatabaseAdd } from "react-icons/bs"
import { GoLog } from "react-icons/go"
import { IoMdAdd } from "react-icons/io"
import { PiShoppingBagOpen } from "react-icons/pi"
import { SlLayers } from "react-icons/sl"


function MenuTop(props) {
    const {result} = props
  const location = useLocation()
  const path = location.pathname
    // Quản lý đặt hàng.
    const handleClickAddModalOrder = (value) => {
        result(value)
    }
  return (
    <div className="d-flex justify-content-end gap-1 rounded-4">
      <Link
        to=""
        className={`fs-16 btn btn-wave bg-transparent border-0 rounded-4 btn-light d-flex flex-direction-column justify-content-center align-items-center ${
          path === ""
            ? "active"
            : "text-muted"
        }`}
        onClick={()=>handleClickAddModalOrder("AddSoftwareOrder")}
      >
        <BsDatabaseAdd className="fs-21" />
        Tạo đơn hàng
      </Link>
      <Link
        to="/software-order"
        className={`fs-16 btn btn-wave bg-transparent border-0 rounded-4 btn-light d-flex flex-direction-column justify-content-center align-items-center ${
          path === "/software-order" ? "active" : "text-muted"
        }`}
      >
        <PiShoppingBagOpen className="fs-21" />
        Đơn hàng
      </Link>
      <Link
        to="/software-order/payment-amount"
        className={`fs-16 btn btn-wave bg-transparent border-0 rounded-4 btn-light d-flex flex-direction-column justify-content-center align-items-center ${
          path === "/software-order/payment-amount" ? "active" : "text-muted"
        }`}
      >
        <GoLog className="fs-21" />
        Thanh toán
      </Link>
      <Link
        to="/software-order/purchased-list"
        className={`fs-16 btn btn-wave bg-transparent border-0 rounded-4 btn-light d-flex flex-direction-column justify-content-center align-items-center ${
          path === "/software-order/purchased-list" ? "active" : "text-muted"
        }`}
      >
        <SlLayers className="fs-21" />
        Đã mua
      </Link>
    </div>
  )
}
export default MenuTop
