import { useLocation } from "react-router-dom"
import { useEffect, useRef, useState } from "react"
import SideMenuFull from "../../Component/SideMenuLeft/SideMenuFull"
import SideMenuPC from "../../Component/SideMenuLeft/SideMenu"
import HeaderDashboardPC from "../../Component/Header/HeaderDashboardPC"
import ShopsPagePC from "../../../Shops/Theme/PC"
import PricingPagePC from "../../../Pricing/Theme/PC"
import LoadingSpinner from "../../../../Component/Loading/LoadingSpinner"
import CheckRoleUser from "../../Component/CheckRole/CheckRoleUser"
import RentEstatePage from "../../../Home"
import ContentPersonal from "../../Component/ContentPersonal"
import AdminPage from "../../../Admin"
import SoftwareOrderPage from "../../../SoftwareOrder"
import SoftwareOrderPagePC from "../../../SoftwareOrder/Theme/PC"

function PersonalPagePC(props) {
  const { socketio, dataUser } = props
  const location = useLocation()
  const path = location.pathname
  const [loading, setLoading] = useState(true)

  // Quản lý thu gọn hoặc mở rộng.
  const [showHide, setShowHide] = useState("compact")
  const handleSideMenu = (status_) => {
    setShowHide(status_)
  }

  // Quan ly data menu side.
  const [dataMenu, setDataMenu] = useState([])
  const handleGetDataMenu = () => {
    const params = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
      object: {
        tech_user_id: dataUser.length > 0 ? dataUser[0].id : 0,
      },
    }
    socketio.emit("client-get-data-tech-personal-page-menu", params)
    socketio.on("server-get-data-tech-personal-page-menu", (data) => {
      setDataMenu([...data])
      setLoading(false)
    })
  }
  useEffect(() => {
    handleGetDataMenu()
    return () => {
      socketio.off("client-get-data-tech-personal-page-menu")
      socketio.off("server-get-data-tech-personal-page-menu")
    }
  }, [])
  // Update data khi đang ở mục shops.
  useEffect(() => {
    if (path === "/shops" && !loading) {
      handleGetDataMenu()
    }
  }, [path])

  // Kiểm tra đã tải xong dữ liệu hay chưa
  if (loading) {
    return <LoadingSpinner />
  }
  // Set những data cần truyền đến các Component
  const objectDataRole = { socketio, dataUser, dataMenu, path }

  return (
    <div className="wrapper_container">
      {/* sideMenu left*/}
      <div className={`wrapper_sidemenu_left ${showHide}`}>
        {showHide === "compact" && (
          <SideMenuFull dataMenu={dataMenu} resultStatus={handleSideMenu} />
        )}
        {showHide === "more" && (
          <SideMenuPC dataMenu={dataMenu} resultStatus={handleSideMenu} />
        )}
      </div>

      {/* wrapper right */}
      <div className={`wrapper_content_right ${showHide}`}>
        <div className="content_right">
          <div className="content_layout_right">
            {/* Header */}
            <div className="wrapper_header">
              <HeaderDashboardPC objectDataRole={objectDataRole} />
            </div>
            {/* Content */}
            <div className="wrapper_content">
              {/* Trang danh cho admin */}
              {path === "/role-admin" && (
                <AdminPage socketio={socketio} dataUser={dataUser} />
              )}
              {path === "/role-admin/cate-user" && (
                <AdminPage socketio={socketio} dataUser={dataUser} />
              )}
              {path === "/role-admin/cate-shops" && (
                <AdminPage socketio={socketio} dataUser={dataUser} />
              )}
              {path === "/role-admin/cate-payment" && (
                <AdminPage socketio={socketio} dataUser={dataUser} />
              )}
              {path === "/role-admin/cate-chatbot" && (
                <AdminPage socketio={socketio} dataUser={dataUser} />
              )}
              {path === "/role-admin/cate-software-designer" && (
                <AdminPage socketio={socketio} dataUser={dataUser} />
              )}
              {path === "/role-admin/cate-statistical" && (
                <AdminPage socketio={socketio} dataUser={dataUser} />
              )}
              {/* middleware quyền truy cập tiếp theo cho các trang cần quyền truy cập danh cho sản phẩm */}
              {path === "/thue-tncn" && (
                <CheckRoleUser objectDataRole={objectDataRole} />
              )}
              {/* Các trang công khai */}
              {path === "/" && <RentEstatePage />}
              {path === "/shops" && <ShopsPagePC sizeScreen={showHide} />}
              {path === "/pricing" && <PricingPagePC sizeScreen={showHide} />}

              {/* Các trang công khai cần có tài khoản */}
              {path === "/software-order" && <SoftwareOrderPagePC />}
              {path === "/software-order/payment-amount" && <SoftwareOrderPagePC />}
              {path === "/software-order/purchased-list" && <SoftwareOrderPagePC />}

              {/* Các trang thuộc về cá nhân user */}
              {path === "/personal" && (
                <ContentPersonal socketio={socketio} dataUser={dataUser} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PersonalPagePC
