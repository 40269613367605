import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { IoImages } from "react-icons/io5"
import { MdOutlineCancel } from "react-icons/md"
import Sortable from "sortablejs"
import classNames from "classnames/bind"
import styles from "../Shops.module.scss";
import LoadingSpinner from "../../../Component/Loading/LoadingSpinner"
import { handleAddPathImages, handleUpdateListPathImages, handleUploadFileImage } from "../../../handle-reuses/reuses/UploadFile"
import LoadingUploadFiles from "../../../Component/LoadingUploadFiles"
import moment from "moment"
const cx = classNames.bind(styles)

// Form add product.
function FormAddProduct({ socketio, dataUser, result }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [loading, setLoading] = useState(true)

  //   Quản lý gợi ý danh mục.
  const [dataCate, setDataCate] = useState([])
  useEffect(() => {
    const paramsCate = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
    }
    socketio.emit("client-get-data-tech-shops-cate", paramsCate)
    socketio.on("server-get-data-tech-shops-cate", (data) => {
      setDataCate([...data])
    })
    return () => {
      socketio.off("client-get-data-tech-shops-cate")
      socketio.off("server-get-data-tech-shops-cate")
    }
  }, [])
  // Quản lý gợi ý thể loại.
  const [dataCateChildren, setDataCateChildren] = useState([])
  useEffect(() => {
    const paramsCate = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
    }
    socketio.emit("client-get-data-tech-cate-children", paramsCate)
    socketio.on("server-get-data-tech-cate-children", (data) => {
      setDataCateChildren([...data])
      
    })
    return () => {
      socketio.off("client-get-data-tech-cate-children")
      socketio.off("server-get-data-tech-cate-children")
    }
  }, [])

  // Format price.
  const [formatPrice, setFormatPrice] = useState("")
  const handleFormatPrice = (e) => {
    let price_ = e.target.value.replace(/[.,]/g, "")
    if (Number(price_) >= 1000) {
      let formatPrice_ = Number(price_).toLocaleString().replace(/[.]/g, ",")
      setFormatPrice(formatPrice_)
    } else {
      if (Number(price_)) {
        setFormatPrice(price_)
      } else {
        alert("Bạn cần nhập dạng số")
      }
    }
  }

  // Quản lý chọn memebership.
  const [optionPricing, setOptionPricing] = useState([]);
  useEffect(() => {
    const paramsOption = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
    }
    socketio.emit("client-get-data-tech-pricing", paramsOption)
    socketio.on("server-get-data-tech-pricing", (data) => {
      setOptionPricing([...data])
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-data-tech-pricing")
      socketio.off("server-get-data-tech-pricing")
    }
  }, [])

  // LInk đên thẻ input khi click button.
  const [files, setFiles] = useState([])
  const [objectFile, setObjectFile] = useState([])
  const updateObjectFile = useRef([])
  const linkRef = useRef()
  let sttId = useRef(1)
  const handleAddImages = () => {
    linkRef.current.click()
  }
  // Set checkbox avatar.
  const [checkBoxAvatar, setCheckBoxAvatar] = useState("")
  const handleCheckBoxAvatar = (id) => {
    setCheckBoxAvatar(id)
  }
  // Xử lý hiển thị xem trước hình ảnh
  const handleFileImages = (e) => {
    const file = e.target.files
    if (file.length > 0) {
      let checkLengthFile =
        Number(file.length) + Number(updateObjectFile.current.length)
      if (checkLengthFile > 100) {
        alert(
          "Tối đa chỉ được 6 ảnh, hãy lựa chọn những ảnh bạn cho là đẹp nhất để truyền thông.",
        )
      } else {
        updateObjectFile.current = [...updateObjectFile.current, ...file]
        // Hiển thị giao diện người dùng
        const newFile = Array.from(file).map((file_, index) => {
          // Tạo cho mỗi đường dẫn 1 id
          file_.id = sttId.current++
          // Đường dẫn hiển thị
          let pathShow = URL.createObjectURL(file_)
          // Đối tượng được tạo mới
          let objectPath = { id: file_.id, path: pathShow }
          return objectPath
        })
        setFiles((fileOld) => [...fileOld, ...newFile])
        // Cập nhật lên CSDL

        setObjectFile((old) => [...old, ...file])
      }
    }
  }
  useEffect(() => {
    // Quản lý sắp xếp ảnh
    const locationImage = document.querySelector(".imageContainer")
    if (locationImage) {
      new Sortable(locationImage, {
        animation: 250, // Thời gian chuyển động khi sắp xếp (milliseconds)
        // Các tùy chọn khác...
        onEnd: (evt) => {
          // Xử lý sự kiện khi kết thúc việc sắp xếp
          const items = evt.from.children
          const newImages = []
          for (let i = 0; i < items.length; i++) {
            newImages.push(
              items[i].getAttribute("data-image").replace(/\D/g, ""),
            )
          }
          // Thực hiện cập nhật danh sách sắp xếp hình ảnh
          // console.log('New image order:', newImages);
          // console.log([...file])
          let sortList = []
          for (let i = 0; i < [...updateObjectFile.current].length; i++) {
            sortList.push(updateObjectFile.current[newImages[i]])
          }
          setObjectFile(sortList)
        },
      })
    }
  }, [objectFile])

  // Delete images.
  const handleDeleteImages = (index, id) => {
    const updateImage = [...files]
    URL.revokeObjectURL(updateImage[index])
    updateImage.splice(index, 1)
    setFiles(updateImage)
    // xóa ảnh khỏi form file (cập nhật lại form file).
    const formatArr = [...updateObjectFile.current]
    formatArr.splice(index, 1)
    updateObjectFile.current = formatArr
    setObjectFile(updateObjectFile.current)
  }


  // Quản lý submit.
  const [effectLoading, setEffectLoading] = useState(false)
  const handleOnSubmit = (object) => {
    object.shops_cate_id = Number(object.shops_cate_id)
    object.shops_children_id = Number(object.shops_children_id)
    // set gia khuyen mai.
    object.thoigian_khuyenmai = object.thoigian_khuyenmai ? object.thoigian_khuyenmai : null
    object.giaban = object.giaban.replace(/[.,]/g, "")
    object.author = dataUser[0].id
    delete object.listImages
    
    let mienphi = object.membership1 ? 1 +"," : ""
    let hangtieuchuan = object.membership2 ? 2+"," : ""
    let hangbac = object.membership3 ? 3+"," : ""
    let hangvang = object.membership4 ? 4 : "";
    let hangbachkim = object.membership5 ? ","+5 : ""
    object.pricing_duockichhoat = mienphi + hangtieuchuan + hangbac + hangvang + hangbachkim
    delete object.membership1
    delete object.membership2
    delete object.membership3
    delete object.membership4
    delete object.membership5
    // Xử lý upload hình ảnh cho sản phẩm
    handleUploadFileImage(objectFile, process.env.REACT_APP_API_UPLOAD_IMAGES)
    // Xử lý thêm mới sản phẩm.
    let params = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
      object: object
    }
    socketio.emit("client-create-data-tech-shops", params)
    socketio.on("server-create-data-tech-shops", (dataCreate) => {
      // Xử lý thêm mới đường dẫn cho sản phẩm
      if (dataCreate.length > 0) {
        // Kiểm tra xem file ảnh có tồn tại hay không.
        if (objectFile) {
          // set index upload images để chọn ảnh đại diện cho sản phẩm.
          if (checkBoxAvatar) {
            let indexFiles = objectFile.findIndex(
              (item) => item.id === checkBoxAvatar,
            )
            objectFile[indexFiles].active_avatar = 1
          }
          // Đối tượng thêm mới đường dẫn
          let paramsPath = {
            shops_id: dataCreate[0].id,
            path: "",
            addtime: "",
          }
          handleAddPathImages(
            objectFile,
            paramsPath,
            "images/shops",
            process.env.REACT_APP_API_UPLOAD_PATH,
          )
          setEffectLoading(true)
        } else {
          // Nếu không có hình ảnh.
          alert("Thêm sản phẩm thành công")
          setEffectLoading(true)
        }
      }
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-create-data-tech-shops")
      socketio.off("server-create-data-tech-shops")
    }
  }, [effectLoading])

  // Tìm icon .
  const handleOpenSearchIcon = () => {
    window.open("https://react-icons.github.io/react-icons/icons/io5/")
  }

  if (loading) {
    return <LoadingSpinner />
  }
  if (dataCate.length === 0) {
    return <div className="text-center mt-3">Bạn cần tạo danh mục trước</div>
  }


  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      {!effectLoading && (
        <div>
          <div className="col-xl-12 col-md-12">
            <div className="row">
              <div className="form-floating mb-3 col-6">
                <select
                  className="form-select"
                  {...register("shops_cate_id", { required: true })}
                >
                  <option value="">Chọn danh mục</option>
                  {dataCate.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.title}
                      </option>
                    )
                  })}
                </select>
                <label className="fs-15 ms-2 text-muted">
                  Danh mục <span className="text-danger">*</span>
                </label>
                {errors.shops_cate_id && (
                  <span className="text-danger fs-14">
                    Bạn cần chọn danh mục lưu trữ.
                  </span>
                )}
              </div>
              <div className="form-floating mb-3 col-6">
                <select
                  className="form-select"
                  {...register("shops_children_id", { required: true })}
                >
                  <option value="">Chọn thể loại</option>
                  {dataCateChildren.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.title}
                      </option>
                    )
                  })}
                </select>
                <label className="fs-15 ms-2 text-muted">
                  Thể loại <span className="text-danger">*</span>
                </label>
                {errors.shops_children_id && (
                  <span className="text-danger fs-14">
                    Bạn cần chọn thể loại lưu trữ.
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-md-12">
            <div className="row">
              <div className="form-floating mb-3 col-12">
                <input
                  type="text"
                  className="form-control rounded-3"
                  placeholder="Tên input"
                  {...register("tenduan", { required: true })}
                />
                <label className="fs-15 ms-2 text-muted">
                  Tên dự án <span className="text-danger">*</span>
                </label>
                {errors.tenduan && (
                  <span className="text-danger fs-14">
                    Bạn cần nhập tên dự án.
                  </span>
                )}
              </div>
              <div className="form-floating mb-3 col-12">
                <input
                  type="text"
                  className="form-control rounded-3"
                  placeholder="Tên input"
                  {...register("tuongthich", { required: true })}
                  defaultValue="Reactjs"
                />
                <label className="fs-15 ms-2 text-muted">
                  Tương thích<span className="text-danger">*</span>
                </label>
                {errors.tuongthich && (
                  <span className="text-danger fs-14">
                    Bạn cần nhập ngôn ngữ lập trình.
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="form-floating mb-4 mt-3">
            <textarea
              className="form-control rounded-3"
              placeholder="Nhập ghi chú"
              style={{ height: "100px" }}
              {...register("cactrang", { required: true })}
            ></textarea>
            <label className="fs-15 text-muted">
              Các trang màn hình <span className="text-danger">*</span>
            </label>
            {errors.cactrang && (
              <span className="text-danger fs-14">
                Bạn cần nhập các trang hoặc màn hình của dự án.
              </span>
            )}
          </div>
          {/* Ghi chú */}
          <div className="form-floating mb-4 mt-3">
            <textarea
              className="form-control rounded-3"
              placeholder="Nhập ghi chú"
              style={{ height: "100px" }}
              {...register("mieuta", { required: true })}
            ></textarea>
            <label className="fs-15 text-muted">
              Miêu tả <span className="text-danger">*</span>
            </label>
            {errors.mieuta && (
              <span className="text-danger fs-14">
                Bạn cần tạo mô tả cho dự án.
              </span>
            )}
          </div>
          {/* Link video */}
          <div className="form-floating mb-4 mt-3">
            <textarea
              className="form-control rounded-3"
              placeholder="Nhập ghi chú"
              style={{ height: "100px" }}
              {...register("link_video")}
            ></textarea>
            <label className="fs-15 text-muted">Link video youtube</label>
          </div>
          {/* Link xem trước */}
          <div className="form-floating mb-4 mt-3">
            <textarea
              className="form-control rounded-3"
              placeholder="Nhập ghi chú"
              style={{ height: "100px" }}
              {...register("link_xemtruoc")}
            ></textarea>
            <label className="fs-15 text-muted">Link xem trước</label>
          </div>
          <div className="col-xl-12 col-md-12">
            <div className="row">
              <div className="form-floating mb-3 col-12">
                <input
                  type="text"
                  className="form-control rounded-3"
                  placeholder="Tên input"
                  {...register("giaban")}
                  value={formatPrice}
                  onChange={handleFormatPrice}
                />
                <label className="fs-15 ms-2 text-muted">
                  Giá bán <span className="text-danger">*</span>
                </label>
              </div>
              <div className="form-floating mb-3 col-12">
                <input
                  type="text"
                  className="form-control rounded-3"
                  placeholder="Tên input"
                  {...register("khuyenmai")}
                  defaultValue={0}
                />
                <label className="fs-15 ms-2 text-muted">Khuyến mãi (%)</label>
              </div>
              <div className="form-floating mb-3 col-12">
                <input
                  type="datetime-local"
                  className="form-control rounded-3"
                  placeholder="Tên input"
                  {...register("thoigian_khuyenmai")}
                />
                <label className="fs-15 ms-2 text-muted">
                  Thời gian khuyến mãi
                </label>
              </div>
            </div>
          </div>
          {/* Hỗ trợ khi mua */}
          <div className="form-floating mb-4 mt-3">
            <textarea
              className="form-control rounded-3"
              placeholder="Nhập ghi chú"
              style={{ height: "100px" }}
              {...register("hotro_khimua")}
            ></textarea>
            <label className="fs-15 text-muted">
              Hỗ trợ khi mua (cách nhau bởi dấu phảy)
            </label>
          </div>
          {/* Mẹo nhỏ */}
          <div className="form-floating mb-3 col-12">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("tips")}
            />
            <label className="fs-15 ext-muted">Mẹo nhỏ</label>
          </div>
          {/* Chọn memebership */}
          <div className="col-12">
            <div className="text-danger fs-15 mb-2">
              Lưu ý: cần chọn những thành viên có thể kích hoạt ứng dụng này
              (nếu không chọn mặc định là <span className="fw-5">HẠNG BẠC</span>
              )
            </div>
            {optionPricing.length > 0 && (
              <div className="row gy-3">
                {optionPricing.map((item, index) => {
                  return (
                    <div className="col-xl-6" key={index}>
                      <div className="form-check shipping-method-container mb-0">
                        <input
                          id={"shipping-method-" + index}
                          name={"shipping-methods-" + index}
                          type="checkbox"
                          className="form-check-input"
                          {...register("membership" + item.id + "")}
                        />
                        <div className="form-check-label">
                          <div className="d-sm-flex align-items-center justify-content-between">
                            <div className="shipping-partner-details me-sm-5 me-0">
                              <label
                                htmlFor={"shipping-method-" + index}
                                className="mb-0 fw-semibold"
                              >
                                {item.title}
                              </label>
                              <p className="text-muted fs-18 mb-0">
                                {Number(item.price)
                                  .toLocaleString()
                                  .replace(/[.]/g, ",")}
                                đ/Tháng
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          {/* Nhập đường dẫn cho phần mềm */}
          <div className="form-floating mb-3 col-12 mt-3">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("path")}
            />
            <label className="fs-15 text-muted">
              Đường dẫn của trang (ví dụ: /shops)
            </label>
            <span className="text-danger fs-15">
              Lưu ý: Nếu không có đường dẫn người dùng không thể sử dụng được vì
              không có nút <span className="fw-5 fs-18">kích hoạt</span>.
            </span>
          </div>
          {/* Thêm icon cho menu */}
          <div className="form-floating mb-3 col-12 mt-3">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("icon_menu")}
            />
            <label className="fs-15 text-muted">
              Thêm icon menu khi có đường dẫn (ví dụ: IoPieChartOutline)
            </label>
            <div className="text-end">
              <button
                className="fs-15 border-0 rounded-1 mt-1"
                type="button"
                onClick={handleOpenSearchIcon}
              >
                Tìm icon cho menu
              </button>
            </div>
          </div>
          {/* Thêm ảnh sản phẩm */}
          <div className="col-12 mb-3 position-relative text-center">
            <input
              type="file"
              style={{
                position: "absolute",
                transform: "translate(-100%, 3px)",
                cursor: "pointer",
                opacity: 0,
              }}
              onChange={handleFileImages}
            />
          </div>
          {/* List hình ảnh */}
          <div>
            <div className={cx("border-1", "p-3 rounded-3")}>
              <div
                className={cx(
                  "d-flex justify-content-between align-items-center",
                )}
              >
                <span>Thêm ảnh vào bài viết</span>
                <div className="position-relative">
                  <input
                    type="file"
                    {...register("listImages")}
                    multiple
                    className={cx("file-input")}
                    onChange={handleFileImages}
                    style={{ display: "none" }}
                    ref={linkRef}
                  />
                  <label
                    className={cx("option-button")}
                    onClick={handleAddImages}
                  >
                    <IoImages />
                  </label>
                </div>
              </div>
              <ul className="row mt-3 imageContainer">
                {files.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className="col-4 listImage p-1 text-center"
                      data-image={"img" + index}
                    >
                      <span className="d-flex gap-1 justify-content-center">
                        <input
                          type="checkbox"
                          id={"avatar-" + index}
                          name={"avatar-" + index}
                          checked={checkBoxAvatar === item.id ? true : false}
                          onChange={() => handleCheckBoxAvatar(item.id)}
                        />
                        <label htmlFor={"avatar-" + index}>Avatar</label>
                      </span>
                      <img
                        src={item.path}
                        height="100"
                        width="100%"
                        className={cx("rounded-3")}
                        alt=""
                      />
                      <button
                        type="button"
                        className={cx(
                          "deleteButtonImage",
                          "btn btn-icon btn-danger-transparent rounded-pill btn-wave p-1",
                        )}
                        onClick={() => {
                          handleDeleteImages(index)
                        }}
                      >
                        <MdOutlineCancel
                          className="fs-21"
                          style={{ margin: "-.5rem" }}
                        />
                      </button>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          {/* Button */}
          <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
            <button className="btn btn-primary-light m-1 fw-5" type="submit">
              Thêm mới
            </button>
          </div>
        </div>
      )}
      {/* Tiến trình xử lý dữ liệu */}
      <LoadingUploadFiles />
    </form>
  )
}
export default FormAddProduct

// Form edit product
export function FormEditProduct({ socketio, dataUser, dataEdit, result }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [loading, setLoading] = useState(true)

  //   Quản lý gợi ý danh mục.
  const [dataCate, setDataCate] = useState([])
  useEffect(() => {
    const paramsCate = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
    }
    socketio.emit("client-get-data-tech-shops-cate", paramsCate)
    socketio.on("server-get-data-tech-shops-cate", (data) => {
      setDataCate([...data])
    })
    return () => {
      socketio.off("client-get-data-tech-shops-cate")
      socketio.off("server-get-data-tech-shops-cate")
    }
  }, [])
  // Quản lý gợi ý thể loại.
  const [dataCateChildren, setDataCateChildren] = useState([])
  useEffect(() => {
    const paramsCate = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
    }
    socketio.emit("client-get-data-tech-cate-children", paramsCate)
    socketio.on("server-get-data-tech-cate-children", (data) => {
      setDataCateChildren([...data])
    })
    return () => {
      socketio.off("client-get-data-tech-cate-children")
      socketio.off("server-get-data-tech-cate-children")
    }
  }, [])

  // Format price.
  const [formatPrice, setFormatPrice] = useState(Number(dataEdit.item.giaban).toLocaleString().replace(/[.]/g, ","))
  const handleFormatPrice = (e) => {
    let price_ = e.target.value.replace(/[.,]/g, "")
    if (Number(price_) >= 1000) {
      let formatPrice_ = Number(price_).toLocaleString().replace(/[.]/g, ",")
      setFormatPrice(formatPrice_)
    } else {
      if (Number(price_)) {
        setFormatPrice(price_)
      } else {
        alert("Bạn cần nhập dạng số")
      }
    }
  }

  // Quản lý chọn memebership.
  const [optionPricing, setOptionPricing] = useState([])
  useEffect(() => {
    const paramsOption = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
    }
    socketio.emit("client-get-data-tech-pricing", paramsOption)
    socketio.on("server-get-data-tech-pricing", (data) => {
      setOptionPricing([...data])
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-data-tech-pricing")
      socketio.off("server-get-data-tech-pricing")
    }
  }, [])
  // set dataa membership active.
  const [arrActive, setArrActive] = useState([]);
  const arrActiveRef = useRef([])
  useEffect(() => {
    if (dataEdit) {
      let arr = dataEdit.item.pricing_duockichhoat.split(",")
      setArrActive([...arr])
      arrActiveRef.current = [...arr]
    }
  }, [dataEdit])
  const handleActiveMembership = (id) => {
    let filterdArr = arrActiveRef.current.filter((item) => Number(item) === Number(id));
 
    if (filterdArr.length > 0) {
      arrActiveRef.current = arrActiveRef.current.filter((item) => Number(item) !== Number(id))
    } else {
       arrActiveRef.current.push(id)
    }
    setArrActive([...arrActiveRef.current])
  }
  

  // LInk đên thẻ input khi click button.
  const [files, setFiles] = useState(dataEdit.listImages)
  const [objectFile, setObjectFile] = useState([])
  const updateObjectFile = useRef(dataEdit.listImages)
  const linkRef = useRef()
  let sttId = useRef(1)
  const handleAddImages = () => {
    linkRef.current.click()
  }
  // Set checkbox avatar.
  const [checkBoxAvatar, setCheckBoxAvatar] = useState("")
  const handleCheckBoxAvatar = (id) => {
    setCheckBoxAvatar(id)
  }
  // Xử lý hiển thị xem trước hình ảnh
  const handleFileImages = (e) => {
    const file = e.target.files
    if (file.length > 0) {
      let checkLengthFile =
        Number(file.length) + Number(updateObjectFile.current.length)
      if (checkLengthFile > 100) {
        alert(
          "Tối đa chỉ được 100 ảnh, hãy lựa chọn những ảnh bạn cho là đẹp nhất để truyền thông.",
        )
      } else {
        updateObjectFile.current = [...updateObjectFile.current, ...file]
        // Hiển thị giao diện người dùng
        const newFile = Array.from(file).map((file_, index) => {
          // Tạo cho mỗi đường dẫn 1 id
          file_.id = new Date().getTime()+sttId.current++
          // Đường dẫn hiển thị
          let pathShow = URL.createObjectURL(file_)
          // Đối tượng được tạo mới
          let objectPath = { id: file_.id, path: pathShow }
          return objectPath
        })
        setFiles((fileOld) => [...fileOld, ...newFile])
        // Cập nhật lên CSDL

        setObjectFile((old) => [...old, ...file]);
        
      }
    }
  }
  useEffect(() => {
    // Quản lý sắp xếp ảnh
    const locationImage = document.querySelector(".imageContainer")
    if (locationImage) {
      new Sortable(locationImage, {
        animation: 250, // Thời gian chuyển động khi sắp xếp (milliseconds)
        // Các tùy chọn khác...
        onEnd: (evt) => {
          // Xử lý sự kiện khi kết thúc việc sắp xếp
          const items = evt.from.children
          const newImages = []
          for (let i = 0; i < items.length; i++) {
            newImages.push(
              items[i].getAttribute("data-image").replace(/\D/g, ""),
            )
          }
          // Thực hiện cập nhật danh sách sắp xếp hình ảnh
          // console.log('New image order:', newImages);
          // console.log([...file])
          let sortList = []
          for (let i = 0; i < [...updateObjectFile.current].length; i++) {
            sortList.push(updateObjectFile.current[newImages[i]])
          }
          setObjectFile(sortList);
        },
      })
    }
  }, [objectFile, loading])

  // Delete images.
  const handleDeleteImages = (index, id) => {
    const updateImage = [...files]
    URL.revokeObjectURL(updateImage[index])
    updateImage.splice(index, 1)
    setFiles(updateImage)
    // xóa ảnh khỏi form file (cập nhật lại form file).
    const checkDelete = window.confirm("Bạn muốn xóa ?")
    if (checkDelete) {
      const paramsDelete = {
        iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
        object: { id: id },
      }
      socketio.emit("client-delete-data-tech-hops-images", paramsDelete)
      socketio.on("server-delete-data-tech-hops-images", (data) => {
        if (data === "success") {
          // Cập nhật lại giao diện
          const formatArr = [...updateObjectFile.current]
          formatArr.splice(index, 1)
          updateObjectFile.current = formatArr
          setObjectFile(updateObjectFile.current)
        }
      })
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-delete-data-tech-hops-images")
      socketio.off("server-delete-data-tech-hops-images")
    }
  }, [objectFile])

  // Quản lý submit.
  const [effectLoading, setEffectLoading] = useState(false)
  const handleOnSubmit = (object) => {
    // set gia khuyen mai.
    object.giaban = object.giaban.replace(/[.,]/g, "")
    object.id = dataEdit.item.id
    object.thoigian_khuyenmai = object.thoigian_khuyenmai ? object.thoigian_khuyenmai : null
    delete object.listImages

    let mienphi = object.membership1 ? 1 + "," : ""
    let hangtieuchuan = object.membership2 ? 2 + "," : ""
    let hangbac = object.membership3 ? 3 + "," : ""
    let hangvang = object.membership4 ? 4 : ""
    let hangbachkim = object.membership5 ? "," + 5 : ""
    object.pricing_duockichhoat =
      mienphi + hangtieuchuan + hangbac + hangvang + hangbachkim
    delete object.membership1
    delete object.membership2
    delete object.membership3
    delete object.membership4
    delete object.membership5

    // Xử lý edit data.
    const params = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
      object: object
    }
    socketio.emit("client-edit-data-tech-shops", params)
    socketio.on("server-edit-data-tech-shops", (data) => {
      if (data.length > 0) {
        // Lọc lại mảng objectFile (loại bỏ giá trị cũ)
        let filterObjectFile = objectFile.filter((item) => item.shops_id === undefined)
        if (checkBoxAvatar) {
          // set index upload images để chọn ảnh đại diện cho sản phẩm khi thêm file image.
          let indexFiles = objectFile.findIndex(
            (item) => item.id === checkBoxAvatar,
          )
          if (indexFiles !== -1) {
            objectFile[indexFiles].active_avatar = 1
          }
        }
        // Xử lý edit path.
        handleUpdateListPathImages(
          filterObjectFile,
          "listImage",
          process.env.REACT_APP_API_EDIT_PATH_TECH_SHOPS_IMAGES,
          process.env.REACT_APP_API_UPLOAD_IMAGES,
          process.env.REACT_APP_API_UPLOAD_PATH,
          "/images/shops",
          { shops_id: data[0].id },
          result
        )
        setEffectLoading(true)
      }
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-data-tech-shops")
      socketio.off("server-edit-data-tech-shops")
    }
  }, [effectLoading])

  // Tìm kiếm icon cho menu.
  const handleOpenSearchIcon = () => {
    window.open("https://react-icons.github.io/react-icons/icons/io5/")
  }

  if (loading) {
    return <LoadingSpinner />
  }
  if (dataCate.length === 0) {
    return <div className="text-center mt-3">Bạn cần tạo danh mục trước</div>
  }

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      {!effectLoading && (
        <div>
          <div className="col-xl-12 col-md-12">
            <div className="row">
              <div className="form-floating mb-3 col-6">
                <select
                  className="form-select"
                  {...register("shops_cate_id", { required: true })}
                  defaultValue={dataEdit.item.shops_cate_id}
                >
                  <option value="">Chọn danh mục</option>
                  {dataCate.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.title}
                      </option>
                    )
                  })}
                </select>
                <label className="fs-15 ms-2 text-muted">
                  Danh mục <span className="text-danger">*</span>
                </label>
                {errors.shops_cate_id && (
                  <span className="text-danger fs-14">
                    Bạn cần chọn danh mục lưu trữ.
                  </span>
                )}
              </div>
              <div className="form-floating mb-3 col-6">
                <select
                  className="form-select"
                  {...register("shops_children_id", { required: true })}
                  defaultValue={dataEdit.item.shops_children_id}
                >
                  <option value="">Chọn thể loại</option>
                  {dataCateChildren.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.title}
                      </option>
                    )
                  })}
                </select>
                <label className="fs-15 ms-2 text-muted">
                  Thể loại <span className="text-danger">*</span>
                </label>
                {errors.shops_children_id && (
                  <span className="text-danger fs-14">
                    Bạn cần chọn thể loại lưu trữ.
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-md-12">
            <div className="row">
              <div className="form-floating mb-3 col-12">
                <input
                  type="text"
                  className="form-control rounded-3"
                  placeholder="Tên input"
                  {...register("tenduan", { required: true })}
                  defaultValue={dataEdit.item.tenduan}
                />
                <label className="fs-15 ms-2 text-muted">
                  Tên dự án <span className="text-danger">*</span>
                </label>
                {errors.tenduan && (
                  <span className="text-danger fs-14">
                    Bạn cần nhập tên dự án.
                  </span>
                )}
              </div>
              <div className="form-floating mb-3 col-12">
                <input
                  type="text"
                  className="form-control rounded-3"
                  placeholder="Tên input"
                  {...register("tuongthich", { required: true })}
                  defaultValue={dataEdit.item.tuongthich}
                />
                <label className="fs-15 ms-2 text-muted">
                  Tương thích<span className="text-danger">*</span>
                </label>
                {errors.tuongthich && (
                  <span className="text-danger fs-14">
                    Bạn cần nhập ngôn ngữ lập trình.
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="form-floating mb-4 mt-3">
            <textarea
              className="form-control rounded-3"
              placeholder="Nhập ghi chú"
              style={{ height: "100px" }}
              {...register("cactrang", { required: true })}
              defaultValue={dataEdit.item.cactrang}
            ></textarea>
            <label className="fs-15 text-muted">
              Các trang màn hình <span className="text-danger">*</span>
            </label>
            {errors.cactrang && (
              <span className="text-danger fs-14">
                Bạn cần nhập các trang hoặc màn hình của dự án.
              </span>
            )}
          </div>
          {/* Ghi chú */}
          <div className="form-floating mb-4 mt-3">
            <textarea
              className="form-control rounded-3"
              placeholder="Nhập ghi chú"
              style={{ height: "100px" }}
              {...register("mieuta", { required: true })}
              defaultValue={dataEdit.item.mieuta}
            ></textarea>
            <label className="fs-15 text-muted">
              Miêu tả <span className="text-danger">*</span>
            </label>
            {errors.mieuta && (
              <span className="text-danger fs-14">
                Bạn cần tạo mô tả cho dự án.
              </span>
            )}
          </div>
          {/* Link video */}
          <div className="form-floating mb-4 mt-3">
            <textarea
              className="form-control rounded-3"
              placeholder="Nhập ghi chú"
              style={{ height: "100px" }}
              {...register("link_video")}
              defaultValue={dataEdit.item.link_video}
            ></textarea>
            <label className="fs-15 text-muted">Link video youtube</label>
          </div>
          {/* Link xem trước */}
          <div className="form-floating mb-4 mt-3">
            <textarea
              className="form-control rounded-3"
              placeholder="Nhập ghi chú"
              style={{ height: "100px" }}
              {...register("link_xemtruoc")}
              defaultValue={dataEdit.item.link_xemtruoc}
            ></textarea>
            <label className="fs-15 text-muted">Link xem trước</label>
          </div>
          <div className="col-xl-12 col-md-12">
            <div className="row">
              <div className="form-floating mb-3 col-12">
                <input
                  type="text"
                  className="form-control rounded-3"
                  placeholder="Tên input"
                  {...register("giaban")}
                  value={formatPrice}
                  onChange={handleFormatPrice}
                />
                <label className="fs-15 ms-2 text-muted">
                  Giá bán <span className="text-danger">*</span>
                </label>
              </div>
              <div className="form-floating mb-3 col-12">
                <input
                  type="text"
                  className="form-control rounded-3"
                  placeholder="Tên input"
                  {...register("khuyenmai")}
                  defaultValue={dataEdit.item.khuyenmai}
                />
                <label className="fs-15 ms-2 text-muted">Khuyến mãi (%)</label>
              </div>
              <div className="form-floating mb-3 col-12">
                <input
                  type="datetime-local"
                  className="form-control rounded-3"
                  placeholder="Tên input"
                  {...register("thoigian_khuyenmai")}
                  defaultValue={moment(dataEdit.item.thoigian_khuyenmai).format(
                    "YYYY-MM-DD HH:mm",
                  )}
                />
                <label className="fs-15 ms-2 text-muted">
                  Thời gian khuyến mãi
                </label>
              </div>
            </div>
          </div>
          {/* Hỗ trợ khi mua */}
          <div className="form-floating mb-4 mt-3">
            <textarea
              className="form-control rounded-3"
              placeholder="Nhập ghi chú"
              style={{ height: "100px" }}
              {...register("hotro_khimua")}
              defaultValue={dataEdit.item.hotro_khimua}
            ></textarea>
            <label className="fs-15 text-muted">
              Hỗ trợ khi mua (cách nhau bởi dấu phảy)
            </label>
          </div>
          {/* Mẹo nhỏ */}
          <div className="form-floating mb-3 col-12">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("tips")}
              defaultValue={dataEdit.item.tips}
            />
            <label className="fs-15 ext-muted">Mẹo nhỏ</label>
          </div>
          {/* Chọn memebership */}
          <div className="col-12">
            <div className="text-danger fs-15 mb-2">
              Lưu ý: cần chọn những thành viên có thể kích hoạt ứng dụng này
              (nếu không chọn mặc định là <span className="fw-5">HẠNG BẠC</span>
              )
            </div>
            {optionPricing.length > 0 && (
              <div className="row gy-3">
                {optionPricing.map((item, index) => {
                  let activeMembership = arrActive.filter(
                    (item_) => Number(item_) === item.id,
                  )

                  return (
                    <div className="col-xl-6" key={index}>
                      <div className="form-check shipping-method-container mb-0">
                        <input
                          id={"shipping-method-" + index}
                          name={"shipping-methods-" + index}
                          type="checkbox"
                          className="form-check-input"
                          {...register("membership" + item.id + "")}
                          checked={activeMembership.length > 0 ? true : false}
                          onChange={() => handleActiveMembership(item.id)}
                        />
                        <div className="form-check-label">
                          <div className="d-sm-flex align-items-center justify-content-between">
                            <div className="shipping-partner-details me-sm-5 me-0">
                              <label
                                htmlFor={"shipping-method-" + index}
                                className="mb-0 fw-semibold"
                              >
                                {item.title}
                              </label>
                              <p className="text-muted fs-18 mb-0">
                                {Number(item.price)
                                  .toLocaleString()
                                  .replace(/[.]/g, ",")}
                                đ/Tháng
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          {/* Nhập đường dẫn cho phần mềm */}
          <div className="form-floating mb-3 col-12 mt-3">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("path")}
              defaultValue={dataEdit.item.path}
            />
            <label className="fs-15 text-muted">
              Đường dẫn của trang (ví dụ: /shops)
            </label>
            <span className="text-danger fs-15">
              Lưu ý: Nếu không có đường dẫn người dùng không thể sử dụng được vì
              không có nút <span className="fw-5 fs-18">kích hoạt</span>.
            </span>
          </div>
          {/* Thêm icon cho menu */}
          <div className="form-floating mb-3 col-12 mt-3">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("icon_menu")}
              defaultValue={dataEdit.item.icon_menu}
            />
            <label className="fs-15 text-muted">
              Thêm icon menu khi có đường dẫn (ví dụ: IoPieChartOutline)
            </label>
            <div className="text-end">
              <button
                className="fs-15 border-0 rounded-1 mt-1"
                type="button"
                onClick={handleOpenSearchIcon}
              >
                Tìm icon cho menu
              </button>
            </div>
          </div>
          {/* Thêm ảnh sản phẩm */}
          <div className="col-12 mb-3 position-relative text-center">
            <input
              type="file"
              style={{
                position: "absolute",
                transform: "translate(-100%, 3px)",
                cursor: "pointer",
                opacity: 0,
              }}
              onChange={handleFileImages}
            />
          </div>
          {/* List hình ảnh */}
          <div>
            <div className={cx("border-1", "p-3 rounded-3")}>
              <div
                className={cx(
                  "d-flex justify-content-between align-items-center",
                )}
              >
                <span>Thêm ảnh vào bài viết</span>
                <div className="position-relative">
                  <input
                    type="file"
                    {...register("listImages")}
                    multiple
                    className={cx("file-input")}
                    onChange={handleFileImages}
                    style={{ display: "none" }}
                    ref={linkRef}
                  />
                  <label
                    className={cx("option-button")}
                    onClick={handleAddImages}
                  >
                    <IoImages />
                  </label>
                </div>
              </div>
              <ul className="row mt-3 imageContainer">
                {files.map((item, index) => {
                  let images_
                  if (item.shops_id) {
                    images_ = "https://tech.view360.vn" + item.path
                  } else {
                    images_ = item.path
                  }
                  return (
                    <li
                      key={index}
                      className={cx(
                        "col-4 listImage p-1 text-center",
                        !checkBoxAvatar
                          ? item.active_avatar && item.active_avatar === 1
                            ? "active_avatar"
                            : ""
                          : checkBoxAvatar === item.id
                          ? "active_avatar"
                          : "",
                      )}
                      data-image={"img" + index}
                      id={"images-pro" + item.id}
                    >
                      <span className="d-flex gap-1 justify-content-center">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={"avatar-" + index}
                          name={"avatar-" + index}
                          checked={
                            !checkBoxAvatar
                              ? item.active_avatar === 1 && true
                              : checkBoxAvatar === item.id
                              ? true
                              : false
                          }
                          onChange={() => handleCheckBoxAvatar(item.id)}
                        />
                        <label htmlFor={"avatar-" + index}>Avatar</label>
                      </span>
                      <img
                        src={images_}
                        height="100"
                        width="100%"
                        className={cx("rounded-3")}
                        alt=""
                      />
                      <button
                        type="button"
                        className={cx(
                          "deleteButtonImage",
                          "btn btn-icon btn-danger-transparent rounded-pill btn-wave p-1",
                        )}
                        onClick={() => {
                          handleDeleteImages(index, item.id)
                        }}
                      >
                        <MdOutlineCancel
                          className="fs-21"
                          style={{ margin: "-.5rem" }}
                        />
                      </button>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          {/* Button */}
          <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
            <button className="btn btn-primary-light m-1 fw-5" type="submit">
              Lưu lại
            </button>
          </div>
        </div>
      )}
      {/* Tiến trình xử lý dữ liệu */}
      <LoadingUploadFiles />
    </form>
  )
}