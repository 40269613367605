
import ContentInfoPC from "../../Component/ContentInfoPC"



function HomePagePC() {
  return (
    <div className="wrapper_module_content">
      <ContentInfoPC />
    </div>
  )
}
export default HomePagePC