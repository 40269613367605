function LoadingUploadFiles() {
    return (
        <div id="progressUploadData" style={{ display: "none" }}>
            <div id="titleProgressUpload" className="text-center"></div>
            <div
                className="progress progress-xl mb-3 progress-animate custom-progress-4"
                role="progressbar"
                aria-valuenow="0"
                aria-valuemin="0"
                aria-valuemax="100"
            >
                <div className="progress-bar" id="progressSuccess"></div>
            </div>
        </div>
    );
}
export default LoadingUploadFiles