import { useContext, useState } from "react";
import MenuTop from "../../Component/MenuTop"
import ModalReact from "../../../../Component/Modal/ModalReact";
import FormSoftwareOrder from "../../Component/FormSoftware";
import { SlBookOpen } from "react-icons/sl";
import AppContext from "../../../../handle-reuses/Contexts/CreateContexts";
import { useLocation } from "react-router-dom";
import ListDonhang from "../../../Admin/Component/Cate-donhang/ListDonhang";
import ListOrderPage from "../../Component/ListOrderPage";
import ListPaymentPage from "../../Component/ListPaymentPage";
import PurchasedListPage from "../../Component/PurchasedListPage";

function SoftwareOrderPagePC() {
    const { socketio, dataUser } = useContext(AppContext)
    const location = useLocation();
    const path = location.pathname
    const [dataOrderNew, setDataOrderNew] = useState([])
    // Quản lý menu top (Cate thêm mới đặt hàng).
    const [modalOrder, setModalOrder] = useState(false)
    const handleResultMenuTop = (value) => {
        if (value === "AddSoftwareOrder") {
            setModalOrder(true)
        }
    }
    const handleCloseOrder = () => setModalOrder(false)
    const handleOrderSuccess = (dataUpdate) => {
        setDataOrderNew(dataUpdate)
    }

    return (
        <div className="wrapper_content_children">
            {/* Top */}
            <div>
                <MenuTop result={handleResultMenuTop} />
            </div>
            <div className="mt-3 border-top border-block-start-dashed"></div>
            {/* main */}
            <div className="heightScroll">
                <div
                    className="mt-3"
                    style={{
                        overflow: "hidden",
                        width: "100%",
                        height: "calc(-11.4rem + 100vh)",
                    }}
                >
                    {/* Trang danh sách đơn đặt hàng */}
                    {path === "/software-order" && (
                        <ListOrderPage socketio={socketio} dataUser={dataUser} dataOrderNew={dataOrderNew} />
                    )}
                    {/* Trang thanh toán */}
                    {path === "/software-order/payment-amount" && (
                        <ListPaymentPage socketio={socketio} dataUser={dataUser} />
                    )}
                    {/* Trang danh sách đã mua */}
                    {path === "/software-order/purchased-list" && (
                        <PurchasedListPage socketio={socketio} dataUser={dataUser} />
                    )}
                </div>
            </div>
            {/* Footer */}
            {/* modal order */}
            <ModalReact
                modalTitle={
                    <div className="d-flex align-items-center gap-2">
                        <div
                            className="w-39 d-flex rounded-5 align-items-center justify-content-center"
                            style={{ background: "#fcd791" }}
                        >
                            <SlBookOpen />
                        </div>
                        <div>
                            Tạo đơn hàng theo yêu cầu
                            <p className="mb-0 text-muted fs-15 mt-1">
                                Bạn hãy lên ý tưởng, việc còn lại để chúng tôi
                            </p>
                        </div>
                    </div>
                }
                showModal={modalOrder}
                handleClose={handleCloseOrder}
                theme={
                    <FormSoftwareOrder
                        socketio={socketio}
                        dataUser={dataUser}
                        resultOrderNew={handleOrderSuccess}
                    />
                }
            />
        </div>
    );
}

export default SoftwareOrderPagePC