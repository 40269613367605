import classNames from "classnames/bind"
import styles from "../../Admin.module.scss"
import { images } from "../../../../assets/images";
import { IoIosArrowDropright } from "react-icons/io";
import SearchHorizontal from "../../../../Component/Search/SearchHorizontal";
import { useEffect, useRef, useState } from "react";
import { BsDot } from "react-icons/bs";
import { TbArrowBigDownLines, TbArrowBigUpLines } from "react-icons/tb";
import Tippy from "@tippyjs/react";
import SearchDonhang from "./SearchDonhang";
import moment from "moment";
import { TextLimit } from "../../../../handle-reuses/reuses";
import LoadingSpinner from "../../../../Component/Loading/LoadingSpinner";
import { RiCheckboxCircleFill } from "react-icons/ri";
const cx = classNames.bind(styles);

function ListDonhang(props) {
  const { socketio, dataUser, dataOrderNew, resultData } = props
  const [dataSoftwareOrders, setDataSoftwareOrders] = useState([])
  const dataSoftwareOrdersRef = useRef([])
  const [activeItem, setActiveItem] = useState("")
  const [loading, setLoading] = useState(true)

    // Quan ly search.
  const [objectSearch, setObjectSearch] = useState({
    monthSearch: "",
    textSearch: ""
  });
    const handleValueTextSearch = (object) => {
      setObjectSearch({
        monthSearch: object.monthSearch,
        textSearch: object.textSearch
      });
    }
  
  // Quản lý get data.
  const handleGetdataSoftwareOrders = (object) => {
    const params = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
      object: {
        role_token: dataUser[0].role_token,
        user_id: dataUser[0].id,
        monthSearch: object.monthSearch,
        textSearch: object.textSearch
      }
    };
    socketio.emit("client-get-search-data-software-orders", params)
    socketio.on("server-get-search-data-software-orders", (data) => {
      setDataSoftwareOrders([...data]);
      dataSoftwareOrdersRef.current = [...data];
      if (data.length > 0) {
        setActiveItem(data[0].id)
        resultData([data[0]])
      }
      setLoading(false)
    })
  }
  useEffect(() => {
    handleGetdataSoftwareOrders(objectSearch)
    return () => {
      socketio.off("client-get-search-data-software-orders")
      socketio.off("server-get-search-data-software-orders")
    }
  }, [objectSearch])

  // Quản lý click item.
  const handleActiveClickItem = (item) => {
    resultData([item])
    setActiveItem(item.id)
  }

  // Cập nhận lại danh sách khi có đơn đặt hàng mới.
  useEffect(() => {
    if (dataOrderNew) {
      if (dataOrderNew.length > 0) {
        let dataUpdateOrder = dataSoftwareOrdersRef.current
        dataUpdateOrder.push(dataOrderNew[0])
        let sortData = dataUpdateOrder.sort((a, b) => {
          let min_ = new Date(a.addtime).getTime()
          let max_ = new Date(b.addtime).getTime()
          return max_ - min_
        })
        setDataSoftwareOrders([...sortData])
        dataSoftwareOrdersRef.current = [...sortData]
      }
    }
  }, [dataOrderNew])

  if (loading) {
    return <LoadingSpinner />
  }
  if (dataSoftwareOrders.length === 0) {
    return <div className="text-center mt-3 fs-16">Bạn chưa có đơn đặt hàng nào.</div>
  }

    return (
      <>
        {/* Search */}
        <div className="mb-2">
          {/* <SearchHorizontal
            result={handleValueTextSearch}
            className="rounded-5"
          /> */}
          <SearchDonhang resultSearch={handleValueTextSearch}/>
        </div>
        {/* Danh sach */}
        <div
          className="rounded-4"
          style={{
            border: "1px solid rgb(244, 237, 233)",
            overflow: "hidden",
          }}
        >
          {dataSoftwareOrders.map((item, index) => {
            return (
              <div
                className={cx(
                  "d-flex justify-content-between cursor-pointer",
                  // activeItem === item.id && "alert-primary",
                  item.status === 1 && "alert-success",
                )}
                style={{
                  background: activeItem === item.id && "#f4edea",
                  // borderBottom: "1px solid white",
                }}
                key={index}
                onClick={() => handleActiveClickItem(item)}
              >
                <div className={cx("d-flex align-items-center gap-3 p-1 px-2")}>
                  <Tippy content="Hoàng hiếu">
                    <span className="avatar avatar-rounded">
                      <img src={images.user} alt="img" />
                    </span>
                  </Tippy>
                  <div>
                    <Tippy content={item.project_name}>
                      <div className="fw-5 fs-17">
                        <TextLimit text={item.project_name} number={21} />
                      </div>
                    </Tippy>
                    <div className="mb-0 text-muted fs-15">
                      <span className="d-flex align-items-center">
                        {item.phone}
                        <BsDot />
                        {moment(item.addtime).format("DD-MM-YYYY")}
                        {item.status === 1 && (
                          <Tippy content="Đã bàn giao">
                            <div>
                              <BsDot />
                              <RiCheckboxCircleFill className="text-info" />
                            </div>
                          </Tippy>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="p-1 px-2 d-flex align-items-center">
                  <button className="border-0 bg-transparent text-muted">
                    <IoIosArrowDropright className="fs-21" />
                  </button>
                </div>
              </div>
            )
          })}
          
        </div>
      </>
    )
}
export default ListDonhang