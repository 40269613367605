import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import LoadingSpinner from "../../../../Component/Loading/LoadingSpinner";


// money workday.
const moneyWorkday = 699000;
/** ========= form thêm mới chức năng. ===========*/ 
function FormAddFunctions(props) {
  const { socketio, dataUser, dataDetail, resultDataUpdate } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // option type (chọn kiểu chức năng)
  const [optionType, setOptionType] = useState("")
  const handleOptionType = (e) => {
    setOptionType(e.target.value)
  }
  // Quản lý thêm mới chức năng.
  const checkChangeSuccess = useRef()
  const handleOnSubmit = (object) => {
    object.software_orders_id = dataDetail[0].id
    object.user_id = dataUser[0].id
      // Get data count function.
      const paramsCount = {
          iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
          object: {software_orders_id: dataDetail[0].id}
      }
      socketio.emit("client-count-data-software-orders-list", paramsCount)
      socketio.on("server-count-data-software-orders-list", (dataCount) => {
        if (dataCount.length > 0) {
            //   Xử lý thêm chức năng.
            object.sort_function = dataCount[0].count +  1
            const params = {
                iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
                object: object,
            }
            socketio.emit("client-create-data-software-orders-list", params)
            socketio.on(
                "server-create-data-software-orders-list",
                (dataUpdate) => {
                    if (dataUpdate.length > 0) {
                      // Xử lý thêm danh sách nội dung của chức năng.
                      let arrContent = object.content.split(",");
                      let i = 0;
                      let max_ = arrContent.length;
                      function handleCreateListContent() {
                        if (i <= max_) {
                          if (i < max_) {
                            const paramsFunction = {
                              iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
                              object: {
                                orders_list_id: dataUpdate[0].id,
                                function_name: arrContent[i].trim(),
                              },
                            }
                            socketio.emit("client-create-data-software-order-list-function", paramsFunction)
                          }
                          i++
                          setTimeout(handleCreateListContent, 1);
                          if (i === max_) {
                            alert("Thêm chức năng thành công.")
                            resultDataUpdate([...dataUpdate])
                            checkChangeSuccess.current = object
                          }
                        }
                      }
                      handleCreateListContent()
                    }
                },
            );
        }
      });
    
  }
    useEffect(() => {
        return () => {
            socketio.off("client-count-data-software-orders-list")
            socketio.off("server-count-data-software-orders-list")

            socketio.off("client-create-data-software-orders-list")
            socketio.off("server-create-data-software-orders-list")
            
            socketio.off("client-create-data-software-order-list-function")
        }
    }, [checkChangeSuccess.current]);
  return (
    <div>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="row">
          <div className="form-floating mb-3 col-12">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("cate", { required: true })}
            />
            <label className="fs-15 ms-2 text-muted">
              Tên chức năng hoặc tên thành phần của phần mềm{" "}
              <span className="text-danger">*</span>
            </label>
            {errors.cate && (
              <span className="fs-14 text-danger">
                *Bạn cần nhập tên của chức năng bạn muốn.
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-12">
            <select
              className="form-select"
              {...register("type", { required: true })}
              value={optionType}
              onChange={handleOptionType}
            >
              <option value="">Chọn hình thức cho chức năng</option>
              <option value="Form">Trường nhập dữ liệu</option>
              <option value="simple-data">Phân tích dữ liệu đơn giản</option>
              <option value="complex-data">Phân tích dữ liệu phức tạp</option>
            </select>
            <label className="fs-15 ms-2 text-muted">
              Hình thức cho chức năng <span className="text-danger">*</span>
            </label>
            {errors.type && (
              <span className="fs-14 text-danger">
                *Bạn cần chọn hình thức cho chức năng.
              </span>
            )}
          </div>
          {/* Set nếu chọn chức năng là xử lý trường dữ liệu */}
          {optionType === "Form" && (
            <div className="form-floating mb-3 col-12">
              <input
                type="number"
                className="form-control rounded-3"
                placeholder="Tên input"
                {...register("form_quantity", { required: true })}
              />
              <label className="fs-15 ms-2 text-muted">
                Số trường dữ liệu <span className="text-danger">*</span>
              </label>
              {errors.form_quantity && (
                <span className="fs-14 text-danger">
                  *Bạn cần chọn số trường dữ liệu.
                </span>
              )}
            </div>
          )}
          {/* Set kiểu xử lý dữ liệu chức năng */}
          <div className="form-floating mb-3 col-12">
            <select
              className="form-select"
              {...register("handle_type", { required: true })}
            >
              <option value="">Chọn kiểu xử lý dữ liệu</option>
              <option value="Text">Chỉ văn bản</option>
              <option value="Images">Chỉ hình ảnh</option>
              <option value="Text-image">Văn bản + hình ảnh</option>
              <option value="Text-images">Văn bản + hình ảnh (số nhiều)</option>
            </select>
            <label className="fs-15 ms-2 text-muted">
              Kiểu xử lý dữ liệu <span className="text-danger">*</span>
            </label>
            {errors.handle_type && (
              <span className="fs-14 text-danger">
                *Bạn cần chọn kiểu xử lý dữ liệu.
              </span>
            )}
          </div>
        </div>

        {/* Content */}
        <div className="mb-4">
          {/* <label className="fs-15 mb-1 text-muted">Nội dung chức năng</label> */}
          <textarea
            className="form-control-lg col-12 rounded-3"
            placeholder="Mỗi nội dung được cách nhau bởi dấu phẩy (Ví dụ: Trang chủ, Trang chi tiết, Trang thanh toán...)"
            style={{ height: "100px" }}
            {...register("content", { required: true })}
          ></textarea>
          {errors.content && (
            <span className="fs-14 text-danger">
              *Bạn cần nhập nội dung cho chức năng này.
            </span>
          )}
        </div>
        {/* Button */}
        <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
          <button className="btn btn-primary-light m-1 fw-5" type="submit">
            Thêm mới
          </button>
        </div>
      </form>
    </div>
  )
}

export default FormAddFunctions

/** ========= *form thêm mới chức năng. ===========*/ 



/** ========== form edit chức năng =========== */
export function FormEditFunctions(props) {
    const { socketio, dataUser, dataDetail, object, resultDataUpdate } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // option type (chọn kiểu chức năng)
  const [optionType, setOptionType] = useState(object.type)
  const handleOptionType = (e) => {
    setOptionType(e.target.value)
  }
  // Quản lý thêm mới chức năng.
  const checkChangeSuccess = useRef()
  const handleOnSubmit = (object_) => {
      object_.id = object.id
      object_.form_quantity = object_.form_quantity ? object_.form_quantity : 0
    const params = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
      object: object_,
    }
    
      socketio.emit("client-edit-data-software-orders-list", params)
      socketio.on("server-edit-data-software-orders-list", (data) => {
          if (data.length > 0) {
              alert("Sửa thông tin chức năng thành công.")
              resultDataUpdate([...data]);
              checkChangeSuccess.current = object
          }
      })

  }
  useEffect(() => {
    return () => {
        socketio.off("client-edit-data-software-orders-list")
        socketio.off("server-edit-data-software-orders-list")
    }
  }, [checkChangeSuccess.current])
  return (
    <div>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="row">
          <div className="form-floating mb-3 col-12">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("cate", { required: true })}
              defaultValue={object.cate}
            />
            <label className="fs-15 ms-2 text-muted">Tên chức năng</label>
            {errors.cate && (
              <span className="fs-14 text-danger">
                *Bạn cần nhập tên của chức năng bạn muốn.
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-12">
            <select
              className="form-select"
              {...register("type", { required: true })}
              value={optionType}
              onChange={handleOptionType}
            >
              <option value="">Chọn kiểu chức năng</option>
              <option value="Form">Xử lý trường nhập dữ liệu</option>
              <option value="Phân tích">Phân tích</option>
              <option value="Phân tích & giao diện">
                Phân tích & giao diện
              </option>
              <option value="Giao diện">Thiết kế giao diện</option>
              <option value="Hình ảnh">Hình ảnh</option>
              <option value="Văn bản">Văn bản</option>
              <option value="Video">Video</option>
            </select>
            <label className="fs-15 ms-2 text-muted">Kiểu chức năng</label>
            {errors.type && (
              <span className="fs-14 text-danger">
                *Bạn cần chọn kiểu chức năng.
              </span>
            )}
          </div>
          {/* Set nếu chọn chức năng là xử lý trường dữ liệu */}
          {optionType === "Form" && (
            <div className="form-floating mb-3 col-12">
              <input
                type="number"
                className="form-control rounded-3"
                placeholder="Tên input"
                {...register("form_quantity", { required: true })}
                defaultValue={object.form_quantity}
              />
              <label className="fs-15 ms-2 text-muted">Số trường dữ liệu</label>
              {errors.form_quantity && (
                <span className="fs-14 text-danger">
                  *Bạn cần chọn số trường dữ liệu.
                </span>
              )}
            </div>
          )}
          {/* Set kiểu xử lý dữ liệu chức năng */}
          <div className="form-floating mb-3 col-12">
            <select
              className="form-select"
              {...register("handle_type", { required: true })}
              defaultValue={object.handle_type}
            >
              <option value="">Chọn kiểu xử lý dữ liệu</option>
              <option value="Text">Văn bản</option>
              <option value="Images">Hình ảnh</option>
              <option value="Video">Video</option>
              <option value="Text-image">Văn bản + hình ảnh</option>
              <option value="Text-video">Văn bản + Video</option>
              <option value="Video-image">Video + hình ảnh</option>
              <option value="Text-images">Văn bản + hình ảnh (số nhiều)</option>
              <option value="Video-images">Video + hình ảnh (số nhiều)</option>
            </select>
            <label className="fs-15 ms-2 text-muted">Kiểu xử lý dữ liệu</label>
            {errors.handle_type && (
              <span className="fs-14 text-danger">
                *Bạn cần chọn kiểu xử lý dữ liệu.
              </span>
            )}
          </div>
        </div>

        {/* Content */}
        {/* <div className="mb-4">
          <label className="fs-15 mb-1 text-muted">Nội dung chức năng</label>
          <textarea
            className="form-control-lg col-12 rounded-3"
            placeholder="Nội dung được cách nhau bởi dấu phẩy"
            style={{ height: "100px" }}
            {...register("content", { required: true })}
            defaultValue={object.content}
          ></textarea>
          {errors.content && (
            <span className="fs-14 text-danger">
              *Bạn cần nhập nội dung cho chức năng này.
            </span>
          )}
        </div> */}
        {/* Button */}
        <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
          <button className="btn btn-primary-light m-1 fw-5" type="submit">
            Lưu lại
          </button>
        </div>
      </form>
    </div>
  )
}
/** ========== *form edit chức năng =========== */


/** ======= form edit workday ======== */
export function FormEditWorkdayFunction(props) {
    const { socketio, dataUser, object, resultDataUpdate } = props
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    // Get data để phân tích dự kiến ngày làm việc, giá dự kiến.
    const [dataStatistical, setDataStatistcal] = useState([]);
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const params = {
            iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
            object: {
                cate: object.cate,
                type: object.type,
                handle_type: object.handle_type
            }
        }
        socketio.emit("client-get-data-avg-software-orders-list", params);
        socketio.on("server-get-data-avg-software-orders-list", (data) => {
            setDataStatistcal([...data]);
            setLoading(false)
        });
        return () => {
            socketio.off("client-get-data-avg-software-orders-list")
            socketio.off("server-get-data-avg-software-orders-list")
        }
    }, [])
    // Xử lý số ngày làm việc 
    const [workdayNumber, setWorkdayNumber] = useState(object.workday);

    // Xử lý thời gian bắt đầu.
    const [dateStart, setdateStart] = useState(object.date_start ? moment(object.date_start).format("YYYY-MM-DD HH:mm") : "");
    const handleDateStart = (e) => {
        setdateStart(moment(e.target.value).format("YYYY-MM-DD HH:mm"))
    }

    // Xử lý thời gian kết thúc.
    const [dateEnd, setDateEnd] = useState(object.date_end ? moment(object.date_end).format("YYYY-MM-DD HH:mm") : "");
    const handleDateEnd = (e) => {
        setDateEnd(moment(e.target.value).format("YYYY-MM-DD HH:mm"))
        let dateEndFormat = moment(e.target.value);
        let dateStartFormat = moment(dateStart);
        let diffDay = Number(dateEndFormat.diff(dateStartFormat, "days"));
        setWorkdayNumber(diffDay)
    }

    // Xử lý submit.
    const checkChangeSuccess = useRef();
    const handleOnSubmit = (object_) => {
        // set ngày làm việc.
        let workdayOfficial = !object_.date_end ? Number(object_.workday) : workdayNumber;
        // set payment amount.
        let paymentAmount = workdayOfficial * moneyWorkday;
        // set object.
        object_.workday = workdayOfficial;
        object_.payment_amount = paymentAmount
        object_.date_end = object_.date_end ? object_.date_end : null
        object_.id = object.id
        const params = {
            iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
            object: object_
        }
        socketio.emit("client-edit-row-workday-payment-amount-software-orders-list", params)
        socketio.on("server-edit-row-workday-payment-amount-software-orders-list", (dataUpdate) => {
            if (dataUpdate.length > 0) {
                alert("Thiết lập thời gian làm việc thành công.");
                resultDataUpdate([...dataUpdate]);
                checkChangeSuccess.current = object_
            }
        })

    }
    useEffect(() => {
        return () => {
            socketio.off("client-edit-row-workday-payment-amount-software-orders-list")
            socketio.off("server-edit-row-workday-payment-amount-software-orders-list")
        }
    }, [checkChangeSuccess.current])

    if (loading) {
        return <LoadingSpinner />
    }
    return (
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="alert alert-primary fs-16 text-center">
          <span className="fw-5">Thiết lập dự kiến hoàn thành</span>
          <div className="mt-2">
            {dataStatistical.length === 0 && (
              <div className="text-danger fs-16">
                Chưa đủ dữ liệu để phân tích
              </div>
            )}
            {dataStatistical.length > 0 && (
              <ul className="list-group rounded-4">
                <li className="list-group-item d-flex justify-content-between">
                  <span>Dự kiến ngày làm việc:</span>
                  <span>{dataStatistical[0].workday_avg} ngày</span>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <span>Giá dự kiến:</span>
                  <span>
                    {Number(dataStatistical[0].payment_amount_avg)
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                    đ
                  </span>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className="text-center mt-3 mb-2 fs-18">
          Tên chức năng <span className="fw-5">{object.cate}</span>
        </div>
        <div className="row">
          <div className="form-floating mb-3 col-12">
            <input
              type="datetime-local"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("date_start", { required: true })}
              value={dateStart}
              onChange={handleDateStart}
            />
            <label className="fs-15 ms-2 text-muted">
              Ngày bắt đầu <span className="text-danger">*</span>
            </label>
            {errors.date_start && (
              <span className="fs-14 text-danger">
                *Bạn cần nhập ngày bắt đầu làm việc.
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-12">
            <input
              type="datetime-local"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("date_end")}
              value={dateEnd}
              onChange={handleDateEnd}
            />
            <label className="fs-15 ms-2 text-muted">Ngày kết thúc</label>
          </div>
          {!dateEnd && (
            <div className="form-floating mb-3 col-12">
              <input
                type="number"
                className="form-control rounded-3"
                placeholder="Tên input"
                {...register("workday", { required: true })}
                defaultValue={workdayNumber}
              />
              <label className="fs-15 ms-2 text-muted">
                Ngày dự kiến hoàn thành
              </label>
              {errors.workday && (
                <span className="fs-14 text-danger">
                  *Bạn cần nhập ngày dự kiến làm việc.
                </span>
              )}
            </div>
          )}
        </div>
        {/* Button */}
        <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
          <button className="btn btn-primary-light m-1 fw-5" type="submit">
            Lưu lại
          </button>
        </div>
      </form>
    )
}
/** ======= *form edit workday ======== */