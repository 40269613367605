import classNames from "classnames/bind";
import styles from "./ChatBot.module.scss"
import { avatarChatBot, images } from "../../assets/images";
import { BsDot, BsSend } from "react-icons/bs";
import SpeechToText from "./Component/SpeechToText";
import { useEffect, useRef, useState } from "react";
import { autoResizeInput } from "../../handle-reuses/reuses";
import { VscRobot } from "react-icons/vsc";
import Tippy from "@tippyjs/react/headless";
import moment from "moment";
const cx = classNames.bind(styles);

function ChatBot() {
  const [valueInput, setValueInput] = useState("")
  const textareaRef = useRef(null)
  const [heightAuto, setHeightAuto] = useState("")
  // Quan ly chuyển giọng nói thành văn bản.
  const handleSpeechToText = (voiceText) => {
    setValueInput(voiceText)
    if (voiceText.length > 35) {
      setHeightAuto(textareaRef.current.scrollHeight + "px")
    }
  }
  // Quản lý nội dung đầu vào bằng văn bản viết.
  const handleGetValueInputText = (e) => {
    setValueInput(e.target.value)
  }
  useEffect(() => {
    if (valueInput.length > 35) {
      setHeightAuto(textareaRef.current.scrollHeight + "px")
    } else {
      setHeightAuto("39px")
    }
  }, [valueInput])

  // Ngăn chặn vào cửa hàng trước khi khai trương
  const dateEnd = moment("2025-06-03")
  const currentTime = moment()
  const diffDay = dateEnd.diff(currentTime, "days")
  if (diffDay > 0) {
    return (
      <div>
        Chúng tôi sẽ cập nhật thông tin vào 2025. Cảm ơn bạn đã ghé qua.
      </div>
    )
  }

  return (
    <div className="main-chat-area border rounded-4">
      <div className="d-flex align-items-center p-2 border-bottom">
        <div className="me-2 lh-1">
          <span className="avatar avatar-lg avatar-rounded chatstatusperson">
            <img
              className="chatimageperson"
              src={avatarChatBot.bee}
              alt="img"
            />
          </span>
        </div>
        <div className="flex-fill">
          <p className="mb-0 fw-semibold fs-14">
            <a href="#" className="chatnameperson responsive-userinfo-open">
              Bee
            </a>
          </p>
          <p className="text-muted mb-0 chatpersonstatus">
            Hỗ trợ 24/7 <BsDot />
            Công nghệ
          </p>
        </div>
        <div className="d-flex">
          <Tippy
            interactive
            delay={[0, 0]}
            placement="bottom-end"
            trigger="click"
            render={() => <ListOptionCate />}
          >
            <button
              aria-label="button"
              type="button"
              className="btn btn-icon btn-outline-light p-1 rounded-5"
            >
              <VscRobot className="fs-21" />
            </button>
          </Tippy>
        </div>
      </div>
      <div className="chat-content" id="main-chat-content">
        <ul className="list-unstyled p-3">
          <li className={cx("chat-item-bot")}>
            <div className={cx("chat-list-inner")}>
              <div className="chat-user-profile">
                <span className="avatar avatar-md avatar-rounded chatstatusperson">
                  <img
                    className="chatimageperson"
                    src={avatarChatBot.bee}
                    alt="img"
                  />
                </span>
              </div>
              <div className="ms-3">
                <span className="chatting-user-info">
                  <span className="chatnameperson fw-5">Bee</span>{" "}
                  {/* <span className="msg-sent-time">11:48PM</span> */}
                </span>
                <div className="main-chat-msg">
                  <div>
                    <p className="mb-0">
                      Chào bạn, tôi có thể giúp gì cho bạn &#128512;
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className={cx("chat-item-user", "mt-5")}>
            <div className={cx("chat-list-inner")}>
              <div className={cx("me-3", "chat-list-inner-user")}>
                {/* <div className="chatting-user-info text-end">
                    <span className="msg-sent-time">
                      <span className="chat-read-mark align-middle d-inline-flex">
                        <i className="ri-check-double-line"></i>
                      </span>
                      11:50PM
                    </span>{" "}
                    Bạn
                  </div> */}
                <div className="main-chat-msg text-end">
                  <div>
                    <p className="mb-0">
                      Làm sao để lấy được người chồng tốt và giàu có ?
                    </p>
                  </div>
                </div>
              </div>
              <div className="chat-user-profile">
                <span className="avatar avatar-md avatar-rounded">
                  <img src={images.user} alt="img" />
                </span>
              </div>
            </div>
          </li>
          <li className={cx("chat-item-bot", "mt-5")}>
            <div className={cx("chat-list-inner")}>
              <div className="chat-user-profile">
                <span className="avatar avatar-md avatar-rounded chatstatusperson">
                  <img
                    className="chatimageperson"
                    src={avatarChatBot.bee}
                    alt="img"
                  />
                </span>
              </div>
              <div className="ms-3">
                <span className="chatting-user-info">
                  <span className="chatnameperson fw-5">Bee</span>{" "}
                  {/* <span className="msg-sent-time">11:48PM</span> */}
                </span>
                <div className="main-chat-msg">
                  <div>
                    <p className="mb-0">
                      Để lấy được người chồng tốt và giàu có bạn cần có các tiêu
                      chí sau:
                    </p>
                    <p className="mb-0">
                      1. Bạn cần có nhan sắc như Rose của nhóm Blackpink
                    </p>
                    <p className="mb-0">
                      2. Bạn cần có vóc dáng cao 1,75m trở lên
                    </p>
                    <p className="mb-0">
                      3. Phong thái của bạn phải quý sờ tộc như nữ hoàng anh
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="chat-footer mt-5 p-3 position-relative">
        <div
          className="d-flex align-items-center justify-content-between rounded-3"
          style={{
            border: "1px solid #e3e3e3",
            height: "100%",
          }}
        >
          <div className="rounded-3 p-1 px-2 col-10 d-flex align-items-center">
            <textarea
              className={cx("rounded-3 col-12 p-1", "break-word")}
              value={valueInput}
              onChange={handleGetValueInputText}
              style={{ height: heightAuto ? heightAuto : "39px" }}
              ref={textareaRef}
              placeholder="Tìm kiếm tài liệu"
            />
          </div>
          <div className="col-2 d-flex align-items-center gap-1">
            <SpeechToText
              width="33px"
              height="33px"
              className="rounded-3"
              sizeIcon={15}
              resultText={handleSpeechToText}
            />
            <button
              className="border-0 rounded-3 text-muted"
              style={{ width: "33px", height: "33px" }}
            >
              <BsSend />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatBot

// Danh sách danh mục.
function ListOptionCate() {
  return (
    <ul
      className="dropdown-menu-tippy"
      style={{
        border: "1px solid rgb(240, 233, 230)",
        overflow: "hidden",
        minWidth: "210px",
      }}
    >
      <li className="dropdown-item fw-5">Chọn danh mục</li>
      <div className="border-top border-block-start-dashed"></div>
      <li className="dropdown-item">Chính sách hợp tác</li>
      <li className="dropdown-item">Khoa học</li>
      <li className="dropdown-item">Công nghệ</li>
    </ul>
  )
}