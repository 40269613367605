import { IoImages } from "react-icons/io5"
import { images } from "../../../assets/images"
import classNames from "classnames/bind"
import styles from "../PersonalPage.module.scss"
const cx = classNames.bind(styles);

function ContentPersonalMobile() {
    return (
      <div className={cx("card-body-mobile")}>
        {/* main */}
        <div
          style={{ height: "100%", background: "#f1c25e", padding: "1rem" }}
          className="d-flex align-items-center justify-content-center flex-direction-column"
        >
          <div className="text-center ">
            <span className="avatar avatar-xl avatar-rounded">
              <img src={images.user} alt="img" />
            </span>
          </div>
          <div className="mt-3 text-center">
            <h4>Hoàng hiếu</h4>
            <p className="mb-0">Số điện thoại: 0934.222.740</p>
            <p className="mb-0">Email: hoanghieu@gmail.com</p>
          </div>
        </div>
        {/* Edit info */}
        <div className="p-5">
          <form>
            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">Họ tên</label>
              <div className="col-sm-9">
                <input type="email" className="form-control" />
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="gioitinh" className="col-sm-3 col-form-label">
                Giới tính
              </label>
              <div className="col-sm-9 d-flex align-items-center gap-3">
                <div className="d-flex align-items-center gap-1">
                  <input
                    id={"gioitinhnam"}
                    type="checkbox"
                    className="form-check-input"
                    style={{ width: "18px", height: "18px" }}
                  />
                  <label
                    htmlFor="gioitinhnam"
                    className="col-sm-2 col-form-label"
                  >
                    Nam
                  </label>
                </div>
                <div className="d-flex align-items-center gap-1">
                  <input
                    id={"gioitinhnu"}
                    type="checkbox"
                    className="form-check-input"
                    style={{ width: "18px", height: "18px" }}
                  />
                  <label
                    htmlFor="gioitinhnu"
                    className="col-sm-2 col-form-label"
                  >
                    Nữ
                  </label>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <label className="col-sm-3 col-form-label">Sinh nhật</label>
              <div className="col-sm-9">
                <input type="date" className="form-control" />
              </div>
            </div>
            <div className="row mb-4">
              <label className="col-sm-3 col-form-label">Mật khẩu mới</label>
              <div className="col-sm-9">
                <input type="text" className="form-control" />
              </div>
            </div>
            {/* ảnh đại diện */}
            <div
              className={cx(
                "d-flex justify-content-between align-items-center",
              )}
            >
              <span>Đổi ảnh đại diện</span>
              <div className="position-relative">
                <input
                  type="file"
                  className={cx("file-input")}
                  style={{ display: "none" }}
                />
                <label className={cx("option-button")}>
                  <IoImages />
                </label>
              </div>
            </div>
            {/* Button */}
            <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
              <button
                className="btn btn-primary-light m-3 fw-5 px-5"
                type="submit"
              >
                Lưu lại
              </button>
            </div>
          </form>
        </div>
      </div>
    )
}
export default ContentPersonalMobile