import classNames from "classnames/bind";
import styles from "../../Admin.module.scss"
import { PiShoppingBagOpen, PiStarFill, PiStarHalfFill, PiWarningCircle } from "react-icons/pi";
import { IoCardOutline } from "react-icons/io5";
import DateMonth from "../../../../Component/Date/DateMonth";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { HiAdjustmentsHorizontal, HiOutlineCalendarDays } from "react-icons/hi2";
import { GoRocket } from "react-icons/go";
import Tippy from "@tippyjs/react";
import ModalReact from "../../../../Component/Modal/ModalReact";
import ListRequestCustomer from "./ListRequestCustomer";
import LoadingSpinner from "../../../../Component/Loading/LoadingSpinner";
import ButtonAddFunction from "./ButtonAddFunction";
const cx = classNames.bind(styles);




function StatisticalDonhang(props) {
  const { socketio, dataUser, dataDetail } = props
  const [dataRequest, setDataRequest] = useState([])
  const totalWorkday = useRef(0)
  const totalPaymentAmount = useRef(0)
  const [loading, setLoading] = useState(true)

  // Quản lý danh sách yêu cầu.
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
      object: {
        software_orders_id: dataDetail[0].id,
      },
    }
    socketio.emit("client-get-data-id-software-orders-list", params)
    socketio.on("server-get-data-id-software-orders-list", (data) => {
      setDataRequest([...data])
      if (data.length > 0) {
        // Tổng ngày làm việc.
        let dataWorkday = data.map((item) => item.workday)
        totalWorkday.current = dataWorkday.reduce(
          (a, b) => Number(a) + Number(b),
        )
        // Tổng tiền thanh toán.
        let dataPayment = data.map((item) => item.payment_amount)
        totalPaymentAmount.current = dataPayment.reduce(
          (a, b) => Number(a) + Number(b),
        )
      }
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-data-id-software-orders-list")
      socketio.off("server-get-data-id-software-orders-list")
    }
  }, [dataDetail])

  // Quản lý danh sách yêu cầu của khách hàng.
  const [modalRequest, setModalRequest] = useState(false)
  const handleShowListRequest = () => {
    setModalRequest(true)
  }
  const handleCloseModalListRequest = () => setModalRequest(false)

  if (loading) {
    return <LoadingSpinner />
  }
  if (dataRequest.length === 0) {
    return (
      <div className="text-center mt-3 fs-15">
        <div className="fs-18">Chưa có yêu cầu cụ thể</div>
        <div className="text-center mt-3">
          <button
            className="border-0 btn btn-secondary-gradient btn-wave fw-5"
            onClick={handleShowListRequest}
          >
            Thêm chức năng
          </button>
        </div>
        {/* Modal request */}
        {modalRequest && (
          <ModalReact
            modalTitle="Danh sách yêu cầu của khách hàng"
            size="modal-md"
            showModal={modalRequest}
            handleClose={handleCloseModalListRequest}
            theme={
              <ListRequestCustomer
                dataDetail={dataDetail}
                listDataRequest={dataRequest}
                {...props}
              />
            }
          />
        )}
      </div>
    )
  }

  return (
    <div>
      {/* Search date */}
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="alert alert-warning-1 fs-15 rounded-4 mb-0">
          <span className="fw-5 me-1">Yêu cầu:</span>
          {dataDetail[0].project_name}
        </div>
        <div>
          <button
            className="border-0 btn btn-secondary-gradient btn-wave fw-5 rounded-4"
            onClick={handleShowListRequest}
          >
            Danh sách chức năng
          </button>
        </div>
      </div>
      {/* Thống kê */}
      <CardStatistical
        {...props}
        dataDetail={dataDetail}
        dataRequest={dataRequest}
        totalWorkday={totalWorkday.current}
        totalPaymentAmount={totalPaymentAmount.current}
      />
      {/* Biểu đồ lưu lượng truy cập theo thời gian*/}
      <ListStatistical
        {...props}
        dataRequest={dataRequest}
        totalWorkday={totalWorkday.current}
        totalPaymentAmount={totalPaymentAmount.current}
      />

      {/* Modal request */}
      {modalRequest && (
        <ModalReact
          modalTitle="Danh sách yêu cầu của khách hàng"
          size="modal-md"
          showModal={modalRequest}
          handleClose={handleCloseModalListRequest}
          theme={
            <ListRequestCustomer
              dataDetail={dataDetail}
              listDataRequest={dataRequest}
              {...props}
            />
          }
        />
      )}
    </div>
  )
}
export default StatisticalDonhang


function CardStatistical(props) {
  const { socketio, dataUser, dataDetail, dataRequest, totalWorkday, totalPaymentAmount } = props
  
  return (
    <>
      <div className="row mb-2">
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
          <div className="card custom-card mb-2">
            <div className="card-body">
              <div className="row">
                <div className="col-9 pe-0">
                  <p className="mb-2">
                    <span className="fs-16">Mục yêu cầu</span>
                  </p>
                  <p className="mb-2 fs-12">
                    <span className="fs-20 fw-semibold lh-1 vertical-bottom mb-0">
                      {dataRequest.length}
                    </span>
                    <span className="d-block fs-12 fw-semibold text-muted">
                      Số lượng yêu cầu
                    </span>
                  </p>
                </div>
                <div className="col-3">
                  {/* <p
                    className="badge bg-success-transparent float-end d-inline-flex cursor-pointer"
                    onClick={handleShowListRequest}
                  >
                    Danh sách
                  </p> */}
                  <p className="main-card-icon mb-0">
                    <HiAdjustmentsHorizontal className="fs-25 text-muted" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
          <div className="card custom-card mb-2">
            <div className="card-body">
              <div className="row">
                <div className="col-9 pe-0">
                  <p className="mb-2">
                    <span className="fs-16">Số ngày dự kiến</span>
                  </p>
                  <p className="mb-2 fs-12">
                    <span className="fs-20 fw-semibold lh-1 vertical-bottom mb-0">
                      {totalWorkday.toLocaleString().replace(/[.]/g, ",")} ngày
                    </span>
                    <span className="d-block fs-12 fw-semibold text-muted">
                      Số ngày dự kiến bàn giao
                    </span>
                  </p>
                  {/* <div className="cursor-pointer">Danh sách</div> */}
                </div>
                <div className="col-3">
                  {/* <p className="badge bg-danger-transparent float-end d-inline-flex">
                    12%
                  </p> */}
                  <p className="main-card-icon mb-0">
                    <HiOutlineCalendarDays className="fs-25 text-muted" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
          <div className="card custom-card mb-2">
            <div className="card-body">
              <div className="row">
                <div className="col-9 pe-0">
                  <p className="mb-2">
                    <span className="fs-16">Giá dự kiến</span>
                  </p>
                  <p className="mb-2 fs-12">
                    <span className="fs-20 fw-semibold lh-1 vertical-bottom mb-0">
                      {/* {(15 * 600000).toLocaleString().replace(/[.]/g, ",")}đ */}
                      {totalPaymentAmount.toLocaleString().replace(/[.]/g, ",")}đ
                    </span>
                    <span className="d-block fs-12 fw-semibold text-muted">
                      Số tiền dự kiến
                    </span>
                  </p>
                  {/* <div className="cursor-pointer">Danh sách</div> */}
                </div>
                <div className="col-3">
                  {/* <p className="badge bg-success-transparent float-end d-inline-flex">
                    <i className="ti ti-caret-up me-1"></i>27%
                  </p> */}
                  <p className="main-card-icon mb-0">
                    <IoCardOutline className="fs-25 text-muted" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


function ListStatistical(props) {
  const { socketio, dataUser, dataDetail, dataRequest, totalWorkday, totalPaymentAmount } = props;
  // Set dataRequest status = 1;
  let dataRequestStatus = dataRequest.filter((item) => item.status === 1);
  let tysuatSuccess = (dataRequestStatus.length / dataRequest.length) * 100
  return (
    <div className="heightScroll" style={{ height: "calc(100vh - 21.5rem)" }}>
      {/* Đánh giá người dùng */}
      <div className="d-flex justify-content-between align-items-center mb-2">
        {/* <span>
          Đánh giá độ phức tạp ở mức <span className="fw-5">43.2%</span>
        </span> */}
        {/* <div className="d-flex align-items-center gap-1">
          <span className="me-1 text-muted">Dự kiến hoàn thành</span>
          <span className="fw-5">trước 5 ngày</span>
        </div> */}
      </div>
      {/* List statistical */}
      <Tippy content="Tiến độ hoàn thành dự án">
        <div
          className="progress progress-xl progress-animate custom-progress-4 rounded-0 mb-2"
          role="progressbar"
          aria-valuenow="10"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{
            borderBottom: "1px solid white",
            backgroundColor: "#f4edea",
          }}
        >
          <div
            className="progress-bar bg-success rounded-0 fw-5"
            style={{ width: tysuatSuccess + "%" }}
          >
            <span>
              Đã hoàn tất{" "}
              {Number.isInteger(tysuatSuccess)
                ? tysuatSuccess
                : tysuatSuccess.toFixed(1)}
              %
            </span>
          </div>
          <div className="progress-bar-label">
            <span className="ms-2">
              {Number.isInteger(tysuatSuccess)
                ? tysuatSuccess
                : tysuatSuccess.toFixed(1)}
              %
            </span>
          </div>
        </div>
      </Tippy>

      {/* List conten */}
      {dataRequest.map((item, index) => {
        let setTimeTySuat = 0
        if (Number(item.workday) > 0) {
          setTimeTySuat = (Number(item.workday) / totalWorkday) * 100
        }
        let bg = "bg-info"
        if (setTimeTySuat >= 70) {
          bg = "bg-danger"
        } else if (setTimeTySuat >= 40 && setTimeTySuat < 70) {
          bg = "bg-warning"
        }
        return (
          <Tippy key={index} content="Tỷ lệ thời gian làm việc">
            <div
              className="progress progress-xl progress-animate custom-progress-4 rounded-0"
              role="progressbar"
              aria-valuenow="10"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{
                borderBottom: "1px solid white",
                backgroundColor: "#f4edea",
              }}
            >
              <div
                className={`fw-5 progress-bar bg-info rounded-0 ${bg}`}
                style={{ width: setTimeTySuat + "%" }}
              >
                <span>
                  {Number.isInteger(setTimeTySuat)
                    ? setTimeTySuat
                    : setTimeTySuat.toFixed(1)}
                  %
                </span>
              </div>
              <div className="progress-bar-label">
                {item.cate}
                {dataUser[0].role_token ===
                  process.env.REACT_APP_ROLE_ADMIN && (
                  <span>: {item.workday} ngày</span>
                )}
              </div>
            </div>
          </Tippy>
        )
      })}
    </div>
  )
}