import classNames from "classnames/bind"
import styles from "../../PersonalPage.module.scss"
import { images } from "../../../../assets/images";
import { IoIosArrowDown } from "react-icons/io";
import Tippy from "@tippyjs/react";
import { MdCheckCircle } from "react-icons/md";
import { LuAlarmCheck } from "react-icons/lu";
import { IoAddCircleOutline } from "react-icons/io5";
import { TiStarFullOutline } from "react-icons/ti";
import moment from "moment";
const cx = classNames.bind(styles);

function PaymentList() {
  // Ngăn chặn vào cửa hàng trước khi khai trương
  const dateEnd = moment("2025-06-03")
  const currentTime = moment()
  const diffDay = dateEnd.diff(currentTime, "days")
  if (diffDay > 0) {
    return (
      <div>
        Chúng tôi sẽ cập nhật thông tin vào 2025. Cảm ơn bạn đã ghé qua.
      </div>
    )
  }
  // Quản lý xem thêm.
  const handleSeeMorePayment = (id) => {}
  return (
    <div>
      {/* payment list */}
      <div
        className={cx(
          "d-flex justify-content-between rounded-5",
          "payment-list",
        )}
      >
        <div className={cx("d-flex align-items-center gap-3 p-1 px-2")}>
          <span className="avatar avatar-rounded">
            <img src={images.noImage} alt="img" />
          </span>
          <div>
            <div className="fw-5 fs-18">Web bất động sản</div>
            <p className="mb-0 text-muted fs-15">
              <span>
                15,000,000đ
                <span className="ms-2 me-2">|</span>
                21/09/2024
              </span>
            </p>
          </div>
        </div>
        <div className="p-1 px-2 d-flex align-items-center">
          <button
            className="border-0 bg-transparent text-muted"
            onClick={() => handleSeeMorePayment(1)}
          >
            <IoIosArrowDown className="fs-21" />
          </button>
        </div>
      </div>
      {/* xem them payment */}
      <div className="col-12 wrapper_ground">
        <div
          className={cx("rounded-4 p-2 px-3 mt-2 col-8 list_items")}
          style={{ background: "#f4ede9" }}
        >
          <div className="mb-1 d-flex justify-content-between">
            <span className="text-muted">Tiến độ</span>
            <span className="fs-15 text-danger fw-5">Bàn giao: 10/10/2024</span>
          </div>
          <Tippy content="Xử lý database">
            <div
              className="progress progress-xl progress-animate custom-progress-4 success rounded-0"
              role="progressbar"
              aria-valuenow="10"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ borderBottom: "1px solid white" }}
            >
              <div
                className="progress-bar bg-warning rounded-0"
                style={{ width: "21%" }}
              >
                21%
              </div>
              <div className="progress-bar-label">25/09</div>
              <div className="px-1">
                <LuAlarmCheck className="text-warning fs-15" />
              </div>
            </div>
          </Tippy>
          <Tippy content="Xử lý giao diện">
            <div
              className="progress progress-xl progress-animate custom-progress-4 success rounded-0"
              role="progressbar"
              aria-valuenow="10"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ borderBottom: "1px solid white" }}
            >
              <div
                className="progress-bar bg-warning rounded-0"
                style={{ width: "12%" }}
              >
                12%
              </div>
              <div className="progress-bar-label">24/09</div>
              <div className="px-1">
                <MdCheckCircle className="text-success fs-15" />
              </div>
            </div>
          </Tippy>
          <Tippy content="Xử lý giao dịch">
            <div
              className="progress progress-xl progress-animate custom-progress-4 success rounded-0"
              role="progressbar"
              aria-valuenow="10"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ borderBottom: "1px solid white" }}
            >
              <div
                className="progress-bar bg-warning rounded-0"
                style={{ width: "3%" }}
              >
                3%
              </div>
              <div className="progress-bar-label">21/09</div>
              <div className="px-1">
                <MdCheckCircle className="text-success fs-15" />
              </div>
            </div>
          </Tippy>
        </div>
      </div>
      <div className="col-12">
        {/* Đánh giá */}
        <form>
          <div className="wrapper_ground">
            <div className="list_items col-12 col-xl-8 mt-2">
              <div className="form-floating">
                <textarea
                  className="form-control rounded-4"
                  placeholder="Cảm nhận của bạn về sản phẩm ?"
                  style={{ height: "100px" }}
                ></textarea>
                <label className="fs-15 text-muted">
                  Cảm nhận của bạn về sản phẩm này ?
                </label>
              </div>
            </div>
            <div
              className={cx(
                "rounded-4 p-2 px-3 mt-2 col-3 col-xl-3 list_items",
              )}
              style={{ background: "#f4ede9" }}
            >
              <div className="text-center">
                <TiStarFullOutline className="text-warning" />
                <TiStarFullOutline className="text-warning" />
                <TiStarFullOutline className="text-warning" />
                <TiStarFullOutline className="text-warning" />
                <TiStarFullOutline className="text-warning" />
              </div>
              <div className="text-center fs-21 text-warning fw-5">
                5/5 <span className="fs-15">(1,5k)</span>
              </div>
              <div className="text-center">
                <button className="border-0 rounded-4 bg-warning fs-12 fw-5 px-2">
                  Gửi cảm nhận
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
export default PaymentList