import CheckPCMobile from "../../handle-reuses/reuses/check.pc.mobile"
import AdminPageMobile from "./Theme/Mobile"
import AdminPagePC from "./Theme/PC"

function AdminPage(props) {
    return (
      <CheckPCMobile>
        {(isMobile) =>
          isMobile ? (
            <AdminPageMobile {...props} />
          ) : (
            <AdminPagePC {...props} />
          )
        }
      </CheckPCMobile>
    )
}
export default AdminPage