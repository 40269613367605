import moment from "moment"
import HeaderDashboardPC from "../../../../Component/Header/HeaderDashboardPC"
import SideMenuPC from "../../../../Component/Sidebar/SideMenu"
import ContentPricingPC from "../../Component/ContentPricingPC"

function PricingPagePC(props) {
  const { sizeScreen } = props
  // Ngăn chặn vào cửa hàng trước khi khai trương
  const dateEnd = moment("2025-06-03")
  const currentTime = moment()
  const diffDay = dateEnd.diff(currentTime, "days")
  if (diffDay > 0) {
    return (
      <div className="d-flex justify-content-center">
        <div className="text-center col-5 ">
          <div
            className="progress progress-sm progress-custom mb-3 mt-5 progress-animate mt-5"
            role="progressbar"
            aria-valuenow="50"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <h6 className="progress-bar-title bg-warning">Tiến độ</h6>
            <div className="progress-bar bg-warning" style={{ width: "90%" }}>
              <div className="progress-bar-value bg-warning">90%</div>
            </div>
          </div>
          <div className="">
            Chúng tôi sẽ cập nhật các gói dịch vào 2025. Cảm ơn bạn đã ghé qua.
          </div>
        </div>
      </div>
    )
  }
  return <ContentPricingPC sizeScreen={sizeScreen} />
}
export default PricingPagePC