import { PiSealPercentLight } from "react-icons/pi";
import { images } from "../../../assets/images";
import SearchHorizontal from "../../../Component/Search/SearchHorizontal";
import { useContext, useEffect, useRef, useState } from "react";
import ModalReact from "../../../Component/Modal/ModalReact";
import { SliderImageList } from "../../../Component/Slider";
import FormPaymentProduct from "./FormPaymentProduct";
import { toast, ToastContainer } from "react-toastify";
import AlertSuccess from "../../../Component/Alert/AlertSuccess";
import FormAddProduct, { FormEditProduct } from "./FormProduct";
import AppContext from "../../../handle-reuses/Contexts/CreateContexts";
import LoadingSpinner from "../../../Component/Loading/LoadingSpinner";
import classNames from "classnames/bind";
import styles from "../Shops.module.scss";
import { TextLimit } from "../../../handle-reuses/reuses";
import { IoEyeOutline } from "react-icons/io5";
import moment from "moment";
import { Link } from "react-router-dom";
import { LuShoppingCart } from "react-icons/lu";
import { TbArrowBadgeRight } from "react-icons/tb";
import ButtonAddCateShops from "./ButtonAddCateShops";
import { BsDatabaseAdd } from "react-icons/bs";
import Tippy from "@tippyjs/react";
import * as IconGithub from "react-icons/go"

const cx = classNames.bind(styles);


function ContentShopsPC(props) {
  const {sizeScreen} = props
  const {socketio, dataUser} = useContext(AppContext)
  const [dataCate, setDataCate] = useState([])
  const [dataTechShops, setDataTechShops] = useState([]);
  const dataTechShopsRef = useRef([])
  const [objectSearch, setObjectSearch] = useState({
     cateSearch: "",
     textSearch: "",
   })
  const [loading, setLoading] = useState(true)
  // Quan ly danh muc.
  useEffect(() => {
    const paramsCate = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
    }
    socketio.emit("client-get-data-tech-shops-cate", paramsCate)
    socketio.on("server-get-data-tech-shops-cate", (data) => {
      setDataCate([...data])
    });
    return () => {
      socketio.off("client-get-data-tech-shops-cate")
      socketio.off("server-get-data-tech-shops-cate")
    }
  }, []);
  const [activeCate, setActiveCate] = useState("Tất cả");
  const handleActiveCate = (cate) => {
    setActiveCate(cate)
    setObjectSearch({
      ...objectSearch,
      cateSearch: cate === "Tất cả" ? "" : cate,
    })
  }
    
  // Quan ly search.
  const handleValueSearch = (textSearch) => {
    setObjectSearch({
      ...objectSearch,
      textSearch: textSearch
    });
    
  }

  // Quan ly lay du lieu
  const handleGetSearchDataTechShops = (object) => {
    let params = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
      object: {
        cateSearch: activeCate === "Tất cả" ? "" : activeCate,
        textSearch: object.textSearch
      }
    }
    socketio.emit("client-get-search-data-tech-shops", params)
    socketio.on("server-get-search-data-tech-shops", (data) => {
      setDataTechShops([...data])
      dataTechShopsRef.current = [...data]
      setLoading(false)
    })
  }
  useEffect(() => {
    // Get data.
    handleGetSearchDataTechShops(objectSearch)
    return () => {
      socketio.off("client-get-search-data-tech-shops")
      socketio.off("server-get-search-data-tech-shops")
    }
  }, [objectSearch])


  // Quản lý thêm mới.
  const [addProduct, setAddProduct] = useState(false);
  const handleAddProduct = () => {
      setAddProduct(true)
  }
  const handleCloseAddProduct = () => setAddProduct(false)
  const handleAddProductSuccess = (object) => {
      if (object === "success") {
          setAddProduct(false)
      }
  }

  // Cập nhật trạng thái khi edit thành công.
  const handleEditProductSuccess = (object) => {
    if (object.status) {
      setLoading(true)
      // set lại data mới nhất khi edit thành công.
      const paramsUpdate = {
        iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
        object: { id: object.shops_id },
      }
      socketio.emit("client-update-data-edit-success-tech-shops", paramsUpdate)
      socketio.on("server-update-data-edit-success-tech-shops", (data) => {
        if (data.length > 0) {
          // Thực hiện việc update.
          let dataUpdate = dataTechShopsRef.current
          let index = dataUpdate.findIndex((item) => item.id === data[0].id)
          dataUpdate[index] = data[0]
          setDataTechShops([...dataUpdate])
          dataTechShopsRef.current = [...dataUpdate]
          setLoading(false)
        }
      })
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-update-data-edit-success-tech-shops")
      socketio.off("server-update-data-edit-success-tech-shops")
    }
  }, [dataTechShops])

  if (loading) {
    return <LoadingSpinner />
  }
  if (dataCate.length === 0) {
    return <div className="text-center mt-3">Chưa có danh mục</div>
  }

  return (
    <div className="wrapper_content_children">
      {/* Header */}
      <div className="wrapper_ground">
        {/* category */}
        <div className="d-flex align-items-center gap-3 list_items col-xl-6">
          <button
            className={cx(
              "btn btn-wave fw-5 border-0 rounded-4 d-flex align-items-center justify-content-center flex-direction-column gap-1",
              activeCate === "Tất cả"
                ? "button-active"
                : "bg-transparent text-muted",
            )}
            onClick={() => handleActiveCate("Tất cả")}
          >
            <IconGithub.GoServer className="fs-21" />
            Tất cả
          </button>
          {/* <button
            className={cx(
              "btn btn-wave fw-5 border-0 rounded-3",
              activeCate === "Miễn phí" ? "btn-secondary-gradient" : "btn-light",
            )}
            onClick={() => handleActiveCate("Miễn phí")}
          >
            Miễn phí
          </button> */}
          {dataCate.map((item, index) => {
            // tìm icon theo tên icon.
            let nameIcon = item.icon_cate
            let FormatIcon = IconGithub[nameIcon]
            return (
              <button
                key={index}
                className={cx(
                  "btn btn-wave fw-5 border-0 rounded-4 d-flex align-items-center justify-content-center flex-direction-column gap-1",
                  activeCate === item.title
                    ? "button-active"
                    : "bg-transparent text-muted",
                )}
                onClick={() => handleActiveCate(item.title)}
              >
                {<FormatIcon /> ? <FormatIcon className="fs-21" /> : "NoIcon"}
                {item.title}
              </button>
            )
          })}
        </div>

        <div className="list_items col- d-flex align-items-center gap-3">
          {/* Search */}
          <SearchHorizontal
            result={handleValueSearch}
            className="rounded-5"
            placeholder="Danh mục, thể loại, tên phần mềm"
          />
          {/* Thêm mới */}
          {dataUser.length > 0 && dataUser[0].role_id === 1 && (
            <>
              <Tippy content="Thêm sản phẩm">
                <button
                  className="border-0 btn btn-warning btn-wave fw-5 rounded-4 p-1"
                  style={{ height: "40px", width: "40px" }}
                  onClick={handleAddProduct}
                >
                  <BsDatabaseAdd className="fs-21" />
                </button>
              </Tippy>
              <ButtonAddCateShops socketio={socketio} dataUser={dataUser} />
            </>
          )}
        </div>
      </div>
      {/* Content */}
      <div className="mt-4 heightScroll">
        <ListItemProduction
          sizeScreen={sizeScreen}
          socketio={socketio}
          dataUser={dataUser}
          dataTechShops={dataTechShops}
          result={handleEditProductSuccess}
        />
      </div>

      {/* Modal add product */}
      {addProduct && (
        <ModalReact
          modalTitle="Thêm mới sản phẩm"
          showModal={addProduct}
          handleClose={handleCloseAddProduct}
          theme={
            <FormAddProduct
              socketio={socketio}
              dataUser={dataUser}
              result={handleAddProductSuccess}
            />
          }
        />
      )}
    </div>
  )
}

export default ContentShopsPC


// Danh sách sản phẩm.
function ListItemProduction({sizeScreen, socketio, dataUser, dataTechShops, result}) {
  // Quản lý lấy hình ảnh sản phẩm
  const [dataImages, setDataImages] = useState([])
  const dataImagesRef = useRef([])
  const [dataCheckActive, setDataCheckActive] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    // Làm mới lại data images
    dataImagesRef.current = []
    // Set data images
    for (let i = 0; i < dataTechShops.length; i++) {
      const params = {
        iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
        object: {
          shops_id: dataTechShops[i].id,
        },
      }
      socketio.emit("client-get-data-tech-shops-images", params)
      socketio.on("server-get-data-tech-shops-images", (data) => {
        dataImagesRef.current = [...dataImagesRef.current, ...data]
      })
    }

    // Set data check kích hoạt.
    const paramsActive = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
      object: {
        tech_user_id: dataUser.length > 0 ? dataUser[0].id : 0,
      },
    }
    socketio.emit("client-get-data-tech-personal-page-menu", paramsActive)
    socketio.on("server-get-data-tech-personal-page-menu", (data) => {
      setDataCheckActive([...data])
      // Set lại danh sách images (loại bỏ giá trị giống nhau trong mảng)
      let filterDataImages = dataImagesRef.current.filter(
        (item, index, arrOld) => arrOld.indexOf(item) === index,
      )
      setDataImages([...filterDataImages])
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-data-tech-shops-images")
      socketio.off("server-get-data-tech-shops-images")

      socketio.off("client-get-data-tech-personal-page-menu")
      socketio.off("server-get-data-tech-personal-page-menu")
    }
  }, [dataTechShops])

  // Quản lý xem chi tiết sản phẩm.
  const [modalDetail, setModalDetail] = useState(false)
  const dataDetail = useRef()
  const handleDetailProduct = (object) => {
    setModalDetail(true)
    dataDetail.current = object
    // Xử lý đếm lượt xem sản phẩm.
    const paramsCountview = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
      object: {
        id: object.item.id,
      },
    }
    socketio.emit("client-edit-row-countview-tech-shops", paramsCountview)
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-row-countview-tech-shops")
    }
  }, [dataDetail.current])
  const handleCloseDetailProduct = () => {
    setModalDetail(false)
    dataDetail.current = undefined
  }

  // Quản lý edit data.
  const [editProduct, setEditProduct] = useState(false)
  const dataEdit = useRef([])
  const handleEditProduct = (object) => {
    setEditProduct(true)
    dataEdit.current = object
  }
  const handelCloseEditProduct = () => {
    setEditProduct(false)
    dataEdit.current = undefined
  }
  const handleEditProductSuccess = (object) => {
    if (object.status) {
      result(object)
    }
  }

  if (dataTechShops.length === 0) {
    return (
      <div className="text-center mt-3 fs-18">
        Kho hiện tại chưa có sản phẩm nào.
      </div>
    )
  }
  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <div className="row mb-5">
      {dataTechShops.map((item, index) => {
        // Set giá khuyến mãi.
        let giakhuyenmai = Number(item.giaban) * (Number(item.khuyenmai) / 100)
        let giabanThucte = Number(item.giaban) - giakhuyenmai
        // Set images.
        let listImages = dataImages.filter(
          (item_) => item_.shops_id === item.id,
        )
        let imageAvatar = listImages.filter(
          (item_) => item_.active_avatar === 1,
        )
        // set thời hạn khuyến mãi.
        let thoihanKhuyenmai = moment(item.thoigian_khuyenmai)
        let currentTime = moment()
        let thoigianKhuyenmai = thoihanKhuyenmai.diff(currentTime, "days")
        // Set data support.
        let listSupport = item.hotro_khimua.split(",")
        // Set membership (check show button kich hoat).
        let membership = item.pricing_duockichhoat.split(",")
        let checkKichhoat
        if (dataUser.length > 0) {
          checkKichhoat = membership.filter(
            (item_) => Number(item_) === dataUser[0].pricing_id,
          )
        } else {
          checkKichhoat = membership.filter((item_) => Number(item_) === 1)
        }
        // Check status kich hoat.
        let arrActive = dataCheckActive.filter(
          (item_) => item_.shops_id === item.id,
        )

        return (
          <div
            className={`mb-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 ${
              sizeScreen === "more" ? "col-xxl-3" : "col-xxl-4"
            }`}
            key={index}
          >
            <div className="product-card">
              <div className="card-body">
                <div style={{ height: "241px" }} className="mb-3">
                  {imageAvatar.length > 0 ? (
                    <img
                      src={"https://tech.view360.vn" + imageAvatar[0].path}
                      className="card-img mb-3 rounded-3"
                      alt="..."
                      height={241}
                    />
                  ) : (
                    <img
                      src={
                        "https://tech.view360.vn" + listImages.length > 0
                          ? listImages[0].path
                          : ""
                      }
                      className="card-img mb-3 rounded-3"
                      alt="..."
                    />
                  )}
                </div>
                <p className="product-name fw-semibold mb-0 d-flex align-items-center justify-content-between">
                  <TextLimit text={item.tenduan} number={36} />
                  <span className="float-end text-muted fs-15 fw-4">
                    <IoEyeOutline className="me-1 fs-18" />
                    {Number(item.countview)
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </span>
                </p>
                <p className="product-description fs-15 text-muted mb-0">
                  <TextLimit text={item.mieuta} number={36} />
                </p>
                <p className="text-muted fs-15">
                  {item.danhmuc}
                  <TbArrowBadgeRight className="ms-1 me-1" />
                  {item.theloai}
                </p>
                <p className="mb-1 fw-semibold fs-16 d-flex align-items-center justify-content-between">
                  <span>
                    {/* set giá khuyến mãi */}
                    {Number(thoigianKhuyenmai) >= 0 ? (
                      <span>
                        {giabanThucte.toLocaleString().replace(/[.]/g, ",")}đ
                      </span>
                    ) : (
                      <span>
                        {Number(item.giaban)
                          .toLocaleString()
                          .replace(/[.]/g, ",")}
                        đ
                      </span>
                    )}

                    {/* Set giá gốc */}
                    {Number(thoigianKhuyenmai) >= 0 && (
                      <span
                        className={cx(
                          "text-muted ms-1 op-6 d-inline-block",
                          Number(item.khuyenmai) > 0 &&
                            "text-decoration-line-through",
                        )}
                      >
                        {Number(item.giaban)
                          .toLocaleString()
                          .replace(/[.]/g, ",")}
                        đ
                      </span>
                    )}
                  </span>
                  {Number(thoigianKhuyenmai) >= 0 && (
                    <span className="badge bg-danger-transparent float-end fs-14 rounded-5">
                      Tiết kiệm {item.khuyenmai}%
                    </span>
                  )}
                </p>
                <p className="fs-15 text-success fw-semibold mb-0 d-flex align-items-center">
                  <PiSealPercentLight className="fs-21 me-1" />
                  Đăng ký thành viên giảm thêm 3%
                </p>
              </div>
              <div className="card-footer text-center wrapper_ground mt-3">
                {checkKichhoat.length > 0 && (
                  <ButtonKichhoatPersonalPage
                    socketio={socketio}
                    dataUser={dataUser}
                    objectShops={item}
                    arrActive={arrActive}
                  />
                )}
                <button
                  className="btn btn-secondary-light fw-5 list_items rounded-3"
                  onClick={() =>
                    handleDetailProduct({
                      item,
                      listImages,
                      giakhuyenmai,
                      giabanThucte,
                      thoigianKhuyenmai,
                      listSupport,
                      arrActive,
                    })
                  }
                >
                  Chi tiết
                </button>
                {dataUser.length > 0 && dataUser[0].id === item.author && (
                  <button
                    className="btn btn-warning-light fw-5 list_items rounded-3"
                    onClick={() =>
                      handleEditProduct({
                        item,
                        listImages,
                      })
                    }
                  >
                    Sửa
                  </button>
                )}
              </div>
            </div>
          </div>
        )
      })}

      {/* Modal detail product */}
      {modalDetail && (
        <ModalReact
          modalTitle="Chi tiết sản phẩm"
          showModal={modalDetail}
          handleClose={handleCloseDetailProduct}
          theme={
            <ItemDetailProduct
              dataDetail={dataDetail.current}
              socketio={socketio}
              dataUser={dataUser}
            />
          }
        />
      )}
      {/* modal edot product */}
      {editProduct && (
        <ModalReact
          modalTitle="Sửa thông tin sản phẩm"
          showModal={editProduct}
          handleClose={handelCloseEditProduct}
          theme={
            <FormEditProduct
              socketio={socketio}
              dataUser={dataUser}
              dataEdit={dataEdit.current}
              result={handleEditProductSuccess}
            />
          }
        />
      )}
    </div>
  )
}

// Chi tiết sản phẩm.
function ItemDetailProduct({ dataDetail, socketio, dataUser }) {
  // quản lý xử lý data detail.
  const [dataImages, setDataImages] = useState([])
  useEffect(() => {
    let arrPath = dataDetail.listImages.map((item) => item.path)
    setDataImages([...arrPath])
  }, [dataDetail])

  // Quản lý đăng ký thành viên.
  const handleRegisterMember = () => {
    window.open("/pricing")
  }

  // Set membership.
  let arrMembership = dataDetail.item.pricing_duockichhoat.split(",")
  // Set hạng thành viên.
  const membership = useRef("")
  const checkKichhoat = useRef([])
  useEffect(() => {
    // set thành viên còn đang là thành viên hay không.
    if (dataUser.length > 0) {
      if (dataUser[0].date_end) {
        let dateEnd = moment(dataUser[0].date_end)
        let currentTime = moment()
        let daysDiff = dateEnd.diff(currentTime, "days")
        if (Number(daysDiff) >= 0) {
          membership.current = "active"
        }
      }
    }
    // set hiển thị nút kích hoạt.
    if (dataUser.length > 0) {
      checkKichhoat.current = arrMembership.filter(
        (item) => Number(item) === dataUser[0].pricing_id,
      )
    } else {
      checkKichhoat.current = arrMembership.filter((item) => Number(item) === 1)
    }
  }, [dataDetail])

  // Quan ly nang cap goi dich vu.
  const handleNangcapGoidichvu = () => {
    window.open("/pricing")
  }
  // Quản lý đặt mua sản phẩm.
  const [paymentProduct, setPaymentProduct] = useState(false)
  const handlePaymentProduct = (status_) => {
    setPaymentProduct(status_)
  }
  const [alertSuccess, setAlertSuccess] = useState(false)
  const handlePaymentProductSuccess = (status_) => {
    if (status_) {
      setPaymentProduct(false)
      setAlertSuccess(true)
    }
  }
  // danh mục ảnh, video.
  const [cateImages, setCateImages] = useState("cate-images");
  const handleCateImages = (cate) => {
    setCateImages(cate)
  }
  // Quan ly hien thi video.
  const handleCateVideo = (link) => {
    window.open(link)
  }
  // Quản lý xem trước.
  const handlePreview = (link) => {
    window.open(link)
  }

  
  return (
    <div>
      <div className="wrapper_ground">
        <button
          className={cx(
            "border-0 list_items fw-5 btn btn-wave fs-15",
            cateImages === "cate-images" ? "btn-success" : "btn-light",
          )}
          onClick={() => handleCateImages("cate-images")}
        >
          Hình ảnh
        </button>
        {dataDetail.item.link_video && (
          <button
            className={cx(
              "border-0 list_items fw-5 btn btn-wave fs-15 btn-light",
            )}
            onClick={() => handleCateVideo(dataDetail.item.link_video)}
          >
            Video
          </button>
        )}
        {dataDetail.item.link_xemtruoc && (
          <button
            className="border-0 list_items fw-5 btn btn-light btn-wave fs-15"
            onClick={() => handlePreview(dataDetail.item.link_xemtruoc)}
          >
            Xem trước
          </button>
        )}
      </div>
      {/* Hình ảnh */}
      <div className="mt-3">
        {cateImages === "cate-images" && (
          <SliderImageList
            data={dataImages}
            height={350}
            web="https://tech.view360.vn/"
          />
        )}
      </div>
      {/* Nội dung */}
      <div className="mt-3">
        {/* Thông tin chi tiết sản phẩm */}
        <div>
          <div className="mb-4">
            <div className="row">
              <div className="col-xl-12 mb-3">
                <div className="table-responsive-customer">
                  <table className="table table-bordered text-nowrap">
                    <tbody>
                      <tr>
                        <th scope="row" className="fw-semibold">
                          Tên dự án
                        </th>
                        <td>{dataDetail.item.tenduan}</td>
                      </tr>
                      <tr>
                        <th scope="row" className="fw-semibold">
                          Thể loại
                        </th>
                        <td>{dataDetail.item.theloai}</td>
                      </tr>
                      <tr>
                        <th scope="row" className="fw-semibold">
                          Tương thích
                        </th>
                        <td>{dataDetail.item.tuongthich}</td>
                      </tr>
                      <tr>
                        <th scope="row" className="fw-semibold">
                          Ngày xuất bản
                        </th>
                        <td>
                          {moment(dataDetail.item.ngayxuatban).format(
                            "DD/MM/YYYY HH:mm:ss",
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="fw-semibold">
                          Ngày cập nhật
                        </th>
                        <td>
                          {moment(dataDetail.item.addtime).format(
                            "DD/MM/YYYY HH:mm:ss",
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="fw-semibold">
                          Màn hình
                        </th>
                        <td>{dataDetail.item.cactrang}</td>
                      </tr>
                      <tr>
                        <th scope="row" className="fw-semibold">
                          Mô tả
                        </th>
                        <td>{dataDetail.item.mieuta}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* Thông tin giảm giá, đặt hàng */}
              <div className="col-xl-12 mt-xxl-0 mt-3">
                <div className="mb-3">
                  <div className="offer-promotion mb-4">
                    <div className="d-xxl-flex d-lg-block d-sm-flex flex-wrap justify-content-between align-items-center">
                      <div className="text-warning fw-5">
                        <span className="promotion-code fw-semibold">
                          {dataDetail.thoigianKhuyenmai >= 0 ? (
                            <span className="text-danger">
                              Khuyến mãi chỉ còn lại{" "}
                              {dataDetail.thoigianKhuyenmai} ngày
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                      {Number(dataDetail.thoigianKhuyenmai) >= 0 && (
                        <div className="mt-sm-0 mt-2">
                          <span className="bg-warning-transparent text-default fs-15 offer-details mb-0">
                            Tiết kiệm{" "}
                            <span className="text-success fw-semibold">
                              {dataDetail.giakhuyenmai
                                .toLocaleString()
                                .replace(/[.]/g, ",")}
                              đ
                            </span>
                          </span>
                        </div>
                      )}
                      <div className="mt-2 col-12">
                        <p className="mb-1 fw-semibold fs-16 d-flex align-items-center justify-content-between">
                          <span>
                            {Number(dataDetail.thoigianKhuyenmai) >= 0 ? (
                              <span>
                                {dataDetail.giabanThucte
                                  .toLocaleString()
                                  .replace(/[.]/g, ",")}
                                đ
                              </span>
                            ) : (
                              <span>
                                <span className="me-2 fs-18">Giá bán:</span>
                                <span className="fs-18">
                                  {Number(dataDetail.item.giaban)
                                    .toLocaleString()
                                    .replace(/[.]/g, ",")}
                                  đ
                                </span>
                              </span>
                            )}
                            {Number(dataDetail.thoigianKhuyenmai) >= 0 && (
                              <span
                                className={cx(
                                  "text-muted ms-1 op-6 d-inline-block",
                                  Number(dataDetail.item.khuyenmai) > 0 &&
                                    "text-decoration-line-through",
                                )}
                              >
                                {Number(dataDetail.item.giaban)
                                  .toLocaleString()
                                  .replace(/[.]/g, ",")}
                                đ
                              </span>
                            )}
                          </span>
                          {Number(dataDetail.thoigianKhuyenmai) >= 0 && (
                            <span className="badge bg-secondary-transparent float-end fs-14">
                              - {dataDetail.item.khuyenmai}%
                            </span>
                          )}
                        </p>
                      </div>
                      {/* Set membership */}
                      {membership.current === "active" && (
                        <>
                          <div className="mt-2 col-12">
                            <p className="mb-1 fw-semibold fs-16 d-flex align-items-center justify-content-between">
                              <span className="text-primary">
                                Bạn thuộc {dataUser[0].membership}
                              </span>
                              <span className="badge bg-secondary-transparent float-end fs-14 text-primary">
                                - {dataUser[0].pricing_discount}%
                              </span>
                            </p>
                          </div>
                          <div className="mt-2 col-12">
                            <p className="mb-1 fw-semibold fs-16 d-flex align-items-center justify-content-between">
                              <span className="text-primary">
                                Bạn đã tiết kiệm
                              </span>
                              {Number(dataDetail.thoigianKhuyenmai) >= 0 ? (
                                <span className="badge bg-secondary-transparent float-end fs-14 text-primary">
                                  {(
                                    Number(dataDetail.item.giaban) *
                                      (Number(dataUser[0].pricing_discount) /
                                        100) +
                                    Number(dataDetail.giakhuyenmai)
                                  )
                                    .toLocaleString()
                                    .replace(/[.]/g, ",")}
                                  đ
                                </span>
                              ) : (
                                <span className="badge bg-secondary-transparent float-end fs-14 text-primary">
                                  {(
                                    Number(dataDetail.item.giaban) *
                                    (Number(dataUser[0].pricing_discount) / 100)
                                  )
                                    .toLocaleString()
                                    .replace(/[.]/g, ",")}
                                  đ
                                </span>
                              )}
                            </p>
                          </div>
                          <div className="mt-2 col-12">
                            <p className="mb-1 fw-semibold fs-16 d-flex align-items-center justify-content-between">
                              <span className="text-primary">
                                Số tiền thanh toán
                              </span>
                              {Number(dataDetail.thoigianKhuyenmai) >= 0 ? (
                                <span className="badge bg-secondary-transparent float-end fs-14 text-primary">
                                  {(
                                    Number(dataDetail.item.giaban) *
                                    ((100 -
                                      (Number(dataUser[0].pricing_discount) +
                                        Number(dataDetail.item.khuyenmai))) /
                                      100)
                                  )
                                    .toLocaleString()
                                    .replace(/[.]/g, ",")}
                                  đ
                                </span>
                              ) : (
                                <span className="badge bg-secondary-transparent float-end fs-14 text-primary">
                                  {(
                                    Number(dataDetail.item.giaban) *
                                    ((100 -
                                      Number(dataUser[0].pricing_discount)) /
                                      100)
                                  )
                                    .toLocaleString()
                                    .replace(/[.]/g, ",")}
                                  đ
                                </span>
                              )}
                            </p>
                          </div>
                        </>
                      )}
                      {/* Đang ký thành viên */}
                      {membership.current !== "active" && (
                        <>
                          <div className="mt-2 col-12">
                            <p className="mb-1 fw-semibold fs-16 d-flex align-items-center justify-content-between">
                              <span className="text-primary">
                                Đăng ký thành viên
                              </span>
                              <span className="badge bg-secondary-transparent float-end fs-14 text-primary">
                                - 3%
                              </span>
                            </p>
                          </div>
                          <div className="mt-2 col-12">
                            <p className="mb-1 fw-semibold fs-16 d-flex align-items-center justify-content-between">
                              <span className="text-primary">
                                Tiết kiệm khi đăng ký thành viên
                              </span>
                              <span className="badge bg-secondary-transparent float-end fs-14 text-primary">
                                {(
                                  Number(dataDetail.item.giaban) * (3 / 100) +
                                  Number(dataDetail.giakhuyenmai)
                                )
                                  .toLocaleString()
                                  .replace(/[.]/g, ",")}
                                đ
                              </span>
                            </p>
                          </div>
                          <div>
                            <button
                              className="border-0 btn btn-secondary-gradient btn-wave fw-5"
                              onClick={handleRegisterMember}
                            >
                              Đăng ký
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="mb-4">
                    <p className="text-danger mb-0 fs-16">
                      Lưu ý: bạn có thể thuê để trải nghiệm trước.
                    </p>
                    <p className="mb-0 fs-16">
                      Nâng cấp gói{" "}
                      <span className="text-primary me-1">299,000đ/tháng</span>
                      bạn được trải nghiệm rất nhiều tính năng khác{" "}
                      <span className="text-primary">
                        Của hệ thống view 360.
                      </span>
                      <span className="ms-1">
                        <button
                          className="border-0 bg-transparent text-primary fw-5"
                          onClick={handleNangcapGoidichvu}
                        >
                          <u>Nâng cấp</u>
                        </button>
                      </span>
                    </p>
                  </div>
                  <div className="mb-4">
                    <ul className="list-group">
                      <li className="list-group-item">
                        <div className="lh-1">
                          <span className="text-success fw-semibold fs-16 d-flex align-items-center">
                            <i className="ti ti-discount-2 fs-18 me-1"></i>Hỗ
                            trợ khi mua
                          </span>
                        </div>
                      </li>
                      {dataDetail.listSupport.map((item, index) => {
                        return (
                          <li className="list-group-item" key={index}>
                            <div className="fs-16 text-default">
                              <span className="flex-fill">{item}</span>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                  <div className="mb-4">
                    <p className="mb-0 fw-semibold">Tiêu chí:</p>
                    <p className="fs-16 text-muted">
                      Chúng tôi không ngừng lỗ lực phát triển hệ thống cũng
                      nhưng xây dựng những phần mềm, giúp người dùng chuyển đổi
                      số nhanh nhất và đơn giản nhất có thể.
                    </p>
                  </div>
                  {dataDetail.item.tips && (
                    <div
                      className="alert alert-primary mb-4 fs-15"
                      role="alert"
                    >
                      Tips: {dataDetail.item.tips}
                    </div>
                  )}

                  <div className="d-grid">
                    {/* {checkKichhoat.current.length > 0 &&
                      dataDetail.item.path &&
                      dataDetail.arrActive.length > 0 && (
                        <ButtonKichhoatPersonalPage
                          socketio={socketio}
                          dataUser={dataUser}
                          objectShops={dataDetail.item}
                          arrActive={dataDetail.arrActive}
                        />
                      )} */}
                    {!paymentProduct && !alertSuccess && (
                      <button
                        className="btn mb-2 mt-1 fw-5 d-flex align-items-center justify-content-center gap-1 fs-18"
                        onClick={() => handlePaymentProduct(true)}
                        style={{ background: "#ff602deb", color: "white" }}
                      >
                        {/* <LuShoppingCart className="fs-18" /> */}
                        Đặt mua
                      </button>
                    )}
                    {paymentProduct && (
                      <button
                        className="btn btn-secondary mb-2 fw-5"
                        onClick={() => handlePaymentProduct(false)}
                      >
                        Hủy
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {/* *Thông tin giảm giá, đặt hàng */}

              {/* Thông tin thanh toán đặt mua */}
              {paymentProduct && (
                <div className="col-12">
                  <FormPaymentProduct
                    socketio={socketio}
                    dataUser={dataUser}
                    dataDetail={dataDetail}
                    result={handlePaymentProductSuccess}
                  />
                </div>
              )}
              {/* *Thông tin thanh toán đặt mua */}
            </div>
          </div>
        </div>
      </div>
      {/* Thông báo đặt mua thành công. */}
      {alertSuccess && <AlertSuccess activeAlert={true} />}
    </div>
  )
}

// xử lý khi người dùng kích hoạt phần mềm vào hệ thống
function ButtonKichhoatPersonalPage({ socketio, dataUser, objectShops, arrActive }) {
  const [statusActive, setStatusActive] = useState(arrActive.length > 0 ? true : "")
  const [activeMenu, setActiveMenu] = useState(arrActive.length > 0 ? arrActive[0].active_menu : 0)
  // Quan ly kich hoat.
  const handleKhinguoidungKichhoat = (status_)=> {
    // Kiem tra user co ton tai khong.
    if (dataUser.length > 0) {
      // Kiểm tra xem dữ liệu có tồn tại không trước khi thêm mới.
      const paramsCheck = {
        iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
        object: {
          shops_id: objectShops.id,
          tech_user_id: dataUser.length > 0 ? dataUser[0].id : 0,
        },
      }
      socketio.emit("client-check-data-tech-personal-page-menu", paramsCheck)
      socketio.on("server-check-data-tech-personal-page-menu", (data) => {
        if (data.length === 0 && status_ === "active") {
          // Nếu chưa tồn tại dữ liệu (xử lý thêm mới)
          const params = {
            iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
            object: {
              shops_id: objectShops.id,
              icon_menu: objectShops.icon_menu,
              title: objectShops.tenduan,
              path: objectShops.path,
              tech_user_id: dataUser[0].id,
              active_menu: 1,
            },
          }
          socketio.emit("client-create-data-tech-personal-page-menu", params)
          socketio.on("server-create-data-tech-personal-page-menu", (data) => {
            if (data === "success") {
              setStatusActive(true)
              alert("Kích hoạt thành công, hãy vào trang cá nhân để trải nghiệm.")
            }
          })
        } else if (data.length > 0 && status_ === "active") {
          // Nếu đã tồn tại (xử lý edit kích hoạt)
          const paramsEdit = {
            iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
            object: {
              tech_user_id: dataUser[0].id,
              id: data[0].id,
              active_menu: 1,
              addtime: moment().format("YYYY-MM-DD HH:mm:ss"),
            },
          }
          socketio.emit(
            "client-edit-row-activemenu-tech-personal-page-menu",
            paramsEdit,
          )
          socketio.on(
            "server-edit-row-activemenu-tech-personal-page-menu",
            (data) => {
              if (data === "success") {
                setStatusActive(true)
                alert("Kích hoạt thành công, danh mục xuất hiện thanh menu bên trái.")
              }
            },
          )
        } else if (data.length > 0 && status_ === "cancel") {
          // Nếu đã tồn tại (xử lý edit hủy kích hoạt)
          let checkCancel = window.confirm("Bạn muốn hủy kích hoạt module này?")
          if (checkCancel) {
            const paramsEdit = {
              iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
              object: {
                tech_user_id: dataUser[0].id,
                id: data[0].id,
                active_menu: 0,
                addtime: moment().format("YYYY-MM-DD HH:mm:ss"),
              },
            }
            socketio.emit(
              "client-edit-row-activemenu-tech-personal-page-menu",
              paramsEdit,
            )
            socketio.on(
              "server-edit-row-activemenu-tech-personal-page-menu",
              (data) => {
                if (data === "success") {
                  setStatusActive(false)
                  setActiveMenu(0)
                }
              },
            )
          }
        }
        
      })
    } else {
      alert("Bạn chưa đăng ký tài khoản.")
    }
    
  }
  useEffect(() => {

    return () => {
      socketio.off("client-check-data-tech-personal-page-menu")
      socketio.off("server-check-data-tech-personal-page-menu")

      socketio.off("client-create-data-tech-personal-page-menu")
      socketio.off("server-create-data-tech-personal-page-menu")

      socketio.off("client-edit-row-activemenu-tech-personal-page-menu")
      socketio.off("server-edit-row-activemenu-tech-personal-page-menu")

      
    }
  }, [statusActive])
  

  // Kiểm tra user đã kích hoạt vào hệ thống lần nào chưa
  if (arrActive.length === 0) {
    // Kiểm tra user đăng ký thành viên còn hạn hay không.
    if (dataUser.length > 0) {
      let dateEnd = moment(dataUser[0].date_end);
      let currentTime = moment();
      let daysDiff = dateEnd.diff(currentTime, "days");
      if (Number(daysDiff) >= 0 && objectShops.path) {
        return (
          <>
            {!statusActive ? (
              <button
                className="btn btn-success-light btn-wave fw-5 rounded-3"
                onClick={() => handleKhinguoidungKichhoat("active")}
              >
                Kích hoạt
              </button>
            ) : (
              <button
                className="btn btn-danger-light text-danger fw-5 rounded-3"
                onClick={() => handleKhinguoidungKichhoat("cancel")}
              >
                Hủy kích hoạt
              </button>
            )}
          </>
        )
      } else {
        // Kiểm tra những tài khoản nào mới được kích hoạt.
        let checkPath = objectShops.path;
        let arrRoleActive = objectShops.pricing_duockichhoat.split(",");
        let checkRoleActive = arrRoleActive.filter((item) => Number(item) === dataUser[0].pricing_id);
        if (checkPath && checkRoleActive.length > 0) {
          return (
            <>
              {!statusActive ? (
                <button
                  className="btn btn-success-light btn-wave fw-5 rounded-3"
                  onClick={() => handleKhinguoidungKichhoat("active")}
                >
                  Kích hoạt
                </button>
              ) : (
                <button
                  className="btn btn-danger-light text-danger fw-5 rounded-3"
                  onClick={() => handleKhinguoidungKichhoat("cancel")}
                >
                  Hủy kích hoạt
                </button>
              )}
            </>
          )
        } else {
          return
        }
      }
    } else {
      // Nếu chưa có tài khoản
      return (
        <button
          className="btn btn-success-light btn-wave fw-5"
          onClick={() => handleKhinguoidungKichhoat("active")}
        >
          Kích hoạt
        </button>
      )
    }
    
  } else {
    // Nếu user đã được kích hoạt 1 lần rồi.
    let dateEnd = moment(dataUser[0].date_end)
    let currentTime = moment()
    let daysDiff = dateEnd.diff(currentTime, "days");
    if (Number(daysDiff) >= 0) {
      return (
        <>
          {!statusActive && activeMenu === 0 ? (
            <button
              className="btn btn-success-light btn-wave fw-5"
              onClick={() => handleKhinguoidungKichhoat("active")}
            >
              Kích hoạt
            </button>
          ) : (
            <button
              className="btn btn-danger-light text-danger fw-5"
              onClick={() => handleKhinguoidungKichhoat("cancel")}
            >
              Hủy kích hoạt
            </button>
          )}
        </>
      )
    } else {
      // Kiểm tra những tài khoản nào mới được kích hoạt.
        let checkPath = objectShops.path;
        let arrRoleActive = objectShops.pricing_duockichhoat.split(",");
        let checkRoleActive = arrRoleActive.filter((item) => Number(item) === dataUser[0].pricing_id);
      if (checkPath && checkRoleActive.length > 0) {
        return (
          <>
            {!statusActive ? (
              <button
                className="btn btn-success-light btn-wave fw-5"
                onClick={() => handleKhinguoidungKichhoat("active")}
              >
                Kích hoạt
              </button>
            ) : (
              <button
                className="btn btn-danger-light text-danger fw-5"
                onClick={() => handleKhinguoidungKichhoat("cancel")}
              >
                Hủy kích hoạt
              </button>
            )}
          </>
        )
      } else {
        return
      }
    }
  }
  
}
