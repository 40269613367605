import media69 from "../images/svg/media-69.svg"
import media80 from "../images/svg/media-80.svg"
import media81 from "../images/svg/media-81.svg"
import media83 from "../images/svg/media-83.svg"
import media86 from "../images/svg/media-86.svg"
import media87 from "../images/svg/media-87.svg"
import media88 from "../images/svg/media-88.svg"
// danh cho landing page.
const imageLandingPage = {
  media_69: media69,
  media_80: media80,
  media_81: media81,
  media_83: media83,
  media_86: media86,
  media_87: media87,
  media_88: media88,
}

// lưu ý: nếu fle có đuôi svg thêm .default
const images = {
    noImage: require("../../assets/images/default-avatar.jpeg"),
    noImagePro: require("./no-images.gif"),
    view360: require("./ad-view360.jpg"),
    bannerKM: require("./banner-km.png"),
    notifi: require("./loa.png"),
    bannerHeight: require("./test.png"),
    bannerPrView360: require("./banner-view.png"), 
    user: require("./user.png"),
    bgImage: require("./1785.png")
}

const imageLogo = {
    logo: require("./view360gif.gif")
    
}

const imagePay = {
    qr: require("./qr-pay.jpg")
}

// Danh cho danh mục
const imagesCate = {
    product: require("./add-item.png"),
    calendar: require("./calendar.png"),
    customer: require("./human.png"),
    wallet: require("./wallet.png"),
    love: require("./love.png")
}

// Payment.
const paymentAmount = {
    payment_amount: require("../images/QR-code/qr-code-payment.jpg")
}

// chat bot.
const avatarChatBot = {
    bee: require("./bee.png")
}



export {images, imageLogo, imagePay, imagesCate, imageLandingPage, paymentAmount, avatarChatBot}