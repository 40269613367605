import { useEffect, useState } from "react"
import { FaMicrophoneAlt } from "react-icons/fa"

// Chuyển giọng nói thành văn bản
const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition

function SpeechToText(props) {
    const {width, height, sizeIcon, className, resultText} = props
  const [transcript, setTranscript] = useState("")
  const [isListening, setIsListening] = useState(false)

    useEffect(() => {
    //   Khởi tạo chức năng nắng nghe
    const recognition = new SpeechRecognition()
    recognition.lang = "vi-VN" // Thiết lập ngôn ngữ là tiếng Việt
    recognition.interimResults = false // hiển thị ngay kết quả người dùng đang nói thì đặt thành true
    recognition.maxAlternatives = 1 // số lượng kết quả trả về khi người dùng nói

    //   Phản hồi khi bấm nút bắt đầu
    recognition.onstart = () => {
    //   console.log("Bắt đầu nghe...")
    }

    // Kết quả khi nhận được thông tin đầu vào
    recognition.onresult = (event) => {
      const speechResult = event.results[0][0].transcript
      setTranscript(speechResult)
        //   console.log("Kết quả: ", speechResult)
        resultText(speechResult)
    }

    // Kết quả khi không nhận được câu hỏi nào
    recognition.onerror = (event) => {
        //   console.error("Lỗi nhận dạng giọng nói: ", event.error)
        resultText("")
    }

    // Dừng nghe
    recognition.onend = () => {
      setIsListening(false)
    //   console.log("Dừng nghe.")
    }

    //   Điều kiện nắng nghe or dừng nghe
    if (isListening) {
      recognition.start()
    } else {
      recognition.stop()
    }

    // Làm mới sự kiện nắng nghe
    return () => {
      recognition.stop()
    }
  }, [isListening])

  return (
    <div>
      {/* <h2>Nhận dạng giọng nói</h2> */}
      <button
        onClick={() => setIsListening((prev) => !prev)}
        className={"border-0 " + className}
        style={{
          width: width ? width : "50px",
          height: height ? height : "50px",
        }}
      >
        {isListening ? (
          <FaMicrophoneAlt
            className="text-danger"
            style={{ fontSize: sizeIcon ? sizeIcon : "21px" }}
          />
        ) : (
          <FaMicrophoneAlt
            className="text-muted"
            style={{ fontSize: sizeIcon ? sizeIcon : "21px" }}
          />
        )}
      </button>
      {/* <p>Kết quả: {transcript}</p> */}
    </div>
  )
}
 export default SpeechToText
