import classNames from "classnames/bind"
import styles from "../../../Admin.module.scss"
import ListDonhang from "../../../Component/Cate-donhang/ListDonhang"
import StatisticalDonhang from "../../../Component/Cate-donhang/StatisticalDonhang"
import { useState } from "react"
const cx = classNames.bind(styles)

// Trang quản lý đơn hàng theo yêu cầu khách hàng
function AdminDonhangPage(props) {
  const [dataDetail, setDataDetail] = useState([])
  const handleResultData = (data) => {
    setDataDetail([...data]);
  }
  return (
    <div className="wrapper_ground">
      <div
        className={cx("list-user", "list_items")}
        style={{ maxWidth: "33%" }}
      >
        <ListDonhang {...props} resultData={handleResultData} />
      </div>
      <div className={cx("list_items")} style={{ maxWidth: "67%" }}>
        {dataDetail.length > 0 && (
          <StatisticalDonhang {...props} dataDetail={dataDetail} />
        )}
      </div>
    </div>
  )
}
export default AdminDonhangPage
