import moment from "moment"
import { useLayoutEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import ThueThuNhapCaNhan from "../../../ThueThunhapcanhan/ThueThuNhapCaNhan"

function CheckRoleUser(props) {
  const { socketio, dataUser, dataMenu, path } = props.objectDataRole
  const navigate = useNavigate()

  useLayoutEffect(() => {
    // set tai khoan con han khong.
    let dateEnd = moment(dataUser[0].date_end)
    let currentTime = moment()
    let daysDiff = Number(dateEnd.diff(currentTime, "days"))
    // check path for page current
    let checkPath = dataMenu.filter((item) => item.path === path)
    if (daysDiff < 0) {
      // Chuyển sang trang đăng ký dịch vụ
      alert("Tài khoản của bạn đã hết hạn. Hãy gia hạn để tiếp tục sử dụng.")
      navigate("/pricing")
      
    } else {
      if (checkPath.length === 0) {
        alert("Bạn chưa kích hoạt ứng dụng này.")
        navigate("/shops")
      } else {
        const paramsProduct = {
          iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
          object: {
            id: checkPath[0].shops_id,
          },
        }
        socketio.emit("client-get-data-id-tech-shops", paramsProduct)
        socketio.on("server-get-data-id-tech-shops", (data) => {
          if (data.length > 0) {
            let arrActive = data[0].pricing_duockichhoat.split(",")
            let checkRoleUser = arrActive.filter(
              (item) => Number(item) === Number(dataUser[0].pricing_id),
            )
            if (checkRoleUser.length > 0) {
              // trường hợp được phép truy cập trang này.
              
              
            } else {
              // Chuyển về trang đăng ký gói dịch vụ khi tài khoản chưa đủ quyền truy cập
              navigate("/pricing")
            }
          } else {
            // trường hợp bị xóa sản phẩm (dự phòng)
            alert("Ứng dụng đã bị xóa khỏi của hàng.")
            navigate("/shops")
          }
        })
      }
    }
    return () => {
      socketio.off("client-get-data-id-tech-shops")
      socketio.off("server-get-data-id-tech-shops")
    }
  }, [])

  return (
    <>
      {/* Những trang đã được kiểm tra quyền truy cập */}
      {path === "/thue-tncn" && <ThueThuNhapCaNhan socketio={socketio} dataUser={dataUser} />}
    </>
  );
}

export default CheckRoleUser