import { Link, useLocation } from "react-router-dom"
import Tippy from "@tippyjs/react"
import "tippy.js/dist/tippy.css"

import classNames from "classnames/bind";
import styles from "./Sidebar.module.scss"
import { BsShop } from "react-icons/bs"
import { imageLogo } from "../../assets/images"
import { HiOutlineTicket } from "react-icons/hi2"
import { AiOutlineInfoCircle } from "react-icons/ai"
import { PiShoppingBagOpenDuotone } from "react-icons/pi";
import { IoIosArrowForward } from "react-icons/io";
const cx = classNames.bind(styles);

// Navabar pc.
function SideMenuPC({ data, resultStatus }) {
  const path = useLocation()
  const pathName = path.pathname

  const listSide = [
    { icon: <PiShoppingBagOpenDuotone />, name: "Cửa hàng", path: "/shops" },
    { icon: <HiOutlineTicket />, name: "Gói dịch vụ", path: "/pricing" },
    { icon: <AiOutlineInfoCircle />, name: "Trang chủ", path: "/" },

    // { icon: <PiHandshake />, name: "Hợp tác", path: "/" },
    // { icon: <IoSettingsOutline />, name: "Cài đặt", path: "/setting" },
  ]
  const menuSide = data ? data : listSide

  // Quản lý mở rộng side menu.
  const handleMoreSideMenu = () => {
    resultStatus("compact")
  }
  return (
    <div className="sideMenu-more">
      <div className="wrapper-bg rounded-3 position-relative">
        <ul className="listItem-sideMenu">
          <li className="d-flex flex-direction-column align-items-center mt-3 mb-3">
            <Link to="/">
              <span className="avatar avatar-lg avatar-rounded bg-logo">
                <img src={imageLogo.logo} alt="LOGO" />
              </span>
            </Link>
          </li>
          {menuSide.map((item, index) => {
            return (
              <Tippy key={index} content={item.name} placement="right">
                <li>
                  <Link
                    to={item.path}
                    className={cx(
                      "d-flex flex-direction-column align-items-center p-2",
                      pathName === item.path
                        ? "activeMenu"
                        : "text-muted menuSidebar",
                    )}
                  >
                    <span className={cx("fs-26 d-flex align-items-center")}>
                      {item.icon}
                    </span>
                  </Link>
                </li>
              </Tippy>
            )
          })}
          {/* Chat */}
          {/* <li className="d-flex flex-direction-column align-items-center">
              <button
                className="border-0 bg-transparent text-muted p-2"
                onClick={handleShowSupport}
              >
                <span className={cx("fs-26 d-flex align-items-center")}>
                  <IoChatbubbleEllipsesOutline />
                </span>
              </button>
            </li> */}
        </ul>
        {/* button thu gọn */}
        <div className="text-center mb-3">
          <Tippy content="Thu gọn" placement="right">
            <button
              className="border-0 rounded-3"
              style={{ width: "35px", height: "35px" }}
              onClick={handleMoreSideMenu}
            >
              <IoIosArrowForward />
            </button>
          </Tippy>
        </div>
      </div>
    </div>
  )
}
export default SideMenuPC
