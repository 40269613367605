import { Link, useNavigate } from "react-router-dom";
import { imageLogo } from "../../assets/images";
import { useForm } from "react-hook-form";
import { useContext, useEffect, useRef } from "react";
import AppContext from "../../handle-reuses/Contexts/CreateContexts";


function RegisterPage() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { socketio } = useContext(AppContext);
    const navigate = useNavigate()
    // Xử lý submit
    const changeRegister = useRef()
    const handleOnSubmit = (object) => {
        if (object.checkService) {
            delete object.checkService
            // Kiểm tra xem tài khoản đã tồn tại hay không.
            const paramsCheck = {
                iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
                object: {
                    phone: object.phone,
                    email: object.email
                }
            };
            socketio.emit("client-check-data-tech-user", paramsCheck)
            socketio.on("server-check-data-tech-user", (data) => {
                if (data.length === 0) {
                    // Xử lý thêm mới
                    const paramsCreate = {
                        iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
                        object: object
                    }
                    socketio.emit("client-create-data-tech-user", paramsCreate)
                    socketio.on("server-create-data-tech-user", (status_) => {
                        if (status_ === "success") {
                            alert("Đăng ký thành công.")
                            navigate("/login")
                            changeRegister.current = object
                        }
                    })
                } else {
                    alert("Tài khoản đã tồn tại.")
                    changeRegister.current = object
                }
            })
        } else {
            alert("Bạn cần đồng ý điều khoản.")
        }
    }

    useEffect(() => {
        return () => {
            socketio.off("client-check-data-tech-user") 
            socketio.off("server-check-data-tech-user")

            socketio.off("client-create-data-tech-user")
            socketio.off("server-create-data-tech-user")
        }
    }, [changeRegister.current])
    return (
        <div className="login-3 tab-box">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 col-md-12 bg-img">
                        <div className="informeson">
                            <div className="typing">
                                <h1>Welcome view 360</h1>
                            </div>
                            <p>
                                Đến với view 360 chúng tôi cung cấp giải pháp chuyển đổi số
                                theo từng mảng, từng lĩnh vực, nhằm mục đích đơn giản những
                                công việc sổ sách thường ngày. Việc chuyển đổi số chỉ danh cho
                                khách hàng hướng tới sự chuyên nghiệp, quản lý vận hành tự
                                động.
                            </p>
                            <div className="social-list">
                                <div className="buttons">
                                    <a href="#" className="facebook-bg">
                                        <i className="fa fa-facebook"></i>
                                    </a>
                                    <a href="#" className="twitter-bg">
                                        <i className="fa fa-twitter"></i>
                                    </a>
                                    <a href="#" className="google-bg">
                                        <i className="fa fa-google"></i>
                                    </a>
                                    <a href="#" className="dribbble-bg">
                                        <i className="fa fa-linkedin"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 form-section">
                        <div className="login-inner-form">
                            <div className="details">
                                <Link to="/">
                                    <span className="avatar avatar-lg bg-danger rounded-5">
                                        <img src={imageLogo.logo} alt="logo" />
                                    </span>
                                </Link>
                                <h3>Đăng ký tài khoản</h3>
                                <form onSubmit={handleSubmit(handleOnSubmit)}>
                                    <div className="form-group form-box">
                                        <input
                                            type="text"
                                            className="form-control rounded-5"
                                            placeholder="Họ tên"
                                            {...register("username", { required: true })}
                                        />
                                        {errors.username && (
                                            <span className="text-danger fs-14">
                                                Bạn cần nhập họ tên của mình.
                                            </span>
                                        )}
                                    </div>
                                    <div className="form-group form-box">
                                        <input
                                            type="text"
                                            className="form-control rounded-5"
                                            placeholder="Số điện thoại"
                                            {...register("phone", {required: true})}
                                        />
                                        {errors.phone && (
                                            <span className="text-danger fs-14">
                                                Bạn cần nhập số điện thoại của mình.
                                            </span>
                                        )}
                                    </div>
                                    <div className="form-group form-box">
                                        <input
                                            type="email"
                                            className="form-control rounded-5"
                                            placeholder="Địa chỉ email"
                                            {...register("email", {required: true})}
                                        />
                                        {errors.email && (
                                            <span className="text-danger fs-14">
                                                Bạn cần nhập email của mình.
                                            </span>
                                        )}
                                    </div>
                                    <div className="form-group form-box">
                                        <input
                                            type="password"
                                            className="form-control rounded-5"
                                            placeholder="Password"
                                            {...register("passwords", {required: true})}
                                        />
                                        {errors.passwords && (
                                            <span className="text-danger fs-14">
                                                Bạn cần nhập mật khẩu của mình.
                                            </span>
                                        )}
                                    </div>
                                    <div className="form-group form-box checkbox clearfix">
                                        <div className="form-check checkbox-theme">
                                            <input
                                                className="form-check-input rounded-5"
                                                type="checkbox"
                                                value=""
                                                id="termsOfService"
                                                {...register("checkService")}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="termsOfService"
                                            >
                                                Chấp nhận điều khoản
                                                <button className="border-0 terms">
                                                    Của view 360
                                                </button>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button
                                            type="submit"
                                            className="btn-md btn-theme w-100 rounded-5"
                                        >
                                            Đăng ký
                                        </button>
                                    </div>
                                    <p>
                                        Bạn đã là thành viên?<Link to="/login"> Đăng nhập</Link>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default RegisterPage