import { useState } from "react"
import ListDonhang from "../../Admin/Component/Cate-donhang/ListDonhang"
import StatisticalDonhang from "../../Admin/Component/Cate-donhang/StatisticalDonhang"

function ListOrderPage(props) {
    const { socketio, dataUser, dataOrderNew } = props
    const [arrListFunction, setArrListFunction] = useState([])
  // Quản lý xem chi tiết đơn hàng.
  const handleResultDataFunctions = (dataFunctions) => {
      setArrListFunction(dataFunctions)
  }
    return (
      <div className="wrapper_ground">
        <div
          className="col-3 heightScroll list_items"
          style={{ height: "calc(100vh - 11.5rem)" }}
        >
          <ListDonhang
            socketio={socketio}
            dataUser={dataUser}
            dataOrderNew={dataOrderNew}
            resultData={handleResultDataFunctions}
          />
        </div>
        <div className="col-7 list_items px-1">
          {arrListFunction.length > 0 && (
            <StatisticalDonhang {...props} dataDetail={arrListFunction} />
          )}
        </div>
      </div>
    )
}
export default ListOrderPage