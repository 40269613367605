import moment from "moment";
import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { paymentAmount } from "../../../assets/images";



function FormPaymentProduct({socketio, dataUser, dataDetail, result}) {
    const { register, handleSubmit, formState: { errors } } = useForm()
    
  // Xử lý format phone.
  const [formatPhone, setFormatPhone] = useState(dataUser.length > 0 ? dataUser[0].phone : "");
  const handleFormatPhone = (e) => {
    let valuePhone = Number(e.target.value);
    if (Number(valuePhone) >= 0) {
      if (e.target.value.length <= 10) {
        setFormatPhone(e.target.value)
      } else {
        alert("Định dạng số điện thoại là 10 số.")
      }
    } else {
      alert("Bạn cần nhập dạng số.")
    }
  }

  // set số tiền cần thanh toán.
  const [paymetAmount, setPaymentAmount] = useState(0);
  useEffect(() => {
    // set payment_amount.
    if (dataUser.length > 0) {
      if (dataUser[0].date_end) {
        let dateEnd = moment(dataUser[0].date_end)
        let currentTime = moment()
        let timeDiscount = moment(dataDetail.item.thoigian_khuyenmai)
        let daysDiff = dateEnd.diff(currentTime, "days")
        let timeDiscountDiff = timeDiscount.diff(currentTime, "days");
        if (Number(daysDiff) >= 0) {
          // Ưu đãi khi là thành viên
          let membershipDiscount =
            Number(dataDetail.item.giaban) *
            (Number(dataUser[0].pricing_discount) / 100)
          let totalPaymentAmount;
          if (Number(timeDiscountDiff) >= 0) {
            totalPaymentAmount = dataDetail.giabanThucte - membershipDiscount
          } else {
            totalPaymentAmount = Number(dataDetail.item.giaban) - membershipDiscount
          }
          setPaymentAmount(totalPaymentAmount)
        } else {
          // Đã hết hạn thành viên.
          let totalPaymentAmount = dataDetail.giabanThucte
          setPaymentAmount(totalPaymentAmount)
        }
      } else {
        // Nếu chưa là thành viên lần nào.
        let totalPaymentAmount = dataDetail.giabanThucte
        setPaymentAmount(totalPaymentAmount)
      }
    } else {
      let totalPaymentAmount = dataDetail.giabanThucte
      setPaymentAmount(totalPaymentAmount)
    }
  }, [])

  // Xử lý submit.
  const changeSubmitSuccess = useRef()
    const handleOnSubmit = (object) => {
      object.shops_id = dataDetail.item.id;
      object.tech_user_id = dataUser.length > 0 ? dataUser[0].id : 0
      object.payment_amount = paymetAmount

      
      const paramsPayment = {
        iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
        object: object
      }
      socketio.emit("client-create-data-tech-shops-order", paramsPayment)
      socketio.on("server-create-data-tech-shops-order", (data) => {
        if (data === "success") {
          result(true)
          changeSubmitSuccess.current = object
        }
      })
    }
  useEffect(() => {
    return () => {
      socketio.off("client-create-data-tech-shops-order")
      socketio.off("server-create-data-tech-shops-order")
    }
  }, [changeSubmitSuccess.current]);

    return (
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="border-top border-block-start-dashed mb-3"></div>
        <div className="text-center mb-3 fw-5 fs-18">
          Thông tin đăng ký đặt mua
          <p className="mb-0 text-primary">Số tiền cần thanh toán là: {paymetAmount.toLocaleString().replace(/[.]/g, ",")}đ</p>
          <p className="mb-0 text-danger fs-14">
            Lưu ý: khách hàng ghi rõ{" "}
            <span className="fs-16 text-secondary">số điện thoại</span> vào nội
            dùng chuyển khoản
          </p>
        </div>
        <div className="col-xl-12 col-md-12">
          <div className="row">
            <div className="form-floating mb-3 col-12">
              <input
                type="text"
                className="form-control"
                placeholder="Tên input"
                {...register("customer_name", { required: true })}
                defaultValue={dataUser.length > 0 ? dataUser[0].username : ""}
              />
              <label className="fs-15 ms-2 text-muted">
                Họ tên <span className="text-danger">*</span>
              </label>
              {errors.customer_name && (
                <span className="text-danger fs-14">
                  Bạn cần nhập họ tên của mình.
                </span>
              )}
            </div>

            <div className="form-floating mb-3 col-12">
              <input
                type="text"
                className="form-control"
                placeholder="Tên input"
                {...register("customer_phone", { required: true })}
                value={formatPhone}
                onChange={handleFormatPhone}
              />
              <label className="fs-15 ms-2 text-muted">
                Số điện thoại <span className="text-danger">*</span>
              </label>
              {errors.customer_phone && (
                <span className="text-danger fs-14">
                  Bạn cần nhập số điện thoại.
                </span>
              )}
            </div>
            <div className="form-floating mb-3 col-12">
              <input
                type="email"
                className="form-control"
                placeholder="Tên input"
                {...register("customer_email", { required: true })}
                defaultValue={dataUser.length > 0 ? dataUser[0].email : ""}
              />
              <label className="fs-15 ms-2 text-muted">
                Email <span className="text-danger">*</span>
              </label>
              {errors.customer_email && (
                <span className="text-danger fs-14">
                  Bạn cần nhập email.
                </span>
              )}
            </div>
          </div>
        </div>
        {/* Ghi chú */}
        <div className="form-floating mb-4">
          <textarea
            className="form-control"
            placeholder="Nhập ghi chú"
            style={{ height: "100px" }}
            {...register("note")}
          ></textarea>
          <label className="fs-15 text-muted">Ghi chú (không bắt buộc)</label>
        </div>
        {/* mã QR thanh toán */}
        <div>
          <img
            src={paymentAmount.payment_amount}
            width="100%"
            alt="payment"
          />
        </div>
        {/* Button */}
        <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
          <button
            className="btn btn-primary-light m-1 fw-5 fs-21"
            type="submit"
          >
            ĐẶT MUA
          </button>
        </div>
      </form>
    )
}
export default FormPaymentProduct