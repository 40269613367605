import { useContext } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import {routerPublic, routerRole, routerRoleAdmin} from "./Router";
import "bootstrap/dist/css/bootstrap.min.css" // Import stylesheet
import "./assets/style/style.scss"
import "./assets/style/customer.scss"
import "./assets/style/login.scss"
import "./assets/style/styleScreen.scss"
import "./assets//style/layout.scss"
import "moment/locale/vi"
import "react-toastify/dist/ReactToastify.css"
import { isAuthen } from "./Router/CheckUser"
import AppContext from "./handle-reuses/Contexts/CreateContexts";



function App() {
  const { dataUser } = useContext(AppContext);
  return (
    <Router>
      <Routes>
        {/* Router public */}
        {routerPublic.map((item, index) => {
          const RouterPublic = item.component;
          return <Route key={index} path={item.path} element={<RouterPublic />} />
        })}
        {/* router role */}
        {routerRole.map((item, index) => {
          const RouterRole = item.component;
          return (
            <Route
              key={index}
              path={item.path}
              element={
                isAuthen ? <RouterRole /> : <Navigate to="/register" />
              }
            />
          )
        })}
        {/* Router role admin */}
        {routerRoleAdmin.map((item, index) => {
          const RouterRoleAdmin = item.component;
          return (
            <Route key={index} path={item.path} element={isAuthen && dataUser[0].role_token === process.env.REACT_APP_ROLE_ADMIN ? <RouterRoleAdmin /> : <Navigate to="/" />} />
          )
        })}
      </Routes>
    </Router>
  );
}

export default App;
