

import { useRef, useState } from "react"
import { TbCalendarEvent, TbCalendarSearch } from "react-icons/tb"
import { RiSearch2Line } from "react-icons/ri"
import moment from "moment"
import DateDay from "../../../../Component/Date/DateDay"
import DateMonth from "../../../../Component/Date/DateMonth"
import SearchHorizontal from "../../../../Component/Search/SearchHorizontal"


function SearchDonhang({ resultSearch }) {
  const [active, setActive] = useState("text-search")
  const objectSearch = useRef({
    monthSearch: "",
    textSearch: "",
  })

  // Search days.
  const [valueDays, setValueDays] = useState(moment().format("YYYY-MM"))
  const handleValueDays = (value) => {
    // setValueDays(value)
    objectSearch.current = {
      monthSearch: objectSearch.current.monthSearch,
      textSearch: objectSearch.current.textSearch,
    }
    resultSearch(objectSearch.current)
  }

  // Search month
  const [valueMonth, setValueMonth] = useState("")
  const handleValueMonth = (value) => {
    objectSearch.current = {
      monthSearch: value
        ? moment(value).format("YYYY-MM")
        : moment().format("YYYY-MM"),
      textSearch: objectSearch.current.textSearch,
    }
    resultSearch(objectSearch.current)
  }

  // search text
  const handleValueSearchText = (value) => {
    objectSearch.current = {
      daysSearch: objectSearch.current.daysSearch,
      monthSearch: objectSearch.current.monthSearch,
      textSearch: value,
    }
    resultSearch(objectSearch.current)
  }

  // Quản lý active search.
  const handleActiveSearch = (cate) => {
    setActive(cate)
  }
  return (
    <div className="wrapper_ground col-12">
      {/* Tìm theo ngày */}
      {/* {active === "days-search" ? (
        <div className="list_items col-6">
          <DateDay
            result={handleValueDays}
            placeholderText="Tìm theo ngày"
            className="rounded-3"
            style={{ border: "2px solid #ff661d94", height: "39px" }}
          />
        </div>
      ) : (
        <button
          className="rounded-3 p-0 btn btn-icon btn-primary-transparent btn-wave text-muted search-data"
          onClick={() => handleActiveSearch("days-search")}
          style={{
            border: "2px solid #ff661d94",
            width: "44.5px",
            height: "44.5px",
          }}
        >
          <TbCalendarEvent className="fs-21 icon-svg" />
        </button>
      )} */}

      {/* Tìm kiếm theo tháng */}
      {active !== "month-search" && (
        <button
          onClick={() => handleActiveSearch("month-search")}
          className="rounded-4 p-0 btn btn-icon btn-wave text-muted search-data"
          style={{
            border: "2px solid rgb(244 237 234)",
            width: "44.5px",
            height: "44.5px",
          }}
        >
          <TbCalendarSearch className="fs-21 icon-svg" />
        </button>
      )}
      {active === "month-search" && (
        <div className="list_items col-6">
          <DateMonth
            result={handleValueMonth}
            className="rounded-5"
          />
        </div>
      )}

      {/* Tìm kiếm theo text */}
      {active === "text-search" ? (
        <div className="list_items col-6">
          <SearchHorizontal
            className={`rounded-4 search-data ${
              active === "text-search" && "active-search"
            }`}
            style={{
              backgroundColor: "white",
              border: "2px solid rgb(244 237 234)",
              height: "44.5px",
              width: "100%",
            }}
            result={handleValueSearchText}
            placeholder="Tên dự án"
          />
        </div>
      ) : (
        <button
          className="rounded-4 p-0 btn btn-icon btn-wave text-muted search-data"
          onClick={() => handleActiveSearch("text-search")}
          style={{ border: "2px solid rgb(244 237 234)", height: "44.5px", width: "44.5px" }}
        >
          <RiSearch2Line className="fs-21 icon-svg" />
        </button>
      )}
    </div>
  )
}
export default SearchDonhang
