import { useState } from "react"
import MenuAdmin from "./AdminUserPage/MenuAdmin"
import AdminUserPage from "./AdminUserPage/AdminUserPage"
import { useLocation } from "react-router-dom"
import AdminDonhangPage from "./AdminDonhangPage/AdminDonhangPage";

function AdminPagePC(props) {
  const location = useLocation();
  const path = location.pathname
  return (
    <div>
      <div className="wrapper_ground">
        {/* Danh muc */}
        <div className="list_items col-12">
          <MenuAdmin />
        </div>
        {/* Content */}
        <div className="list_items col-12">
          <div
            className="rounded-4"
            style={{
              width: "100%",
              height: "calc(100vh - 12rem)",
            }}
          >
            {(path === "/role-admin/cate-user" || path === "/role-admin") && (
              <AdminUserPage />
            )}
            {(path === "/role-admin/cate-shops") && (
              <AdminDonhangPage {...props} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default AdminPagePC
