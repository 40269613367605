import { useEffect, useRef, useState } from "react";
import LoadingSpinner from "../../../Component/Loading/LoadingSpinner";
import { paymentAmount } from "../../../assets/images";
import moment from "moment";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

function PaymentTechPricing(props) {
    const { socketio, dataUser, objectChange } = props;
    const [dataPayment, setDataPayment] = useState([]);
    const [loading, setLoading] = useState(true)
    // Quan ly thanh toan.
    useEffect(() => {
        const params = {
            iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
            object: { id: objectChange.pricing_id }
        }
        socketio.emit("client-get-data-id-tech-pricing", params)
        socketio.on("server-get-data-id-tech-pricing", (data) => {
            setDataPayment([...data]);
            setLoading(false)
        })
    }, []);

    // Xử lý thanh toán.
    const checkChangePeyment = useRef()
    const [checkSendSuccess, setCheckSendSuccess] = useState(false)
    const handlePaymentAmount = () => {
        const params = {
          iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
          object: {
            tech_user_id: Number(dataUser[0].id),
            pricing_id: objectChange.pricing_id,
            payment_amount:
              objectChange.yearMonth === "years"
                ? Number(dataPayment[0].price) * 12
                : dataPayment[0].price,
            date_start: moment().format("YYYY-MM-DD HH:mm:ss"),
            date_end:
              objectChange.yearMonth === "years"
                ? moment().add(12, "months").format("YYYY-MM-DD HH:mm:ss")
                : moment().add(1, "months").format("YYYY-MM-DD HH:mm:ss"),
          },
        }
        socketio.emit("client-create-data-tech-pricing-order", params)
        socketio.on("server-create-data-tech-pricing-order", (data) => {
            if (data === "success") {
                checkChangePeyment.current = params
                setCheckSendSuccess(true)
            }
        })
    }
    useEffect(() => {
        return () => {
            socketio.off("client-create-data-tech-pricing-order")
            socketio.off("server-create-data-tech-pricing-order")
        }
    }, [checkChangePeyment.current])

    if (loading) {
        return <LoadingSpinner />
    }
    if (dataPayment.length === 0) {
        return <div className="mt-3 text-center fs-16">Chưa có gói dịch vụ.</div>
    }
    return (
      <div>
        {!checkSendSuccess && (
          <div className="text-center fs-18">
            <span>Số tiền cần thanh toán</span>
            <p className="text-primary fw-5 mb-0 fs-21">
              {dataPayment[0].title}:{" "}
              {objectChange.yearMonth === "years"
                ? (Number(dataPayment[0].price) * 12).toLocaleString().replace(/[.]/g, ",")
                : Number(dataPayment[0].price)
                    .toLocaleString()
                    .replace(/[.]/, ",")}
              đ
            </p>
            <p className="text-danger fs-16">
              Lưu ý: Quý khách hàng khi thanh toán ghi rõ{" "}
              <span className="fw-5 fs-18">số điện thoại</span> vào nội dung
              thanh toán.
            </p>
          </div>
        )}
        {!checkSendSuccess && (
          <>
            <div>
              <img
                src={paymentAmount.payment_amount}
                alt="QR Payment Amount"
                width="100%"
              />
            </div>
            {/* Button payment amount */}
            <div className="mt-3 text-center">
              <button
                type="button"
                className="btn btn-orange-light btn-wave fs-21 fw-5"
                onClick={handlePaymentAmount}
              >
                Xác nhận thanh toán
              </button>
            </div>
          </>
        )}
        {checkSendSuccess && (
          <div className="text-center fs-16">
            <IoMdCheckmarkCircleOutline
              className="text-success"
              style={{ fontSize: "86px" }}
            />
            <p className="mb-0">Cảm ơn bạn đã xác nhận thanh toán.</p>
            <p className="mb-0">
              Khoản thanh toán sẽ được kiểm duyệt sớm nhất.
            </p>
          </div>
        )}
      </div>
    )
}
export default PaymentTechPricing