import { Link, useLocation } from "react-router-dom"
import Tippy from "@tippyjs/react"
import "tippy.js/dist/tippy.css"

import { HiOutlineShoppingBag, HiOutlineTicket } from "react-icons/hi2"
import { AiOutlineInfoCircle } from "react-icons/ai"
import { PiShoppingBagOpenDuotone } from "react-icons/pi";
import { IoIosArrowForward } from "react-icons/io";
import { images } from "../../../../assets/images"
import { IoSettingsOutline } from "react-icons/io5"
import * as Iconio5 from "react-icons/io5"

// Navabar pc.
function SideMenuPC(props) {
  const {dataMenu, resultStatus} = props
  const path = useLocation()
  const pathName = path.pathname

  // Menu default
  const listMenuDefault = [
    {
      icon_menu: <PiShoppingBagOpenDuotone className="fs-25" />,
      title: "Cửa hàng",
      path: "/shops",
    },
    {
      icon_menu: <HiOutlineTicket className="fs-25" />,
      title: "Gói dịch vụ",
      path: "/pricing",
    },
    {
      icon_menu: <HiOutlineShoppingBag className="fs-21" />,
      title: "Đặt hàng",
      path: "/software-order",
    },
  ]


  // Quản lý mở rộng side menu.
  const handleMoreSideMenu = () => {
    resultStatus("compact")
  }
  return (
    <div className="sideMenu-more">
      <div className="wrapper-bg rounded-4 position-relative">
        <ul className="listItem-sideMenu">
          <li className="d-flex flex-direction-column align-items-center justify-content-center mt-3 mb-3">
            <Link to="/">
              <span className="avatar avatar-lg avatar-rounded bg-logo">
                <img src={images.user} alt="LOGO" />
              </span>
            </Link>
          </li>
          <Tippy content="Trang chủ" placement="right">
            <li>
              <Link
                to="/"
                className={`d-flex align-items-center justify-content-center p-2 gap-3 rounded-3 ${
                  pathName === "/" ? "activeMenu" : "menuSidebar"
                }`}
              >
                {/* Icon menu */}
                <span className="fs-21 d-flex align-items-center">
                  <Iconio5.IoTvOutline className="fs-25" />
                </span>
              </Link>
            </li>
          </Tippy>
          {/* Menu active từ shops */}
          {dataMenu.length > 0 &&
            dataMenu.map((item, index) => {
              // Format icon menu.
              let iconString = `${item.icon_menu}`
              // loại bỏ dấu <> / trong icon.
              let iconName = iconString.replace(/[<>/]/g, "").trim()
              // Đưa về dạng Component
              const FormatIcon = Iconio5[iconName]

              return (
                <Tippy key={index} content={item.title} placement="right">
                  <li>
                    <Link
                      to={item.path}
                      className={`d-flex flex-direction-column align-items-center justify-content-center p-2 gap-3 rounded-3 ${
                        pathName === item.path ? "activeMenu" : "menuSidebar"
                      }`}
                    >
                      {/* Icon menu */}
                      <span className="fs-21 d-flex align-items-center">
                        {<FormatIcon /> ? <FormatIcon className="fs-25" /> : ""}
                      </span>
                    </Link>
                  </li>
                </Tippy>
              )
            })}

          {/* Memu default */}
          {listMenuDefault.map((item, index) => {
            return (
              <Tippy key={index} content={item.title} placement="right">
                <li>
                  <Link
                    to={item.path}
                    className={`d-flex flex-direction-column align-items-center justify-content-center p-2 rounded-3 ${
                      pathName === item.path
                        ? "activeMenu"
                        : "text-muted menuSidebar"
                    }`}
                  >
                    <span className="fs-26 d-flex align-items-center">
                      {item.icon_menu}
                    </span>
                  </Link>
                </li>
              </Tippy>
            )
          })}
        </ul>
        {/* button mở rộng */}
        <div className="text-center mb-3">
          <Tippy content="Mở rộng" placement="right">
            <button
              className="border-0 rounded-5 text-muted p-1"
              style={{
                width: "35px",
                height: "35px",
                backgroundColor: "rgb(244 237 234) !important",
              }}
              onClick={handleMoreSideMenu}
            >
              <IoIosArrowForward />
            </button>
          </Tippy>
        </div>
      </div>
    </div>
  )
}
export default SideMenuPC
