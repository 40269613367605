import React, { useEffect, useState } from "react"
import classNames from "classnames/bind"
import moment from "moment"

import DatePicker from "react-datepicker"
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import vi from "date-fns/locale/vi"

import styles from "./Date.module.scss"
registerLocale("vi", vi)
const cx = classNames.bind(styles)

function DateDay({result, placeholderText}) {
  const [selectedDate, setSelectedDate] = useState("");
  useEffect(() => {
    result(selectedDate)
  }, [selectedDate])

  return (
    <DatePicker
      locale="vi"
      selected={selectedDate}
      onChange={(date) => setSelectedDate(date)}
      dateFormat="dd-MM-yyyy"
      showDaysMonthYearPicker // chọn ngày tháng
      isClearable // nút clear
      // showFullMonthYearPicker // hiển thị full name month
      // showFourColumnMonthYearPicker
      placeholderText={placeholderText}
      className={cx("dateInput", "col-12")}
    />
  )
}

export default DateDay
