// Xác đinh user đăng nhập 1 lần nào chưa (ký tự !! trả về true nếu sodienthoai tồn tại)
const checkLocal = localStorage.getItem("ob")
let checkUserLogin
if (checkLocal) {
  checkUserLogin = JSON.parse(localStorage.getItem("ob"))
}
const email = checkUserLogin ? checkUserLogin.email : ""
const status_ = checkUserLogin ? checkUserLogin.status : ""
let isAuthen = false;
if (!!email && !!status_) {
  isAuthen = true
}

export {isAuthen}
