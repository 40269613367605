import {
  handleMethodCreateData,
  handleMethodUploadFile,
  handleMethodEditData,
} from "./method"
import imageCompression from "browser-image-compression"


// Get tên file.
function handleSetNameFileImages(nameFile,folder) {
  // Lấy tên ảnh để thêm vào database
  let dateCurrent = new Date()
  let days_ = dateCurrent.getDate()
  let months_ = dateCurrent.getMonth() + 1
  let year_ = dateCurrent.getFullYear()
  let hours_ = dateCurrent.getHours()
  let tenphu = days_ + "-" + months_ + "-" + year_ + "-" + hours_

  let name_avatar = tenphu + "-" + nameFile
  // link avatar.
  let pathImage = `${folder}/${year_}/${name_avatar}`

  return pathImage
}



// handle add path image.
async function handleAddPathImages(arrFiles, objectInput, folder, api, result) {
  // Mảng đường dẫn
  let newImageFile = Array.from(arrFiles)

  // Thêm đường dẫn image rows
  let i = 0
  let max_ = Number(newImageFile.length)
  let stt = 1
  // Dùng đệ quy đê tạo vòng lặp
  function sortUploadImages() {
    // Điều kiện sort list images
    if (i <= max_) { // cho điều kiện i <= max_ để cho i chạy thêm 1 lần cho bằng max_ (mục đích để xác đinh đã tải hết path)
      // Điều kiện nà giúp chạy đủ số lượng path không chạy thừa
      if (i < max_) {
        // link avatar.
        let pathImage = handleSetNameFileImages(newImageFile[i].name, folder)

        // Giá trị đầu vào là 1 object với giá trị path,addtime (tùy chọn thay thế cho phù hợp)
        objectInput.path = pathImage
        objectInput.addtime = Date.now()
        objectInput.active_avatar = newImageFile[i].active_avatar
        // handle add path image
        handleMethodCreateData(objectInput, function () {}, api)
        // Trả về sau mỗi lần upload success để tạo hiệu ứng tải dữ liệu.
        handleProgressUploadData(stt++, newImageFile.length)
      }
      i++
      // Khi path được tải xong hết
      // if (i === max_) {
      //   result("success")
      // }
      setTimeout(sortUploadImages, 50)
    }
  }
  sortUploadImages()
}
/** ======= *handle add images ====== */

/** ======= Upload file ảnh lên hosting ========*/
async function handleUploadFileImage(arrFiles, api) {
  // if (!arrFiles) {
  //   alert("Bạn chưa chọn thêm ảnh: " + arrFiles)
  //   return
  // }

  // if (!newImageFile) {
  //     alert("Không tìm thấy tệp ảnh.");
  //     return;
  // }
  // Chuyển đổi FileList thành mảng
  

  // if (newImageFile.length === 0) {
  //     alert("Vui lòng chọn ít nhất một tệp ảnh.");
  //     return;
  // }

  if (arrFiles.length > 0) {
    const newImageFile = Array.from(arrFiles)
 
 
    newImageFile.forEach((file) => {
      const formData = new FormData()
      formData.append("file", file, file.name) // Thêm file vào formData với tên file gốc
      // Set kích thước image
      const options = {
        maxSizeMB: 10,
        maxWidthOrHeight: 2000,
        useWebWorker: true,
      }

      // Thực hiện nén ảnh
      imageCompression(file, options).then((compressedFile) => {
        formData.set("file", compressedFile, file.name) //thêm file sau khi được nén vào FormData()

        handleMethodUploadFile(formData, () => {}, api)
      })

      
    })
  }
}
/** ======= Upload file ảnh lên hosting ========*/


/** ======= Hiệu ứng tiến trình tải dữ liệu ====== */
function handleProgressUploadData(numberProgress, totalData) {
  let tysuat = 0;
  tysuat = (numberProgress / totalData) * 100
  let pending = '<span class="text-primary">Đang xử lý...</span>'
  let penddingSuccess = '<span class="text-success fw-5">Đã hoàn tất !</span>'

  document.querySelector("#progressUploadData").style.display = "block"
  document.querySelector("#titleProgressUpload").innerHTML =
    tysuat < 100 ? pending : penddingSuccess
  document.querySelector("#progressSuccess").style.width = Number.isInteger(tysuat) ? tysuat + "%" : tysuat.toFixed() + "%";
  document.querySelector("#progressSuccess").innerHTML = `
    ${Number.isInteger(tysuat) ? tysuat + "%" : tysuat.toFixed() + "%"}
  `
  if (tysuat === 100) {
    // Thêm class success khi hoàn tất
    document.querySelector("#progressSuccess").classList.add("bg-success")
  }

}
/** ======= *Hiệu ứng tiến trình tải dữ liệu ====== */


/** ======= Xử lý sắp xếp file images ===========*/
/**
 * 
 * 
 * @param {*} arrFiles mảng files đầu vào của images
 * @param {*} listImage class list images edit.
 * @param {*} apiEditPath api edit images
 * @param {*} apiCreateImages api Upload file
 * @param {*} apiCreatePath api add path
 * @param {*} folder thư mục chứa images
 * @param {*} objectAddPath là đối tượng thêm mới hình ảnh
 * @param {*} result là kết quả trả về khi đã edit success.
 */
async function handleUpdateListPathImages(arrFiles, listImage, apiEditPath , apiCreateImages, apiCreatePath, folder, objectAddPath, result) {
  // Dùng đệ quy để sắp xếp update theo thời gian
  let classActiveAvatar
  let class__ = document.querySelectorAll("." + listImage + "");
  let i = 0;
  // Xử lý sắp xếp ảnh khi edit path
  async function processNextItem() {
    if (i <= class__.length) {
      // điều kiện edit hoặc thêm mới.
      if (i < class__.length) {
        let strid_ = class__[i].getAttribute("id")
        let id__ = strid_.replace(/\D/g, "")
        let time_ = Date.now()
        // Kiểm tra các phần từ xem vị trí class nào có class active_avatar
        classActiveAvatar = class__[i].classList.contains("active_avatar")
        let objectEdit = {
          id: Number(id__),
          active_avatar: classActiveAvatar ? 1 : 0,
          addtime: time_,
        }
        // Set file images thêm mới
        let arrFilesAdd = arrFiles.filter((item) => item.id === Number(id__))
        if (arrFilesAdd.length > 0) {
          // Xử lý thêm mới đường dẫn.
          await handleAddPathImages(
            arrFilesAdd,
            objectAddPath,
            folder,
            apiCreatePath,
          )
        } else {
          // Xử lý edit đường dẫn
          await handleMethodEditData(objectEdit, function () {}, apiEditPath)
        }
      }

      // Trả về kết quả khi xử lý thành công.
      if (i === class__.length) {
        alert("Dữ liệu đã được làm mới.")
        result({ status: true, shops_id: objectAddPath.shops_id })
      }

      i++ // Chuyển sang xử lý phần tử tiếp theo
      setTimeout(processNextItem, 9)
      // Hiệu ứng xử lý thành công.
      // handleProgressUploadData(i - 1, class__.length)
    }
  }
  // Xử lý thêm mới nếu có ảnh mới
  if (arrFiles.length > 0) {
    // Xử lý add images hosting
    await handleUploadFileImage(arrFiles, apiCreateImages)
    // Xử lý đường dẫn
    await processNextItem()
  } else {
    // xử lý đường dẫn
    await processNextItem()
  }
}

export {handleSetNameFileImages, handleAddPathImages, handleUploadFileImage, handleUpdateListPathImages }
