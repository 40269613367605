import Tippy from "@tippyjs/react/headless";
import TippyNote from "@tippyjs/react"
import { useEffect, useRef, useState } from "react";
import ModalReact from "../../../Component/Modal/ModalReact";
import SearchHorizontal from "../../../Component/Search/SearchHorizontal";
import LoadingSpinner from "../../../Component/Loading/LoadingSpinner";
import moment from "moment";
import { IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from "react-icons/io";
import { TbPlaylistAdd } from "react-icons/tb";

function ButtonAddCateShops(props) {
    const { socketio, dataUser } = props;
    return (
      <>
        <Tippy
          interactive
          delay={[0, 0]}
          placement="bottom-end"
          trigger="click"
          render={() => (
            <MenuAddCateShops socketio={socketio} dataUser={dataUser} />
          )}
        >
          <TippyNote content="Thêm danh mục">
            <button
              className="border-0 btn btn-success btn-wave fw-5 rounded-4 p-1"
              style={{
                height: "40px",
                width: "40px",
              }}
            >
              <TbPlaylistAdd className="fs-23" />
            </button>
          </TippyNote>
        </Tippy>
      </>
    )
}
export default ButtonAddCateShops


function MenuAddCateShops(props) {
    const { socketio, dataUser } = props;

    //Quản lý danh mục chính
    const [modalCate, setModalCate] = useState(false);
    const handleModalCate = () => {
        setModalCate(true)
    }
    const handleCloseModalCate = () => {
        setModalCate(false)
    }

    // Quản lý danh mục thể loại
    const [modalCateChildren, setModalCateChildren] = useState(false);
    const handleModalCateChildren = () => {
        setModalCateChildren(true)
    }
    const handleCloseModalCateChildren = () => {
        setModalCateChildren(false)
    }

    return (
        <>
            <ul
                className="dropdown-menu-tippy"
                style={{ border: "1px solid #f9f9f9", overflow: "hidden" }}
            >
                <li className="dropdown-item" onClick={handleModalCate}>
                    Danh mục chính
                </li>
                <li className="dropdown-item" onClick={handleModalCateChildren}>
                    Thể loại
                </li>
            </ul>
            {/* Modal cate */}
            {modalCate && (
                <ModalReact
                    modalTitle="Danh sách danh mục chính"
                    showModal={modalCate}
                    handleClose={handleCloseModalCate}
                    theme={<ListItemCateShops socketio={socketio} dataUser={dataUser} />}
                />
            )}
            {/* Modal cate children */}
            {modalCateChildren && (
                <ModalReact
                    modalTitle="Danh sách danh mục thể loại"
                    showModal={modalCateChildren}
                    handleClose={handleCloseModalCateChildren}
                    theme={<ListItemCateChildrenShops socketio={socketio} dataUser={dataUser} />}
                />
            )}
        </>
    );
}


// Danh sách danh mục chính.
function ListItemCateShops(props) {
    const { socketio, dataUser } = props;
    const [dataCate, setDataCate] = useState([]);
    const dataCateRef = useRef([])
    const [objectSearch, setObjectSearch] = useState({textSearch: ""})
    const [loading, setLoading] = useState(true)

    // quản lý tìm kiếm.
    const handleValueSearch = (value) => {
        console.log(value)
        setObjectSearch({
            textSearch: value
        })
    }
    // Get data.
    useEffect(() => {
        const params = {
            iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
            object: {
                textSearch: objectSearch.textSearch
            }
        }
        socketio.emit("client-get-search-data-tech-shops-cate", params)
        socketio.on("server-get-search-data-tech-shops-cate", (data) => {
            setDataCate([...data])
            dataCateRef.current = [...data]
          setLoading(false)
        })
        return () => {
            socketio.off("client-get-search-data-tech-shops-cate")
            socketio.off("server-get-search-data-tech-shops-cate")
        }
    }, [objectSearch])

    // Quản lý thêm mới data.
    const [addData, setAddData] = useState(false)
    const [tendanhmuc, setTendanhmuc] = useState("")
    const handleAddDataCate = () => {
        setAddData(true)
    }
    const handleCloseAddData = () => setAddData(false);
    const handleAddTextCate = (e) => {
        setTendanhmuc(e.target.value)
    }
    // Xử lý thêm mới.
    const handleAddButtonSavedData = () => {
        const paramsCreate = {
            iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
            object: {title: tendanhmuc}
        }
        socketio.emit("client-create-tech-shops-cate", paramsCreate)
        socketio.on("server-create-tech-shops-cate", (data) => {
            if (data.length > 0) {
                alert("Thêm mới thành công.")
                setAddData(false)
                // Cập nhập lại data.
                let dataUpdate = dataCateRef.current;
                dataUpdate.push(data[0])
                let sortDataUpdate = dataUpdate.sort((a, b) => {
                    let min_ = new Date(a.addtime).getTime();
                    let max_ = new Date(b.addtime).getTime();
                    return max_ - min_
                });
                setDataCate([...sortDataUpdate])
                dataCateRef.current = [...sortDataUpdate]
            }
        })
    }
    useEffect(() => {
        return () => {
            socketio.off("client-create-tech-shops-cate")
            socketio.off("server-create-tech-shops-cate")
        }
    }, [addData])

    // Quan ly edit cate.
    const [activeEdit, setActiveEdit] = useState("")
    const [textEditCate, setTextEditCate] = useState("")
    const handleEditCate = (id, textEdit) => {
        setActiveEdit(id)
        setTextEditCate(textEdit)
    }
    const handleTextEditCate = (e) => {
        setTextEditCate(e.target.value)
    }
    // Xác nhận sửa dữ liệu
    const handleEditAcceptCate = (id) => {
        let paramsEdit = {
            iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
            object: {
                id: id,
                title: textEditCate
            }
        }
        socketio.emit("client-edit-data-tech-shops-cate", paramsEdit)
        socketio.on("server-edit-data-tech-shops-cate", (data) => {
            if (data.length > 0) {
                // cập nhật lại dataCate.
                let dataUpdate = dataCateRef.current;
                let index = dataUpdate.findIndex((item) => item.id === data[0].id);
                dataUpdate[index] = data[0];
                setDataCate([...dataUpdate])
                dataCateRef.current = [...dataUpdate]
                setActiveEdit("")
            }
        })
    }
    useEffect(() => {
        return () => {
            socketio.off("client-edit-data-tech-shops-cate")
            socketio.off("server-edit-data-tech-shops-cate")
        }
    }, [dataCate])

    // Xác nhận không sửa dữ liệu.
    const handleCancelEdit = (id) => {
        setActiveEdit("")
    }

    if (loading) {
        return <LoadingSpinner />
    }
    if (dataCate.length === 0) {
        return <div className="text-center mt-3 fs-16">Hiện tại chưa có danh mục.</div>
    }

    return (
      <div>
        <div className="wrapper_ground">
          {/* Tìm kiếm */}
          <div className="list_items">
            {!addData && (
              <SearchHorizontal
                result={handleValueSearch}
                className="rounded-3"
                placeholder="Tên danh mục"
              />
            )}
            {addData && (
              <input
                type="text"
                placeholder="Tên danh mục"
                className="form-control rounded-3"
                value={tendanhmuc}
                onChange={handleAddTextCate}
              />
            )}
          </div>
          {/* Thêm mới danh mục */}
          {!addData ? (
            <button
              className="list_items border-0 rounded-3"
              onClick={handleAddDataCate}
            >
              Thêm mới
            </button>
          ) : (
            <div className="d-flex gap-2">
              <button
                className="list_items border-0 rounded-3 btn btn-orange-light btn-wave fw-5"
                onClick={handleAddButtonSavedData}
              >
                Lưu lại
              </button>
              <button
                className="list_items border-0 rounded-3 btn btn-danger-light btn-wave fw-5"
                onClick={handleCloseAddData}
              >
                Hủy
              </button>
            </div>
          )}
        </div>
        {/* Content */}
        <div className="mt-3">
          {dataCate.map((item, index) => {
            return (
              <div key={index}>
                <div className="fs-18 mb-3 d-flex align-items-center justify-content-between">
                  <div>
                    {activeEdit === item.id ? (
                      <div className="d-flex align-items-center gap-1">
                        <input
                          type="text"
                          value={textEditCate}
                          onChange={handleTextEditCate}
                          className="border-0"
                          style={{
                            width: textEditCate.length <= 12 ? "33%" : "100%",
                          }}
                        />
                        <button
                          className="border-0 bg-transparent text-success"
                          onClick={() => handleEditAcceptCate(item.id)}
                        >
                          <IoIosCheckmarkCircleOutline className="fs-21" />
                        </button>
                        <button
                          className="border-0 bg-transparent text-danger"
                          onClick={() => handleCancelEdit(item.id)}
                        >
                          <IoIosCloseCircleOutline className="fs-21" />
                        </button>
                      </div>
                    ) : (
                      <h6 className="mb-0">{item.title}</h6>
                    )}
                    <p className="mb-0 text-muted fs-15">
                      {moment(item.addtime)
                        .locale("vi")
                        .fromNow()
                        .replace("một", "1")}
                    </p>
                  </div>
                  <div>
                    <button
                      className="badge bg-secondary-transparent border-0"
                      onClick={() => handleEditCate(item.id, item.title)}
                    >
                      Sửa
                    </button>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
}


// Danh sách danh mục thể loại.
function ListItemCateChildrenShops(props) {
  const { socketio, dataUser } = props
  const [dataCate, setDataCate] = useState([])
  const dataCateRef = useRef([])
  const [objectSearch, setObjectSearch] = useState({ textSearch: "" })
  const [loading, setLoading] = useState(true)

  // quản lý tìm kiếm.
  const handleValueSearch = (value) => {
    setObjectSearch({
      textSearch: value,
    })
  }
  // Get data.
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
      object: {
        textSearch: objectSearch.textSearch,
      },
    }
    socketio.emit("client-get-search-data-tech-shops-cate-children", params)
    socketio.on("server-get-search-data-tech-shops-cate-children", (data) => {
      setDataCate([...data])
      dataCateRef.current = [...data]
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-search-data-tech-shops-cate-children")
      socketio.off("server-get-search-data-tech-shops-cate-children")
    }
  }, [objectSearch])

  // Quản lý thêm mới data.
  const [addData, setAddData] = useState(false)
  const [tendanhmuc, setTendanhmuc] = useState("")
  const handleAddDataCate = () => {
    setAddData(true)
  }
  const handleCloseAddData = () => setAddData(false)
  const handleAddTextCate = (e) => {
    setTendanhmuc(e.target.value)
  }
  // Xử lý thêm mới.
  const handleAddButtonSavedData = () => {
    const paramsCreate = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
      object: { title: tendanhmuc },
    }
    socketio.emit("client-create-data-tech-cate-children", paramsCreate)
    socketio.on("server-create-data-tech-cate-children", (data) => {
      if (data.length > 0) {
        alert("Thêm mới thành công.")
        setAddData(false)
        // Cập nhập lại data.
        let dataUpdate = dataCateRef.current
        dataUpdate.push(data[0])
        let sortDataUpdate = dataUpdate.sort((a, b) => {
          let min_ = new Date(a.addtime).getTime()
          let max_ = new Date(b.addtime).getTime()
          return max_ - min_
        })
        setDataCate([...sortDataUpdate])
        dataCateRef.current = [...sortDataUpdate]
      }
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-create-data-tech-cate-children")
      socketio.off("server-create-data-tech-cate-children")
    }
  }, [addData])

  // Quan ly edit cate.
  const [activeEdit, setActiveEdit] = useState("")
  const [textEditCate, setTextEditCate] = useState("")
  const handleEditCate = (id, textEdit) => {
    setActiveEdit(id)
    setTextEditCate(textEdit)
  }
  const handleTextEditCate = (e) => {
    setTextEditCate(e.target.value)
  }
  // Xác nhận sửa dữ liệu
  const handleEditAcceptCate = (id) => {
    let paramsEdit = {
      iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
      object: {
        id: id,
        title: textEditCate,
      },
    }
    socketio.emit("client-edit-data-tech-cate-children", paramsEdit)
    socketio.on("server-edit-data-tech-cate-children", (data) => {
      if (data.length > 0) {
        // cập nhật lại dataCate.
        let dataUpdate = dataCateRef.current
        let index = dataUpdate.findIndex((item) => item.id === data[0].id)
        dataUpdate[index] = data[0]
        setDataCate([...dataUpdate])
        dataCateRef.current = [...dataUpdate]
        setActiveEdit("")
      }
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-data-tech-cate-children")
      socketio.off("server-edit-data-tech-cate-children")
    }
  }, [dataCate])

  // Xác nhận không sửa dữ liệu.
  const handleCancelEdit = (id) => {
    setActiveEdit("")
  }

  if (loading) {
    return <LoadingSpinner />
  }
  if (dataCate.length === 0) {
    return (
      <div className="text-center mt-3 fs-16">Hiện tại chưa có danh mục.</div>
    )
  }

  return (
    <div>
      <div className="wrapper_ground">
        {/* Tìm kiếm */}
        <div className="list_items">
          {!addData && (
            <SearchHorizontal
              result={handleValueSearch}
              className="rounded-3"
              placeholder="Tên danh mục"
            />
          )}
          {addData && (
            <input
              type="text"
              placeholder="Tên danh mục"
              className="form-control rounded-3"
              value={tendanhmuc}
              onChange={handleAddTextCate}
            />
          )}
        </div>
        {/* Thêm mới danh mục */}
        {!addData ? (
          <button
            className="list_items border-0 rounded-3"
            onClick={handleAddDataCate}
          >
            Thêm mới
          </button>
        ) : (
          <div className="d-flex gap-2">
            <button
              className="list_items border-0 rounded-3 btn btn-orange-light btn-wave fw-5"
              onClick={handleAddButtonSavedData}
            >
              Lưu lại
            </button>
            <button
              className="list_items border-0 rounded-3 btn btn-danger-light btn-wave fw-5"
              onClick={handleCloseAddData}
            >
              Hủy
            </button>
          </div>
        )}
      </div>
      {/* Content */}
      <div className="mt-3">
        {dataCate.map((item, index) => {
          return (
            <div key={index}>
              <div className="fs-18 mb-3 d-flex align-items-center justify-content-between">
                <div>
                  {activeEdit === item.id ? (
                    <div className="d-flex align-items-center gap-1">
                      <input
                        type="text"
                        value={textEditCate}
                        onChange={handleTextEditCate}
                        className="border-0"
                        style={{
                          width: textEditCate.length <= 12 ? "43%" : "100%",
                        }}
                      />
                      <button
                        className="border-0 bg-transparent text-success"
                        onClick={() => handleEditAcceptCate(item.id)}
                      >
                        <IoIosCheckmarkCircleOutline className="fs-21" />
                      </button>
                      <button
                        className="border-0 bg-transparent text-danger"
                        onClick={() => handleCancelEdit(item.id)}
                      >
                        <IoIosCloseCircleOutline className="fs-21" />
                      </button>
                    </div>
                  ) : (
                    <h6 className="mb-0">{item.title}</h6>
                  )}
                  <p className="mb-0 text-muted fs-15">
                    {moment(item.addtime)
                      .locale("vi")
                      .fromNow()
                      .replace("một", "1")}
                  </p>
                </div>
                <div>
                  <button
                    className="badge bg-secondary-transparent border-0"
                    onClick={() => handleEditCate(item.id, item.title)}
                  >
                    Sửa
                  </button>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}