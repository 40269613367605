import { AiOutlineInfoCircle } from "react-icons/ai"
import { BsShop } from "react-icons/bs"
import { HiOutlineShoppingBag, HiOutlineTicket } from "react-icons/hi2"
import { Link, useLocation } from "react-router-dom"
import { PiShoppingBagOpenDuotone } from "react-icons/pi"
import { IoBriefcaseOutline, IoFileTrayFullOutline, IoPieChartOutline, IoSettingsOutline, IoTrendingUp } from "react-icons/io5"
import * as Iconio5 from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io"
import Tippy from "@tippyjs/react"
import { images } from "../../../../assets/images"
import { TextLimit } from "../../../../handle-reuses/reuses"
import { useState } from "react"

function SideMenuFull(props) {
  const {dataMenu, resultStatus} = props
  const path = useLocation()
  const pathName = path.pathname

    const listMenuDefault = [
      {
        icon_menu: <PiShoppingBagOpenDuotone className="fs-21" />,
        title: "Cửa hàng",
        path: "/shops",
      },
      {
        icon_menu: <HiOutlineTicket className="fs-21" />,
        title: "Gói dịch vụ",
        path: "/pricing",
      },
      {
        icon_menu: <HiOutlineShoppingBag className="fs-21" />,
        title: "Đặt hàng",
        path: "/software-order",
      },
      // {
      //   icon_menu: <Iconio5.IoAlertCircleOutline className="fs-25" />,
      //   title: "Hỗ trợ",
      //   path: "",
      // },
    ]

    
    // Quản lý thu gọn side menu.
    const handleCompactSideMenu = () => {
        resultStatus("more")
    }
  return (
    <div className="sideMenu-full">
      <div className="wrapper-bg rounded-4 position-relative">
        <ul className="listItem-sideMenu">
          <li
            className="d-flex align-items-center justify-content-center py-3"
            style={{
              borderBottom: "1px dashed #ededed",
            }}
          >
            <Link
              to="/"
              className="d-flex flex-direction-column align-items-center gap-2"
            >
              <span className="avatar avatar-lg avatar-rounded bg-logo">
                <img src={images.user} alt="LOGO" />
              </span>
              <span className="fw-5 fs-18">Hoàng Hiếu</span>
            </Link>
          </li>
          <li className="receipt-style"></li>
          <li>
            <Link
              to="/"
              className={`d-flex align-items-center p-2 px-3 gap-3 rounded-5 ${
                pathName === "/" ? "activeMenu" : "menuSidebar"
              }`}
            >
              {/* Icon menu */}
              <span className="fs-21 d-flex align-items-center">
                <Iconio5.IoTvOutline className="fs-21" />
              </span>
              {/* cate name */}
              <span>Trang chủ</span>
            </Link>
          </li>
          {/* Menu active từ shops */}
          {dataMenu.length > 0 &&
            dataMenu.map((item, index) => {
              // Format icon menu.
              let iconString = `${item.icon_menu}`
              // loại bỏ dấu <> / trong icon.
              let iconName = iconString.replace(/[<>/]/g, "").trim()
              // Đưa về dạng Component
              const FormatIcon = Iconio5[iconName]

              return (
                <li key={index}>
                  <Link
                    to={item.path}
                    className={`d-flex align-items-center p-2 px-3 gap-3 rounded-5 ${
                      pathName === item.path ? "activeMenu" : "menuSidebar"
                    }`}
                  >
                    {/* Icon menu */}
                    <span className="fs-21 d-flex align-items-center">
                      {<FormatIcon /> ? <FormatIcon className="fs-21" /> : ""}
                    </span>
                    {/* cate name */}
                    <span>
                      <TextLimit text={item.title} number={18} />
                    </span>
                  </Link>
                </li>
              )
            })}
          <div className="mb-3 border-top border-block-start-dashed"></div>
          {/* Menu default */}
          {listMenuDefault.map((item, index) => {
            return (
              <li key={index}>
                <Link
                  to={item.path}
                  className={`d-flex align-items-center p-2 px-3 gap-3 rounded-5 ${
                    pathName === item.path ? "activeMenu" : "menuSidebar"
                  }`}
                >
                  <span className="fs-21 d-flex align-items-center">
                    {item.icon_menu}
                  </span>
                  <span>{item.title}</span>
                </Link>
              </li>
            )
          })}
          <div className="mb-3 border-top border-block-start-dashed"></div>
        </ul>
        {/* button thu gọn */}
        <div className="text-center mb-3">
          <Tippy content="Thu gọn" placement="right">
            <button
              className="border-0 rounded-5 text-muted p-1"
              style={{
                width: "35px",
                height: "35px",
                backgroundColor: "rgb(244 237 234) !important",
              }}
              onClick={handleCompactSideMenu}
            >
              <IoIosArrowBack />
            </button>
          </Tippy>
        </div>
      </div>
    </div>
  )
}
export default SideMenuFull
