import { Link, useNavigate } from "react-router-dom";
import { imageLogo } from "../../assets/images";
import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../handle-reuses/Contexts/CreateContexts";
import { useForm } from "react-hook-form";

function LoginPage() {
    const { socketio } = useContext(AppContext)
    const { register, handleSubmit, formState: { errors } } = useForm()
    const navigate = useNavigate()
    
    const [changeLogin, setChangeLogin] = useState()
    const handleOnSubmit = (object) => {
      const paramsLogin = {
        iden: process.env.REACT_APP_TOKEN_KEY_VERIFY,
        object: object
      };
        socketio.emit("client-check-login-tech-user", paramsLogin)
        socketio.on("server-check-login-tech-user", (data) => {
            if (data.length > 0) {
                // set localStorage
                let objectLocal = {
                  email: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"+ data[0].id,
                  status: "success",
                }
                localStorage.setItem("ob", JSON.stringify(objectLocal))
                // Chuyển sang trang chủ
              window.location.href = process.env.REACT_APP_DOMAIN_WEB
              setChangeLogin(object)
            } else {
              alert("Tài khoản không tồn tại.")
              setChangeLogin(object)
            }
        })
    }
    useEffect(() => {
        return () => {
            socketio.off("client-check-login-tech-user")
            socketio.off("server-check-login-tech-user")
        }
    }, [changeLogin])
    
    return (
      <div className="login-3 tab-box">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-12 bg-img">
              <div className="informeson">
                <div className="typing">
                  <h1>Welcome view 360</h1>
                </div>
                <p>
                  Đến với view 360 chúng tôi cung cấp giải pháp chuyển đổi số
                  theo từng mảng, từng lĩnh vực, nhằm mục đích đơn giản những
                  công việc sổ sách thường ngày. Việc chuyển đổi số chỉ danh cho
                  khách hàng hướng tới sự chuyên nghiệp, quản lý vận hành tự
                  động.
                </p>
                <div className="social-list">
                  <div className="buttons">
                    <a href="#" className="facebook-bg">
                      <i className="fa fa-facebook"></i>
                    </a>
                    <a href="#" className="twitter-bg">
                      <i className="fa fa-twitter"></i>
                    </a>
                    <a href="#" className="google-bg">
                      <i className="fa fa-google"></i>
                    </a>
                    <a href="#" className="dribbble-bg">
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 form-section">
              <div className="login-inner-form">
                <div className="details">
                  <Link to="/">
                    <span className="avatar avatar-lg bg-danger rounded-5">
                      <img src={imageLogo.logo} alt="logo" />
                    </span>
                  </Link>
                  <h3>Đăng nhập</h3>
                  <form onSubmit={handleSubmit(handleOnSubmit)}>
                    <div className="form-group form-box">
                      <input
                        type="email"
                        className="form-control rounded-5"
                        placeholder="Địa chỉ email"
                        {...register("email", {required: true})}
                      />
                    </div>
                    <div className="form-group form-box">
                      <input
                        type="password"
                        className="form-control rounded-5"
                        placeholder="Mật khẩu"
                        {...register("passwords", {required: true})}
                      />
                    </div>
                    {/* <div className="form-group form-box checkbox clearfix">
                      <div className="form-check checkbox-theme">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="rememberMe"
                        />
                        <label className="form-check-label" htmlFor="rememberMe">
                          Remember me
                        </label>
                      </div>
                      <a href="forgot-password-3.html">Forgot Password</a>
                    </div> */}
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn-md btn-theme w-100 rounded-5"
                      >
                        Đăng nhập
                      </button>
                    </div>
                    <p>
                      Bạn chưa có tài khoản?
                      <Link to="/register"> Đăng ký</Link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}
export default LoginPage