import classNames from "classnames/bind"
import styles from "../../../Admin.module.scss"
import ListUser from "../../../Component/Cate-user/ListUser"
import StatisticalUser from "../../../Component/Cate-user/StatisticalUser"
const cx = classNames.bind(styles)

function AdminUserPage(props) {
  return (
    <div className="wrapper_ground">
      <div
        className={cx("list-user", "list_items")}
        style={{ maxWidth: "33%" }}
      >
        <ListUser />
      </div>
      <div className={cx("list_items")} style={{ maxWidth: "67%" }}>
        <StatisticalUser />
      </div>
    </div>
  )
}
export default AdminUserPage
