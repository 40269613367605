import Swal from "sweetalert2"

export const handleShowAlertWarning = (object) => {
  Swal.fire({
    title: object.title,
    html: object.html,
    icon: object.icon ? object.icon : "warning",
    confirmButtonText: object.confirmButtonText || "Xác nhận",
    cancelButtonText: object.cancelButtonText || "Hủy",
    showCancelButton: false, // Hiển thị nút hủy
    buttonsStyling: object.button !== undefined ? object.button : true,
  })
}
